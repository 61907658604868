import crypto from 'crypto'

import {
  fetchInvoiceList,
  fetchInvoiceLink,
  downloadInvoicesFromDates,
} from '@/api/invoice'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  list: [],
  detail: {},
  orderId: '',
  page: 1,
  limit: 20,
  queryHash: '',
  hasMore: false,
  lastError: undefined,
  fetchingList: false,
  fetchingLink: false,
  downloadInvoice: false,
  totalRecords: 0,
}

const mutations = {
  SET_HAS_MORE(state, newValue) {
    state.hasMore = newValue
  },
  SET_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = getNewList([], data.data, page)
  },
  ADD_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = getNewList(state.list, data.data, page)
  },
  REMOVE_LIST(state) {
    state.page = 1
    state.list = []
  },
  ADD_ORDER_DETAIL(state, { orderId, detail }) {
    state.detail = { ...state.detail, [orderId]: detail }
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_CURRENT_PAGE(state, newValue) {
    state.page = newValue
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.totalRecords = newValue
  },
}

const getters = {
  getList: (state) => {
    return state.list.reduce((list, subList, index) => {
      if (index + 1 <= state.page) {
        list.push(...subList)
      }

      return list
    }, [])
  },
  getOrderDetail: (state) => (orderId) => {
    return state.detail[orderId] || {}
  },
  getTotalRecords: (state) => {
    return state.totalRecords
  },
}

const actions = {
  async fetchMultiplePages({ commit }, params) {
    const { from, to, ...query } = params
    const caller = []

    for (let page = from; page <= to; page++) {
      caller.push(fetchInvoiceList({ page, ...query }))
    }
    try {
      const responses = await Promise.all(caller)

      responses.forEach(({ data }, index) => {
        commit('ADD_LIST', { data, page: from + index })
      })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async fetchInvoiceList({ commit, dispatch, state }, params) {
    try {
      const { page, limit, ...query } = params

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        commit('REMOVE_LIST')
      }

      // if (!state.list.length && page > 1) {
      //   dispatch('fetchMultiplePages', {
      //     from: 1,
      //     to: page - 1,
      //     limit,
      //     ...query,
      //   })
      // }

      commit('SET_FETCHING', { name: 'fetchingList', value: true })

      const { data, headers } = await fetchInvoiceList({
        page,
        limit,
        ...query,
      })
      const hasMore = getHasMore(headers)

      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('SET_HAS_MORE', hasMore)
      // commit('ADD_LIST', { data, page })
      commit('SET_LIST', { data, page })
      commit('SET_CURRENT_PAGE', page)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },
  async fetchInvoiceLink({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'fetchingLink', value: true })

      const response = await fetchInvoiceLink(data)

      commit('SET_FETCHING', { name: 'fetchingLink', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async downloadInvoices({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'downloadInvoice', value: true })

      const response = await downloadInvoicesFromDates(data)

      commit('SET_FETCHING', { name: 'downloadInvoice', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadInvoice', value: false })
    }
  },
}

// ======= Private helpers =========
const getHasMore = (headers) => {
  const { paginationHasmore } = headers || {}

  if (typeof paginationHasmore === 'string') {
    return paginationHasmore === 'true'
  }

  return !!paginationHasmore
}

// Will update list immediately
const getNewList = (list, data, page) => {
  while (list.length < page) {
    list.push([])
  }

  list[page - 1] = data

  return [...list]
}
// ================================

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
