/**
 *
 * @param queryString
 * @returns {{}|{}}
 */
const parseQueryString = (queryString) =>
  (queryString &&
    queryString !== '?' &&
    queryString
      .substring(1)
      .split('&')
      .map((pair) => pair.split('='))
      .reduce(
        (params, [key, value]) => ({
          ...params,
          [key]: decodeURIComponent(value),
        }),
        {}
      )) ||
  {}

/**
 *
 * @param keyValues
 * @returns {string}
 */
const buildQuerystring = (keyValues) =>
  new URLSearchParams(keyValues).toString()

/**
 *
 * @param url
 * @param keyValues
 * @returns {`${string}${string}${string}`}
 */
const appendQuerystringToUrl = (url, keyValues) => {
  const querystringEncoded = buildQuerystring(keyValues)
  const separator = url.indexOf('?') === -1 ? '?' : '&'

  return `${url}${separator}${querystringEncoded}`
}

export { parseQueryString, buildQuerystring, appendQuerystringToUrl }
