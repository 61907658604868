<script>
import { mapActions, mapGetters } from 'vuex'
import { submitPayment } from '@/api/ads'
import binMadaData from '@/views/ads/constants/bin-mada-data'
import { ADS_GA_EVENTS, emitAdsEvent } from '@/utils/analytics'

const CHECKOUT_FRAMES_SOURCE = 'https://cdn.checkout.com/js/framesv2.min.js'
const CHECKOUT_KEY_BY_CURRENCY = {
  SAR: process.env.VUE_APP_CHECKOUT_PK_SAU,
  AED: process.env.VUE_APP_CHECKOUT_PK_ARE,
  KWD: process.env.VUE_APP_CHECKOUT_PK_KWD,
  BHD: process.env.VUE_APP_CHECKOUT_PK_BHD,
}

export default {
  name: 'AdsPaymentMethod',
  data() {
    return {
      isSubmitting: false,
      isCardValid: false,
      isKeepPaymentData: false,
      paymentMethod: null,
      errors: {},
    }
  },
  computed: {
    ...mapGetters('translations', ['currentLocale']),
    ...mapGetters('ads', ['paymentData', 'userType']),
    supportedPaymentMethods() {
      if (!this.paymentData?.billingAddress) {
        return []
      }

      const { countryCode } = this.paymentData.billingAddress
      const methodList = ['visa', 'mastercard', 'american-express']

      if (countryCode === 'SA') {
        methodList.push('mada')
      }

      return methodList
    },
    paymentMethodIcon() {
      if (!this.paymentMethod) {
        return null
      }

      return `payment-method-${this.paymentMethod}`
    },
    supportedMethodIcons() {
      return this.supportedPaymentMethods.map(
        (method) => `payment-method-${method}`
      )
    },
    isCardSupported() {
      if (!this.paymentMethod) {
        return true
      }

      return this.supportedPaymentMethods.includes(this.paymentMethod)
    },
    checkoutKey() {
      return CHECKOUT_KEY_BY_CURRENCY[
        this.paymentData?.billingAddress?.currency
      ]
    },
  },
  created() {
    if (!this.paymentData) {
      this.$router.replace('/ads/ads-credit/top-up')
    }
  },
  mounted() {
    if (!this.checkoutKey) {
      this.$router.replace('/ads/ads-credit/top-up')
      return
    }

    let frameScript = document.querySelector('#checkout-frames-script')
    // If Checkout's frames script injected already, skip this step
    if (frameScript) {
      this.initFrames()
      return
    }

    frameScript = document.createElement('script')
    frameScript.id = 'checkout-frames-script'
    frameScript.setAttribute('src', CHECKOUT_FRAMES_SOURCE)
    frameScript.onload = this.initFrames

    document.body.appendChild(frameScript)
  },
  destroyed() {
    if (!this.isKeepPaymentData) {
      this.setPaymentData(null)
    }

    // If we go to other pages without remove event listener
    // they will be remain the entire time user on site
    this.removeAllFrameEventHandler()
  },
  methods: {
    ...mapActions('ads', ['setPaymentData']),
    initFrames() {
      const Frames = window.Frames
      const Events = Frames.Events

      Frames.init({
        publicKey: this.checkoutKey,
        localization: this.currentLocale === 'ar_SA' ? 'AR' : 'EN-GB',
      })

      Frames.addEventHandler(
        Events.PAYMENT_METHOD_CHANGED,
        ({ paymentMethod }) => {
          this.paymentMethod = paymentMethod?.replaceAll(' ', '-').toLowerCase()
        }
      )
      Frames.addEventHandler(
        Events.FRAME_VALIDATION_CHANGED,
        ({ element, isValid }) => {
          this.errors = { ...this.errors, [element]: !isValid }
        }
      )
      Frames.addEventHandler(Events.CARD_VALIDATION_CHANGED, ({ isValid }) => {
        this.isCardValid = isValid
      })
      Frames.addEventHandler(Events.CARD_TOKENIZED, (data) => {
        let paymentMethod = 'CheckoutCreditCard'
        if (binMadaData.includes(data.bin)) {
          paymentMethod = 'CheckoutMadaCard'
        }
        this.onCardTokenized(data.token, paymentMethod)
      })
      Frames.addEventHandler(Events.CARD_TOKENIZATION_FAILED, (e) => {
        Frames.enableSubmitForm()
        this.isSubmitting = false
      })
    },
    removeAllFrameEventHandler() {
      if (!window.Frames) {
        return
      }

      const Frames = window.Frames
      const Events = Frames.Events

      Frames.removeAllEventHandlers(Events.PAYMENT_METHOD_CHANGED)
      Frames.removeAllEventHandlers(Events.FRAME_VALIDATION_CHANGED)
      Frames.removeAllEventHandlers(Events.CARD_VALIDATION_CHANGED)
      Frames.removeAllEventHandlers(Events.CARD_TOKENIZED)
      Frames.removeAllEventHandlers(Events.CARD_TOKENIZATION_FAILED)
    },
    onTopUpWallet() {
      if (!window.Frames) {
        return
      }

      window.Frames.submitCard()
      this.isSubmitting = true
    },
    onCancelTopUp() {
      this.isKeepPaymentData = true
      this.$router.replace('/ads/ads-credit/top-up')
    },
    async onCardTokenized(cardToken, paymentMethod) {
      try {
        this.isSubmitting = true

        const { billingAddress, topUpData } = this.paymentData

        const { data } = await submitPayment({
          cardToken,
          amount: topUpData.cost.amount,
          billingAccountId: billingAddress.accountId,
          checkoutPublicKey: this.checkoutKey,
          paymentMethod,
        })

        emitAdsEvent(ADS_GA_EVENTS.TOPUP_PROGRESS, {
          user_type: this.userType,
          amount: topUpData.creditAmount,
          step_completed: 'card_submitted',
        })

        if (data.data && data.data.redirectUrl) {
          window.location.href = data.data.redirectUrl
        }
      } catch {
        window.Frames.enableSubmitForm()
      } finally {
        this.isSubmitting = false
      }
    },
  },
}
</script>

<template>
  <div class="ads-payment-method">
    <h1>{{ $t('ads_top_up_payment_method') }}</h1>
    <p>
      {{ $t('ads_top_up_secure_msg') }}
    </p>
    <form id="payment-form">
      <label class="card-number-input">
        <span>{{ $t('ads_top_up_card_number') }}</span>
        <div
          class="card-number-frame payment-input"
          :class="{ error: errors['card-number'] || !isCardSupported }"
        >
          <!-- card number will be added here -->
          <svg-icon
            v-if="paymentMethodIcon"
            class="payment-method-icon supported-methods"
            :icon-class="paymentMethodIcon"
          />
          <div v-else class="supported-methods">
            <svg-icon
              v-for="methodIcon in supportedMethodIcons"
              :key="methodIcon"
              class="payment-method-icon"
              :icon-class="methodIcon"
            />
          </div>
        </div>
        <small v-if="!isCardSupported" class="error-msg">
          {{ $t('ads_top_up_unsupported_method') }}
        </small>
        <small v-else-if="errors['card-number']" class="error-msg">
          {{ $t('ads_top_up_card_error_msg') }}
        </small>
      </label>
      <label>
        <span>{{ $t('ads_top_up_valid_thru') }}</span>
        <div
          class="expiry-date-frame payment-input"
          :class="{ error: errors['expiry-date'] }"
        >
          <!-- expiry date will be added here -->
        </div>
        <small v-if="errors['expiry-date']" class="error-msg">
          {{ $t('ads_top_up_expiry_date_error_msg') }}
        </small>
      </label>
      <label>
        <span>{{ $t('ads_top_up_cvv') }}</span>
        <div class="cvv-frame payment-input" :class="{ error: errors['cvv'] }">
          <!-- cvv frame will be added here -->
        </div>
        <small v-if="errors['cvv']" class="error-msg">
          {{ $t('ads_top_up_cvv_error_msg') }}
        </small>
      </label>

      <el-button
        :disabled="isSubmitting"
        class="global__button cancel-btn"
        @click="onCancelTopUp"
      >
        {{ $t('ads_top_up_cancel') }}
      </el-button>
      <el-button
        class="global__button-black pay-btn m-0"
        :loading="isSubmitting"
        :disabled="!isCardValid || !isCardSupported"
        @click="onTopUpWallet"
      >
        {{ $t('ads_top_up_pay_now') }}
      </el-button>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.ads-payment-method {
  width: 100%;
  text-align: start;
}

h1 {
  margin-block-end: 16px !important;
}

p {
  margin-block-end: 16px !important;
}

form {
  display: grid;
  grid-template-areas: 'number number' 'valid cvv' 'cancel-btn pay-btn';
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

label {
  position: relative;
  padding-block-end: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666;

  > span {
    display: block;
    margin-block-end: 8px;
  }
}

.payment-input {
  position: relative;
  height: 44px;
  padding: 8px 16px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  &.error {
    border: 1px solid #ff4949;
  }
}

.error-msg {
  position: absolute;
  top: calc(100% - 24px);
  margin-block-start: 8px;
  font-size: 12px;
  line-height: 1;
  color: #ff4949;
}

.card-number-input {
  grid-area: number;
}

.pay-btn {
  grid-area: pay-btn;
  margin: 0;
}
.cancel-btn {
  grid-area: cancel-btn;
}

.supported-methods {
  position: absolute;
  top: 11px;
  right: 8px;
  display: flex;
  gap: 4px;

  [dir='rtl'] & {
    right: unset;
    left: 8px;
  }
}

.payment-method-icon {
  width: 36px !important;
  height: 20px !important;
}
</style>
