export const PAY_NOW = 'PAY_NOW'

const paymentTypes = [
  {
    key: 'payment_type_instalment_3',
    name: 'Split in 3',
    nameAr: 'الدفع علي ٣ دفعات',
  },
  {
    key: 'payment_type_instalment_6',
    name: 'Split in 6',
    nameAr: 'الدفع علي ٦ دفعات',
  },
  {
    key: 'payment_type_paylater',
    name: 'Pay later',
    nameAr: 'ادفع لاحقا',
  },
  {
    key: 'payment_type_paynextmonth',
    name: 'Pay Next Month',
    nameAr: 'ادفعها الشهر الجاي',
  },
  {
    key: 'payment_type_paynow',
    name: 'One Payment',
    nameAr: 'دفعة واحدة',
  },
]

export { paymentTypes }
