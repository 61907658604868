var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar",attrs:{"id":"sidebar"}},[(_vm.subMerchantAccounts && !_vm.isKycUser)?_c('div',[_c('el-drawer',{attrs:{"direction":_vm.currentLocale === 'ar_SA' ? 'rtl' : 'ltr',"with-header":false,"modal-append-to-body":false,"custom-class":"sidebar__submerchants-drawer","visible":_vm.showSubMerchantAccounts},on:{"update:visible":function($event){_vm.showSubMerchantAccounts=$event},"open":_vm.handleBeforeOpenDrawer,"closed":_vm.closeDrawer}},[_c('div',[_c('div',{staticClass:"sidebar__submerchants"},[_c('div',{staticClass:"sidebar__submerchants-head"},[_c('div',{staticClass:"sidebar__submerchants-head-search"},[_c('span',{staticClass:"sidebar__search-svg-container"},[_c('svg-icon',{attrs:{"icon-class":"search"}})],1),_c('el-input',{staticClass:"search-input",attrs:{"clearable":"","placeholder":_vm.$t('sidebar_search_placeholder')},model:{value:(_vm.subMerchantsSearchText),callback:function ($$v) {_vm.subMerchantsSearchText=$$v},expression:"subMerchantsSearchText"}})],1),_c('div',{staticClass:"sidebar__submerchants-head-text"},[_vm._v(" "+_vm._s(_vm.subMerchantAccounts.length)+" "+_vm._s(_vm.$t('sidebar_accounts_found'))+" ")])]),_c('div',{staticClass:"sidebar__submerchants-content"},[(
                _vm.subMerchantsSearchText && _vm.subMerchantAccounts.length === 0
              )?_c('div',{staticClass:"sidebar__submerchants-no-results"},[_vm._v(" "+_vm._s(_vm.$t('sidebar_no_results'))+" ")]):_vm._e(),_vm._l((_vm.subMerchantAccounts),function(item){return _c('div',{key:item.merchantId,staticClass:"sidebar__submerchants-item",on:{"click":function($event){return _vm.handleSelectSubMerchant(item.merchantId)}}},[_c('div',{staticClass:"sidebar__submerchants-item-svg-container"},[_c('svg-icon',{attrs:{"icon-class":"store"}})],1),_c('div',{staticClass:"sidebar__submerchants-item-name-container"},[_c('el-tooltip',{attrs:{"slot":"title","placement":"right","open-delay":200,"content":_vm.getLocalizedSubMerchantName(item)},slot:"title"},[_c('div',{staticClass:"sidebar__submerchants-item-name ellipsis"},[_vm._v(" "+_vm._s(_vm.getLocalizedSubMerchantName(item))+" ")])])],1),(item.merchantId === _vm.selectedMerchant)?_c('div',[_c('svg-icon',{attrs:{"icon-class":"check"}})],1):_vm._e()])})],2)])])])],1):_vm._e(),_c('el-menu',{ref:"sidebarMenu",attrs:{"default-active":_vm.activeMenu,"background-color":_vm.variables.menuBg,"text-color":_vm.variables.menuText,"unique-opened":false,"active-text-color":_vm.variables.menuActiveText,"collapse-transition":false,"mode":"vertical"},on:{"close":_vm.handleMenuCollapse,"item-click":_vm.handleClickedOnItem,"submenu-click":_vm.handleClickedSubmenu}},[_c('div',{staticClass:"sidebar__inner"},[(_vm.isConfirmedUser)?_c('div',{staticClass:"sidebar__top"},[_c('div',{class:("sidebar__submerchants-button " + (_vm.subMerchantAccounts
                ? 'sidebar__submerchants-button--clickable'
                : '')),on:{"click":function($event){_vm.showSubMerchantAccounts = _vm.subMerchantAccounts
              ? !_vm.showSubMerchantAccounts
              : false}}},[_c('div',{staticClass:"sidebar__submerchants-main-logo"},[(_vm.merchantProfile.logo)?_c('img',{attrs:{"src":_vm.merchantProfile.logo,"alt":""}}):_c('svg-icon',{attrs:{"icon-class":"store"}})],1),_c('div',{staticClass:"sidebar__submerchants-button-text"},[_c('div',[_c('el-tooltip',{attrs:{"slot":"title","placement":"bottom","content":_vm.activeSubMerchantName},slot:"title"},[_c('div',{staticClass:"ellipsis"},[_c('span',{staticClass:"sidebar__submerchants-button-merchant-name global__always-ltr"},[_vm._v(" "+_vm._s(_vm.ellipsisEnd(_vm.activeSubMerchantName, 15))+" ")])])])],1),_c('div',[_vm._v(" "+_vm._s(_vm.$t('sidebar_id'))+": "),_c('span',{staticClass:"global__always-ltr"},[_vm._v(" "+_vm._s(_vm.merchantProfile.merchantCode)+" ")])])]),(_vm.subMerchantAccounts)?_c('div',{staticClass:"sidebar__submerchants-button-svg"},[(_vm.showSubMerchantAccounts)?_c('svg-icon',{staticClass:"sidebar__change-branch-svg",attrs:{"icon-class":"change-branch"}}):_vm._e(),(!_vm.showSubMerchantAccounts)?_c('svg-icon',{attrs:{"icon-class":"two-arrows"}}):_vm._e()],1):_vm._e()]),_vm._l((_vm.restRoutes),function(route){return _c('SidebarItem',{key:route.path,attrs:{"item":route,"base-path":route.path},on:{"click:arrow":_vm.handleArrowClick}})})],2):_vm._e(),(_vm.isKycUser && _vm.onboardingData)?_c('div',{staticClass:"sidebar__top sidebar__top--onboarding"},[_c('div',{class:("sidebar__submerchants-button " + (_vm.subMerchantAccounts
                ? 'sidebar__submerchants-button--clickable'
                : ''))},[_c('div',{staticClass:"sidebar__submerchants-main-logo"},[(!_vm.fetchingOnboardingData)?_c('img',{attrs:{"src":_vm.kycMerchantLogoUrl,"alt":""}}):_vm._e()]),_c('div',{staticClass:"sidebar__submerchants-button-text"},[_c('div',[_vm._v(" "+_vm._s(_vm.kycMerchantName)+" ")]),_c('el-tooltip',{attrs:{"slot":"title","placement":"bottom","content":_vm.activeSubMerchantName},slot:"title"},[_c('div',{staticClass:"ellipsis"},[_vm._v(" "+_vm._s(_vm.activeSubMerchantName)+" ")])])],1)]),_vm._l((_vm.kycRoutes),function(route){return _c('SidebarItem',{key:route.path,attrs:{"item":route,"base-path":route.path}})})],2):_vm._e(),_c('div',{staticClass:"sidebar__bottom"},[_c('div',[_c('SidebarItem',{key:_vm.docsUrl,attrs:{"item":_vm.docsItem,"base-path":_vm.docsUrl}})],1),_c('div',[_c('SidebarItem',{key:_vm.kitUrl,attrs:{"item":_vm.kitItem,"base-path":_vm.kitUrl}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }