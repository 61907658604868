import BaseApi from '@/api/base-api'

class MerchantOrderApi extends BaseApi {
  captureOrder = (orderId, { totalAmount, comment, merchantId }) => {
    return this.request({
      method: 'POST',
      path: this.uri + `/${orderId}/capture`,
      data: {
        total_amount: totalAmount,
        comment: comment,
        merchant_id: merchantId,
      },
    })
  }

  cancelOrder = (orderId, { totalAmount, comment, merchantId }) => {
    return this.request({
      method: 'POST',
      path: this.uri + `/${orderId}/cancel`,
      data: {
        total_amount: totalAmount,
        comment: comment,
        merchant_id: merchantId,
      },
    })
  }

  disputeCancelOrder = (
    orderId,
    { totalAmount, comment, merchantId, disputeId }
  ) => {
    return this.request({
      method: 'POST',
      path: this.uri + `/${orderId}/cancel`,
      data: {
        total_amount: totalAmount,
        comment: comment,
        merchant_id: merchantId,
        dispute_id: disputeId,
      },
    })
  }

  refundOrder = (orderId, { totalAmount, comment, merchantId }) => {
    return this.request(
      {
        method: 'POST',
        path: this.uri + `/${orderId}/refund`,
        data: {
          total_amount: totalAmount,
          comment: comment,
          merchant_id: merchantId,
        },
      },
      {
        options: {
          headers: {
            'X-Requested-From': 'partner_portal',
          },
        },
      }
    )
  }

  downloadOrders = (data) => {
    return this.request(
      {
        timeout: 100000, // ms
        method: 'GET',
        path: this.uri + '/report',
        data: data,
      },
      {
        options: {
          hideMessage: true,
          camelizeRes: false,
        },
      }
    )
  }

  generateDocumentLink = (data) => {
    return this.request({
      method: 'POST',
      path: this.uri + `/dispute/generate-document-link?document_name=${data}`,
    })
  }
}

export default new MerchantOrderApi('/merchants/orders')
