import settlementApi from '@/api/settlement'

const state = {
  info: [],
  fetchingInfo: false,
  error: null,
  page: 1,
  limit: 25,
  totalRecords: 0,
  fetchingSummary: false,
  list: [],
  config: [],
  lastSettlement: null,
  breakdownData: {},
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getBreakdown: (state) => {
    return state.breakdownData
  },
  getSettlementTotalRecords: (state) => {
    return state.totalRecords
  },
  fetchingSummary: (state) => {
    return state.fetchingSummary
  },
  getSummaryLoading: (state) => {
    return state.fetchingSummary
  },
}

const mutations = {
  SET_SETTLEMENT_INFO(state, info) {
    state.info = info
  },
  SET_FETCHING_INFO(state, fetching) {
    state.fetchingInfo = fetching
  },
  SET_ERROR(state, error) {
    state.error = error
  },
  SET_FETCHING_SUMMARY(state, fetching) {
    state.fetchingSummary = fetching
  },
  SET_LIST(state, list) {
    state.list = list
  },
  SET_DATA_BREAKDOWN(state, data) {
    state.breakdownData = data
  },

  SET_LAST_SETTLEMENT(state, obj) {
    state.lastSettlement = obj
  },
  SET_TOTAL_RECORDS(state, totalRecords) {
    state.totalRecords = totalRecords
  },
  SET_CURRENT_PAGE(state, page) {
    state.page = page
  },
  SET_SETTLEMENT_CONFIGS(state, config) {
    state.config = config
  },
}

const actions = {
  async fetchSettlements({ commit }, params) {
    try {
      commit('SET_FETCHING_SUMMARY', true)
      const { data } = await settlementApi.fetchSettlements(params)
      commit('SET_FETCHING_SUMMARY', false)
      commit('SET_LIST', data.data)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (e) {
      commit('SET_FETCHING_SUMMARY', false)
      commit('SET_ERROR', e)
    }
  },
  async fetchBreakdown({ commit }, params) {
    const { countryCode, currency } = params
    try {
      const { data } = await settlementApi.fetchBreakdown(countryCode, currency)
      commit('SET_DATA_BREAKDOWN', data)
    } catch (e) {
      commit('SET_ERROR', e)
    }
  },

  async fetchLastSettlement({ commit }, params) {
    try {
      const { data } = await settlementApi.fetchSettlements(params)
      const hasValidData =
        data.data?.length > 0 && Object.keys(data.data[0]).length > 0
      commit('SET_LAST_SETTLEMENT', hasValidData ? data.data[0] : null)
    } catch (e) {
      commit('SET_ERROR', e)
    }
  },
  exportSettlement({ commit }, params) {
    return settlementApi.fetchSettlements(params)
  },
  async fetchSettlementConfigs({ commit }, merchantId) {
    try {
      const { data } = await settlementApi.fetchConfigs(merchantId)
      commit('SET_SETTLEMENT_CONFIGS', data)
    } catch (e) {
      commit('SET_ERROR', e)
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }
