import BaseApi from '@/api/base-api'

class MerchantDisputeApi extends BaseApi {
  refundDispute = (
    orderID,
    { totalAmount, comment, merchantId, disputeId }
  ) => {
    return this.request(
      {
        method: 'POST',
        path: this.uri + `/${orderID}/refund`,
        data: {
          total_amount: totalAmount,
          comment: comment,
          merchant_id: merchantId,
          dispute_id: disputeId,
        },
      },
      {
        options: {
          headers: {
            'X-Requested-From': 'partner_portal',
          },
        },
      }
    )
  }

  submitResponse = ({ disputeId, comment, documents, merchantId }) => {
    return this.request(
      {
        method: 'POST',
        path: this.uri + `/dispute/${disputeId}/challenge`,
        data: {
          comment: comment,
          attachments: documents,
          merchant_id: merchantId,
        },
      },
      {
        options: {
          headers: {
            'X-Requested-From': 'partner_portal',
          },
        },
      }
    )
  }
}

export default new MerchantDisputeApi('/merchants/orders')
