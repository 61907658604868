import store from '@/store'

const makeAuthorization = (token) => (token ? `Bearer ${token}` : '')

const attachToken = ({ headers }) => {
  const common = headers.common || {}
  const token = store.getters.token
  const authorization = makeAuthorization(token)

  if (authorization) {
    common.Authorization = authorization
  }

  return {
    headers: {
      ...headers,
      common: {
        ...common,
      },
    },
  }
}

const headers = {
  attachToken,
}

// Export for unit test
export { attachToken, makeAuthorization }
export default { headers }
