import request from '@/utils/request'

const fetchStoreList = (data) => {
  return request({
    path: '/merchants/stores/index',
    method: 'GET',
    data,
  })
}

const fetchStoreDetail = (storeId) => {
  return request({
    path: `/merchants/stores/${storeId}`,
    method: 'GET',
  })
}

const createStore = (storeName) => {
  return request({
    path: `/merchants/stores/create`,
    method: 'POST',
    data: {
      store_name: storeName,
    },
  })
}

const updateStore = ({ storeId, storeName }) => {
  return request({
    path: `/merchants/stores/${storeId}/update`,
    method: 'PATCH',
    data: {
      store_name: storeName,
    },
  })
}

const toggleStatus = ({ storeId }) => {
  return request({
    path: `/merchants/stores/${storeId}/activate`,
    method: 'PATCH',
  })
}
const downloadStores = (data) => {
  return request(
    {
      timeout: 100000, // ms
      method: 'GET',
      path: `/merchants/stores/report`,
      data: data,
    },
    {
      options: {
        hideMessage: true,
        camelizeRes: false,
      },
    }
  )
}

export {
  fetchStoreList,
  fetchStoreDetail,
  createStore,
  updateStore,
  toggleStatus,
  downloadStores,
}
