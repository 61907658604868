import request from '@/utils/request'

const fetchOnboardingData = (id) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${id}`,
      method: 'GET',
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const updateLegal = (data) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${data.onboarding_id}`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const updateBank = (data) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${data.onboarding_id}`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const addContact = (data) => {
  return request(
    {
      path: `/merchants/onboarding/contacts/${data.onboarding_id}`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const uploadFile = (id, formData) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${id}`,
      method: 'POST',
      data: formData,
    },
    {
      options: {
        hideMessage: true,
        disableErrorRedirect: true,
      },
    }
  )
}
const uploadLogo = (id, formData, reCaptchaToken) => {
  return request(
    {
      path: `/merchants/onboarding/${id}/update-logo`,
      method: 'POST',
      data: formData,
    },
    {
      options: {
        hideMessage: true,
        disableErrorRedirect: true,
        headers: { 'x-tamara-token': reCaptchaToken },
      },
    }
  )
}

const getContactDetails = (onboardingId, country) => {
  return request({
    path: `/merchants/onboarding/contacts/${onboardingId}`,
    data: {
      country,
      contact_type: 'customer_care',
    },
  })
}

const createContactDetails = (onboardingId, data) => {
  return request({
    path: `/merchants/onboarding/contacts/${onboardingId}`,
    method: 'POST',
    data,
  })
}
const updateContactDetails = (onboardingId, data) => {
  return request({
    path: `/merchants/onboarding/contacts/${onboardingId}/update`,
    method: 'POST',
    data,
  })
}

const verifyCr = (data) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${data.onboarding_id}/verify-commercial-registration`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const verifyId = (data) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${data.onboarding_id}/verify-national-identity`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const verifyIban = (data) => {
  return request(
    {
      path: `/merchants/onboarding/kyc/${data.onboarding_id}/verify-iban`,
      method: 'POST',
      data,
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const verifyOtp = (onboardingId, otp) => {
  return request({
    path: `/merchants/onboarding/kyc/${onboardingId}/national-id/verify-otp`,
    method: 'POST',
    data: {
      otp,
    },
  })
}

const resendOtp = (onboardingId, data) => {
  return request({
    path: `/merchants/onboarding/kyc/${onboardingId}/national-id/resend-otp`,
    method: 'POST',
    data,
  })
}

const getCountryList = () => {
  return request({
    path: 'merchants/country/list',
    method: 'GET',
  })
}

export {
  fetchOnboardingData,
  updateLegal,
  verifyCr,
  verifyId,
  updateBank,
  addContact,
  uploadFile,
  uploadLogo,
  getContactDetails,
  createContactDetails,
  updateContactDetails,
  verifyOtp,
  verifyIban,
  resendOtp,
  getCountryList,
}
