export const CAMPAIGN_FORM_MODE = {
  CREATE: 'CREATE',
  EDIT: 'EDIT',
}

// User type:
// + existing: have at least 1 campaign created
// + new: have 0 campaign created
export const ADTEC_USER_TYPE = {
  EXISTING: 'existing',
  NEW: 'new',
}
