import crypto from 'crypto'

import {
  fetchStoreList,
  fetchStoreDetail,
  createStore,
  updateStore,
  toggleStatus,
  downloadStores,
} from '@/api/store'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  list: [],
  detail: {},
  storeId: '',
  storesTotalRecords: 0,
  lastError: undefined,
  updatingStatus: false, // Trigger when merchant update status manually
  fetchingList: false,
  fetchingDetail: false,
  downloadingStore: false,
  loading: false,
  page: 1,
  limit: 25,
  queryHash: '',
}

const mutations = {
  ADD_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = data.data
  },
  REMOVE_LIST(state) {
    state.page = 1
    state.list = []
  },
  ADD_STORE_DETAIL(state, { storeId, detail }) {
    state.detail = { ...state.detail, [storeId]: detail }
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.storesTotalRecords = newValue
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
  SET_CURRENT_PAGE(state, newValue) {
    state.page = newValue
  },
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getLoading: (state) => {
    return state.loading
  },
  getStoresTotalRecords: (state) => {
    return state.storesTotalRecords
  },
  getStoreDetail: (state) => (orderId) => {
    return state.detail[orderId] || {}
  },
  getMerchantStores: (state) => {
    return state.merchantStores
  },
  getError: (state) => {
    return state.lastError
  },
}

const actions = {
  async fetchStores({ commit, dispatch, state }, params) {
    try {
      const { page, limit, merchantIds, ...query } = params

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        commit('REMOVE_LIST')
      }

      commit('SET_FETCHING', { name: 'fetchingList', value: true })
      if (merchantIds) {
        query.merchantIds = Array.isArray(merchantIds)
          ? merchantIds
          : [merchantIds]
      }
      const { data } = await fetchStoreList({
        page,
        limit,
        ...query,
      })

      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('ADD_LIST', { data, page })
      commit('SET_CURRENT_PAGE', page)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },
  async fetchStoreDetail({ commit }, storeId) {
    try {
      commit('SET_FETCHING', { name: 'fetchingDetail', value: true })

      const response = await fetchStoreDetail(storeId)

      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
      commit('ADD_STORE_DETAIL', { storeId, detail: response.data })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
    }
  },

  async createStore({ commit }, storeName) {
    try {
      commit('SET_FETCHING', { name: 'loading', value: true })

      await createStore(storeName)

      commit('SET_FETCHING', { name: 'loading', value: false })
    } catch (error) {
      commit('SET_FETCHING', { name: 'loading', value: false })
      commit('SET_ERROR', error)
      return false
    }
  },

  async updateStore({ commit }, { storeId, storeName }) {
    try {
      commit('SET_FETCHING', { name: 'loading', value: true })

      await updateStore({ storeId, storeName })

      commit('SET_FETCHING', { name: 'loading', value: false })
    } catch (error) {
      commit('SET_FETCHING', { name: 'loading', value: false })
      commit('SET_ERROR', error)
      return false
    }
  },

  async toggleStatus({ commit }, storeId) {
    try {
      commit('SET_FETCHING', { name: 'loading', value: true })

      await toggleStatus({ storeId })

      commit('SET_FETCHING', { name: 'loading', value: false })
    } catch (error) {
      commit('SET_FETCHING', { name: 'loading', value: false })
      commit('SET_ERROR', error)
      return false
    }
  },

  async downloadStores({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'downloadingStore', value: true })

      const response = await downloadStores(data)

      commit('SET_FETCHING', { name: 'downloadingStore', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadingStore', value: false })

      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
