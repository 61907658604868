import BaseApi from '@/api/base-api'

class OrdersStatusApi extends BaseApi {
  authoriseOrder = (orderId, { merchantId }) => {
    return this.request({
      method: 'POST',
      path: this.uri + `/${orderId}/authorise`,
      data: {
        merchant_id: merchantId,
      },
    })
  }
}

export default new OrdersStatusApi('/orders')
