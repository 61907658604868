import {
  fetchWebhookList,
  fetchWebhookDetail,
  createWebhook,
  updateWebhook,
  deleteWebhook,
} from '@/api/webhooks'

const state = {
  list: [],
  detail: {},
  orderId: '',
  lastError: undefined,
  fetchingList: false,
}

const mutations = {
  ADD_LIST(state, { data }) {
    state.list = [...data]
  },
  REMOVE_LIST(state) {
    state.list = []
  },
  ADD_WEBHOOK_DETAIL(state, { webhookId, detail }) {
    state.detail = { ...state.detail, [webhookId]: detail }
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
}

const getters = {
  getList: (state) => state.list,
  getDetail: (state) => state.detail,
}

const actions = {
  async fetchWebhookList({ commit, dispatch, state }, params) {
    try {
      commit('SET_FETCHING', { name: 'fetchingList', value: true })
      const { data } = await fetchWebhookList()
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('ADD_LIST', { data })
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },

  async fetchWebhookDetail({ commit }, webhookId) {
    try {
      commit('SET_FETCHING', { name: 'fetchingDetail', value: true })

      const response = await fetchWebhookDetail(webhookId)

      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
      commit('ADD_WEBHOOK_DETAIL', { webhookId, detail: response.data })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async createWebhook({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'creatingWebhook', value: true })

      const response = await createWebhook(data)

      commit('SET_FETCHING', { name: 'creatingWebhook', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'creatingWebhook', value: false })

      throw error
    }
  },

  async updateWebhook({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'updatingWebhook', value: true })

      const response = await updateWebhook(data)

      commit('SET_FETCHING', { name: 'updatingWebhook', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'updatingWebhook', value: false })

      throw error
    }
  },

  async deleteWebhook({ commit }, webhookId) {
    try {
      commit('SET_FETCHING', { name: 'deletingWebhook', value: true })

      const response = await deleteWebhook(webhookId)

      commit('SET_FETCHING', { name: 'deletingWebhook', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'deletingWebhook', value: false })

      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
