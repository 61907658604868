<script>
import { mapActions, mapGetters } from 'vuex'
import { isNumber, doesNotContainSpecialCharacters } from '@/utils/validate'
import { countryData } from '@/constants/country-data'
import { removeSpaceStartAndEnd } from '@/utils/removeSpace'
import { capitalizeFirstLetter } from '@/utils'

const LENGTH_POSTAL_KSA = 5
const LENGTH_POSTAL_OTHER = 6
export default {
  name: 'AddressCollectionDialog',
  props: {
    addressDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.addressDialogVisible,
      loading: false,
      countryData,
      capitalizeFirstLetter,
      addressCollectionForm: {
        countryCode: '',
        locale: 'ar_SA',
        createdAt: '',
        updatedAt: '',
        createdBy: '',
        updatedBy: '',
        province: '',
        district: '',
        city: '',
        postalCode: '',
        buildingNumber: '',
        additionalBuildingNumber: '',
        street: '',
        additionalStreet: '',
      },
      selectedAddress: {},
      confirmVisible: false,
      successVisible: false,
      rules: {
        checkEmpty: [
          {
            required: true,
            message: this.$t('general_validation_empty'),
            trigger: 'blur',
          },
        ],
        alphanumeric: [{ validator: this.checkAlphanumeric, trigger: 'blur' }],
        alphanumericAndEmpty: [
          {
            required: true,
            message: this.$t('general_validation_empty'),
            trigger: 'blur',
          },
          { validator: this.checkAlphanumericAndEmpty, trigger: 'blur' },
        ],
        postalCode: [
          {
            required: true,
            message: this.$t('general_validation_empty'),
            trigger: 'blur',
          },
          { validator: this.checkPostalCode, trigger: 'blur' },
        ],
        additionalBuildingNumber: [
          { validator: this.checkAdditionalNumber, trigger: 'input' },
        ],
        buildingNumber: [
          {
            required: true,
            message: this.$t('general_validation_empty'),
            trigger: 'blur',
          },
          { validator: this.checkBuildingNumber, trigger: 'blur' },
        ],
        additionalStreet: [
          { validator: this.checkAlphanumeric, trigger: 'input' },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('translations', ['currentLocale']),
    ...mapGetters('profile', {
      merchantProfile: 'profile',
    }),
    ...mapGetters('address', ['addresses', 'fetchingAddresses']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    isArabic() {
      return this.currentLocale === 'ar_SA'
    },
    isRequiredFieldEmpty() {
      return (
        !this.addressCollectionForm.district ||
        !this.addressCollectionForm.district ||
        !this.addressCollectionForm.city ||
        !this.addressCollectionForm.postalCode ||
        !this.addressCollectionForm.buildingNumber ||
        !this.addressCollectionForm.street
      )
    },

    merchantCountryData() {
      if (!this.merchantProfile || !this.merchantProfile.country) return {}
      return countryData.find(
        (item) =>
          item.countryCode.toLowerCase() ===
          this.merchantProfile.country.toLowerCase()
      )
    },
    listCountrySelect() {
      const listCountryCodes = this.addresses.map((address) => address.country)
      return countryData.filter((country) =>
        listCountryCodes.includes(country.countryCode)
      )
    },
  },
  watch: {
    'addressCollectionForm.countryCode': {
      handler(newCountryCode) {
        if (!this.addresses || this.addresses.length < 1) return {}
        this.selectedAddress = this.addresses.find((item) => {
          return item.country && item.country === newCountryCode
        })

        if (!this.selectedAddress) return
        this.addressCollectionForm = {
          ...this.addressCollectionForm,
          province: this.selectedAddress.address.province,
          district: this.selectedAddress.address.district,
          city: this.selectedAddress.address.city,
          buildingNumber: this.selectedAddress.address.buildingNumber,
          street: this.selectedAddress.address.street,
          additionalStreet: this.selectedAddress.address.additionalStreet,
          postalCode: this.selectedAddress.address.zipCode,
          additionalBuildingNumber: this.selectedAddress.address
            .additionalBuildingNumber,
          createdAt: this.selectedAddress.address.createdAt,
          updatedAt: this.selectedAddress.address.updatedAt,
          createdBy: this.selectedAddress.address.createdBy,
          updatedBy: this.selectedAddress.address.updatedBy,
        }
      },
    },
    addresses: {
      handler(newAddresses) {
        if (!newAddresses || newAddresses.length < 1) return {}
        this.selectedAddress = newAddresses.find((item) => {
          return item.country && item.country === this.selectedAddress.country
        })
        if (!this.selectedAddress) return
        this.addressCollectionForm = {
          ...this.addressCollectionForm,
          updatedAt: this.selectedAddress?.address?.updatedAt,
          updatedBy: this.selectedAddress?.address?.updatedBy,
        }
      },
    },
  },
  async created() {
    await this.fetchAddresses()
    this.setDefaultCountry()
  },
  methods: {
    ...mapActions('address', ['fetchAddresses', 'updateAddress']),
    closeDialog() {
      this.dialogVisible = false
      this.$emit('closeDialog')
    },
    toggleConfirm() {
      this.confirmVisible = !this.confirmVisible
    },
    toggleSuccess() {
      this.successVisible = !this.successVisible
    },
    closeAfterSuccess() {
      this.confirmVisible = false
      this.successVisible = false
      this.closeDialog()
    },
    onClickUpdate() {
      this.$refs.addressCollectionForm.validate(async (valid) => {
        if (valid) {
          this.confirmVisible = !this.confirmVisible
        }
      })
    },
    setDefaultCountry() {
      const isContainSA = this.addresses.some((item) => item.country === 'SA')
      if (isContainSA) this.addressCollectionForm.countryCode = 'SA'
      else
        this.addressCollectionForm.countryCode = this.merchantCountryData.countryCode
    },
    checkEmpty(value) {
      return value?.trim()?.length === 0
    },
    checkPostalCode(rule, value, callback) {
      if (this.checkEmpty(value))
        callback(new Error(this.$t('general_validation_empty')))
      const isKSA = this.addressCollectionForm.countryCode === 'SA'
      if (isKSA) {
        if (isNumber(+value)) {
          const isValidLength = value.length === LENGTH_POSTAL_KSA
          if (isValidLength) callback()
          else callback(new Error(this.$t('invalid_postal_length')))
        } else if (!doesNotContainSpecialCharacters(value))
          callback(new Error(this.$t('no_special_character')))
        else callback(new Error(this.$t('number_only_error')))
      } else {
        if (!doesNotContainSpecialCharacters(value))
          callback(new Error(this.$t('no_special_character')))
        const isValidLength = value.length <= LENGTH_POSTAL_OTHER
        if (isValidLength) callback()
        else callback(new Error(this.$t('invalid_postal_length')))
      }
    },
    checkAlphanumericAndEmpty(rule, value, callback) {
      if (this.checkEmpty(value))
        callback(new Error(this.$t('general_validation_empty')))
      if (!value) callback()
      if (!doesNotContainSpecialCharacters(value))
        callback(new Error(this.$t('alphanumeric_only_error')))
      callback()
    },
    checkAlphanumeric(rule, value, callback) {
      if (!value) callback()
      if (!doesNotContainSpecialCharacters(value))
        callback(new Error(this.$t('alphanumeric_only_error')))
      callback()
    },
    checkAdditionalNumber(rule, value, callback) {
      if (this.checkEmpty(value))
        callback(new Error(this.$t('general_validation_empty')))
      if (!value) callback()
      if (!isNumber(+value) || !Number.isInteger(+value)) {
        callback(new Error(this.$t('number_only_error')))
      }
      callback()
    },
    checkBuildingNumber(rule, value, callback) {
      if (this.checkEmpty(value))
        callback(new Error(this.$t('general_validation_empty')))
      if (!isNumber(+value) || !Number.isInteger(+value)) {
        callback(new Error(this.$t('number_only_error')))
      }
      callback()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    async submitForm(formName) {
      const isValid = await this.$refs[formName].validate()
      if (!isValid) return
      try {
        this.loading = true
        if (!this.selectedAddress.merchantAccountId) {
          throw new Error(this.$t('no_merchant_id_error'))
        }
        await this.updateAddress({
          merchantAccountId: this.selectedAddress.merchantAccountId,
          payload: {
            ...this.addressCollectionForm,
            province: removeSpaceStartAndEnd(
              this.addressCollectionForm.province
            ),
            district: removeSpaceStartAndEnd(
              this.addressCollectionForm.district
            ),
            city: removeSpaceStartAndEnd(this.addressCollectionForm.city),
            buildingNumber: removeSpaceStartAndEnd(
              this.addressCollectionForm.buildingNumber
            ),
            street: removeSpaceStartAndEnd(this.addressCollectionForm.street),
            additionalStreet: removeSpaceStartAndEnd(
              this.addressCollectionForm.additionalStreet
            ),
            postalCode: removeSpaceStartAndEnd(
              this.addressCollectionForm.postalCode
            ),
            additionalBuildingNumber: removeSpaceStartAndEnd(
              this.addressCollectionForm.additionalBuildingNumber
            ),
          },
        })
        await this.fetchAddresses()
        this.toggleSuccess()
      } catch (e) {
        this.$message({
          type: 'error',
          message: e.message,
        })
      } finally {
        this.loading = false
        this.toggleConfirm()
      }
    },
    backToList() {
      this.$router.push({
        path: `/users/list`,
      })
    },
  },
}
</script>

<template>
  <div class="address-collection-wrapper">
    <el-dialog
      show-close
      :before-close="closeDialog"
      :close-on-click-modal="false"
      class="address-collection__dialog"
      :visible.sync="dialogVisible"
      @close="closeDialog"
    >
      <div class="address-collection__modal-title">
        {{
          $t('update_address_title', {
            name: capitalizeFirstLetter(userProfile.firstName),
          })
        }}
      </div>
      <div class="address-collection__modal-sub-title">
        {{ $t('update_address_sub_title') }}
      </div>
      <div class="address-collection">
        <div class="address-collection__first">
          <div class="user-form">
            <el-form
              ref="addressCollectionForm"
              max-width="600px"
              label-width="200px"
              :label-position="isArabic ? 'right' : 'left'"
              :model="addressCollectionForm"
            >
              <div class="address-collection__account-information">
                <el-form-item prop="country">
                  <div>
                    <div class="address-collection__account-information-label">
                      {{ $t('address_collection_country_label') }}
                      <span class="address-collection__red-dot">*</span>
                    </div>
                  </div>
                  <div class="flex flex-no-wrap">
                    <el-select
                      v-model="addressCollectionForm.countryCode"
                      :placeholder="$t('address_collection_country_label')"
                      popper-class="global__select"
                      class=" w-full"
                    >
                      <el-option
                        v-for="(item, index) in listCountrySelect"
                        :key="index"
                        :label="
                          isArabic ? item.countryNameAr : item.countryName
                        "
                        :value="item.countryCode"
                      >
                        <div class="address-collection-select-container">
                          <div class="global__select-prefix-icon">
                            <svg-icon
                              :icon-class="`${item.countryCode.toLowerCase()}`"
                            />
                          </div>
                          <div>
                            {{
                              isArabic
                                ? `${item.countryNameAr}`
                                : `${item.countryName}`
                            }}
                          </div>
                        </div>
                      </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
              <div class="address-collection__detail-block">
                <el-form-item
                  :label="$t('address_collection_province_label')"
                  prop="province"
                  :placeholder="$t('address_collection_province_label')"
                  :rules="rules.alphanumeric"
                >
                  <el-input
                    v-model="addressCollectionForm.province"
                    class="value-field"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_district_label')"
                  prop="district"
                  :placeholder="$t('address_collection_district_label')"
                  :rules="rules.alphanumericAndEmpty"
                >
                  <el-input
                    v-model="addressCollectionForm.district"
                    class="value-field"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_city_label')"
                  prop="city"
                  :placeholder="$t('address_collection_city_label')"
                  :rules="rules.alphanumericAndEmpty"
                >
                  <el-input
                    v-model="addressCollectionForm.city"
                    class="value-field"
                    autocomplete="off"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_postal_code_label')"
                  prop="postalCode"
                  :placeholder="$t('address_collection_postal_code_label')"
                  :rules="rules.postalCode"
                >
                  <el-input
                    v-model="addressCollectionForm.postalCode"
                    type="postalCode"
                    class="value-field"
                    autocomplete="off"
                  />
                </el-form-item>
              </div>
              <div class="address-collection__detail-block">
                <el-form-item
                  address_collection_building_label
                  :label="$t('address_collection_building_label')"
                  prop="buildingNumber"
                  :placeholder="$t('address_collection_building_label')"
                  :rules="rules.buildingNumber"
                >
                  <el-input
                    v-model="addressCollectionForm.buildingNumber"
                    class="value-field"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_additional_number_label')"
                  prop="additionalBuildingNumber"
                  :placeholder="
                    $t('address_collection_additional_number_label')
                  "
                  :rules="rules.additionalBuildingNumber"
                >
                  <el-input
                    v-model="addressCollectionForm.additionalBuildingNumber"
                    class="value-field"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_street_label')"
                  prop="street"
                  placeholder="$t('address_collection_street_label')"
                  :rules="rules.alphanumericAndEmpty"
                >
                  <el-input
                    v-model="addressCollectionForm.street"
                    class="value-field"
                  />
                </el-form-item>
                <el-form-item
                  :label="$t('address_collection_additional_street_label')"
                  prop="additionalStreet"
                  placeholder="$t('address_collection_additional_street_label')"
                  :rules="rules.additionalStreet"
                >
                  <el-input
                    v-model="addressCollectionForm.additionalStreet"
                    type="email"
                    class="value-field"
                    autocomplete="off"
                  />
                </el-form-item>
              </div>

              <el-button
                type="primary"
                class="address-collection__save address-collection__save--second global__button-black"
                :disabled="loading || isRequiredFieldEmpty"
                @click="onClickUpdate"
              >
                {{ $t('address_collection_update_button') }}
              </el-button>
              <el-button
                type="primary"
                class="address-collection__save address-collection__update-later "
                :disabled="loading"
                @click="closeDialog"
              >
                {{ $t('address_collection_update_later') }}
              </el-button>
            </el-form>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :custom-class="
        'address-collection_dialog__dialog el-message-box global__notification-box global__notification-box--success el-message-box--center address-collection_dialog__confirm-box'
      "
      center
      :title="$t('address_collection_confirm_title')"
      :visible="confirmVisible"
      :show-close="false"
    >
      <div class=" address-collection_dialog__form-note">
        <div>
          {{ $t('address_collection_confirm_sub_title') }}
        </div>
      </div>
      <span slot="footer">
        <div class="flex flex-col">
          <el-button
            class="address-collection_dialog__dialog-button"
            :loading="loading"
            @click="submitForm('addressCollectionForm')"
          >
            {{ $t('text_submit') }}
          </el-button>
          <el-button
            v-if="!loading"
            class="address-collection_dialog__dialog-button-cancel"
            @click="toggleConfirm"
          >
            {{ $t('text_cancel') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
    <el-dialog
      :custom-class="
        'address-collection_dialog__dialog el-message-box global__notification-box global__notification-box--success el-message-box--center address-collection_dialog__confirm-box address-collection--success'
      "
      center
      :title="$t('address_collection_update_success_dialog_title')"
      :visible="successVisible"
      :show-close="false"
      :before-close="closeAfterSuccess"
    >
      <div class=" address-collection_dialog__form-note">
        <div>
          {{ $t('address_collection_update_success') }}
        </div>
      </div>
      <span slot="footer">
        <div class="flex flex-col">
          <el-button
            class="address-collection_dialog__dialog-button"
            @click="
              () => {
                toggleSuccess()
                closeDialog()
              }
            "
          >
            {{ $t('address_collection_done') }}
          </el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.address-collection__dialog ::v-deep .el-dialog {
  border-radius: 14px;
  box-shadow: 0 4px 54px 0 rgba(0, 0, 0, 0.06);
  transition: all 200ms ease-in;

  h2 {
    [dir='ltr'] & {
      font-family: 'Space Grotesk', sans-serif;
      text-align: left;
    }

    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
      text-align: right;
    }
  }
}

.address-collection__dialog ::v-deep .el-dialog__body {
  padding: 15px 64px;
}
.address-collection__dialog ::v-deep .el-dialog__header {
  text-align: center !important;
}
.address-collection__modal-title {
  margin-bottom: 8px;
  font-size: 28px;
  font-weight: 700;
  color: #000;
  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
    text-align: left;
  }
  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    text-align: right;
  }
}
.address-collection__modal-sub-title {
  margin-bottom: 20px;
  font-size: 14px;
}
.address-collection {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: #666;

  > .address-collection__first {
    width: 100%;
  }

  > .address-collection__second {
    width: 31%;
    min-width: 320px;
    margin-top: 50px;
  }
}
.address-collection__tag {
  margin-top: 0;
  margin-bottom: 16px;
}
.address-collection_form-block {
  padding: 24px 24px 8px;
  margin-bottom: 0;
  background: white;
  border: 1.5px solid #f2f2f2;
  border-radius: 12px;
}
.address-collection_update-note {
  margin-bottom: 16px;
}
.address-collection__title-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  > button {
    [dir='ltr'] & {
      margin-right: 20px;
    }

    [dir='rtl'] & {
      margin-left: 20px;
    }
  }
}

.address-collection__back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 100%;

  svg {
    [dir='rtl'] & {
      transform: rotate(180deg);
    }
  }
}

.address-collection__additional-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;

  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }

  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

.address-collection__second {
  padding: 24px;
  background: white;
  border: 1.5px solid #f2f2f2;
  border-radius: 12px;
}

.address-collection__additional-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.address-collection ::v-deep .el-form-item__label {
  float: none;
  width: initial;
  padding: 0;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  color: #000;
}

.address-collection ::v-deep .el-form-item__content {
  margin: initial !important;
  font-weight: 500;
}

.address-collection ::v-deep .el-form-item__error {
  margin-top: 2px;
  font-weight: 400;
}

.address-collection__detail-block {
  display: flex;
  flex-flow: wrap;

  > * {
    width: 50%;
  }

  > *:nth-child(odd) {
    [dir='ltr'] & {
      padding-right: 16px;
    }

    [dir='rtl'] & {
      padding-left: 16px;
    }
  }
}

.address-collection-select-container {
  display: flex;
  align-items: center;

  > div:first-child {
    [dir='ltr'] & {
      margin-right: 10px;
    }

    [dir='rtl'] & {
      margin-left: 10px;
    }
  }
}

.address-collection ::v-deep .el-form-item {
  margin-bottom: 24px;
}

.address-collection__subtitle {
  margin-bottom: 15px;
  font-size: 20px;
  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }

  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

.address-collection__divider {
  height: 1px;
  margin: 24px 0;
  background: transparent;
}

.address-collection__actions {
  margin-bottom: 20px;
}

.address-collection__actions-subtitle {
  font-size: 16px;
  font-weight: 600;

  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }

  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

.address-collection ::v-deep .el-input__inner {
  min-height: 44px !important;
  border: 1.5px solid #e5e5e5;
  border-radius: 8px;
}

.address-collection__save {
  width: 100%;
  margin-top: 18px;
}
.address-collection__update-later {
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 0;
  color: #000;
  background: transparent;
  border: none;
}
.address-collection__account-information {
  display: flex;
  margin-top: 0;

  > div {
    width: 100%;
  }
}

.address-collection__account-information-label {
  margin-top: 12px;
  margin-bottom: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  color: #000;
  [dir='ltr'] & {
    font-family: 'Helvetica Neue', sans-serif;
  }

  [dir='rtl'] & {
    margin-left: 20px;
  }

  span {
    font-weight: normal;
  }
}

.address-collection__red-dot {
  font-size: 16px;
  color: #e83550;
}

.address-collection-wrapper
  ::v-deep
  .address-collection_dialog__dialog
  .el-dialog__body {
  padding-top: 0;
  text-align: center;
}

.address-collection_dialog__dialog {
  max-width: 552px;
  padding: 38px 48px 40px;
  border-radius: 24px;
}

.address-collection-wrapper
  ::v-deep
  .address-collection_dialog__dialog
  .el-dialog__footer {
  padding-bottom: 0;
}

.address-collection-wrapper
  ::v-deep
  .address-collection_dialog__dialog
  .el-dialog__header
  .el-dialog__title {
  display: block;
  font-size: 28px !important;
  font-weight: 700;
  line-height: 36px;
  color: #000;
  text-align: center;
  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }
  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}
.address-collection-wrapper
  ::v-deep
  .address-collection--success
  .el-dialog__header::before {
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='72' height='73' viewBox='0 0 72 73' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0 36.5C0 16.6178 16.1178 0.5 36 0.5C55.8823 0.5 72 16.6178 72 36.5C72 56.3823 55.8823 72.5 36 72.5C16.1178 72.5 0 56.3823 0 36.5Z' fill='%2393BA30' fill-opacity='0.1'/%3e%3cpath d='M36 20.25C27.0333 20.25 19.75 27.5333 19.75 36.5C19.75 45.4667 27.0333 52.75 36 52.75C44.9667 52.75 52.25 45.4667 52.25 36.5C52.25 27.5333 44.9667 20.25 36 20.25ZM43.4333 32.4333L33.4333 42.4333C33.2333 42.6333 32.95 42.75 32.6667 42.75C32.6333 42.75 32.6 42.75 32.5667 42.75C32.25 42.7167 31.95 42.55 31.7667 42.2667L28.4333 37.2667C28.1 36.7667 28.2333 36.1 28.7333 35.7667C29.2333 35.4333 29.9 35.5667 30.2333 36.0667L32.8333 39.9667L41.9 30.9C42.3167 30.4833 43.0167 30.4833 43.4333 30.9C43.85 31.3167 43.85 32 43.4333 32.4333Z' fill='%2393BA30'/%3e%3c/svg%3e");
}
.address-collection-wrapper
  ::v-deep
  .address-collection--success
  .el-dialog__header {
  text-align: center;
}
.address-collection-wrapper
  ::v-deep
  .address-collection_dialog__dialog
  .el-dialog__close {
  display: none;
}

.address-collection_dialog__dialog-button {
  padding: 17px 32px;
  font-size: 16px;
  color: white;
  background-color: #000;
  border-radius: 12px;
  &:hover,
  &:focus,
  &:active {
    color: white;
    background-color: #000;
  }
}

.address-collection_dialog__dialog-button-cancel {
  padding: 17px 32px;
  margin-left: 0;
  font-size: 16px;
  border: none;
  &:hover,
  &:focus,
  &:active {
    color: #606266;
    background-color: transparent;
    border: none;
  }
}

.address-collection-wrapper ::v-deep .el-dialog__wrapper:nth-child(2),
.address-collection-wrapper ::v-deep .el-dialog__wrapper:nth-child(3) {
  text-align: center;
}
</style>
