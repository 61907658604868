<template>
  <div v-if="isKycUser && kycId" class="kyc-id">
    <div>
      <div>
        <svg-icon :icon-class="'kyc-id'" />
      </div>
      <div class="kyc-id__id-container ellipsis">
        ID #<CopyText :label="ellipsisCenter(kycId)" :value="kycId" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ellipsisCenter } from '@/utils/index.js'
import CopyText from '@/components/CopyText'

export default {
  components: {
    CopyText,
  },
  data() {
    return {
      ellipsisCenter,
    }
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    ...mapGetters('translations', ['currentLocale']),
    ...mapGetters('kyc', ['onboardingData']),
    isArabic() {
      return this.currentLocale === 'ar_SA'
    },
    isKycUser() {
      return this.userProfile && this.userProfile.role === 'MERCHANT_KYC_USER'
    },
    kycId() {
      return this.onboardingData.salesforceLeadId || null
    },
  },
  methods: {},
}
</script>

<style lang="scss">
.kyc-id__actions {
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.kyc-id__actions .el-dropdown-menu__item {
  line-height: 40px !important;
}

.kyc-id__actions-item {
  display: flex;
  align-items: center;
  min-width: 87px;
  &:last-child {
    border-top: 1px solid #e5e5e5;
  }
  svg {
    [dir='ltr'] & {
      margin-right: 14px;
    }
    [dir='rtl'] & {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
.kyc-id {
  display: flex;
  justify-content: flex-end;
  svg {
    width: 18px;
    height: 18px;
    margin: 0 6px;
  }
}

.kyc-id > div {
  display: flex;
  align-items: center;
  margin: 0 25px;
  &:last-child {
    margin-right: 0;
    [dir='rtl'] & {
      margin-left: 0;
    }
  }
}

.kyc-id__id-container {
  display: flex;
  justify-content: center;
}

.kyc-id__id-container > div {
  [dir='rtl'] & {
    direction: rtl;
  }
}
.kyc-id__id-container button {
  margin-bottom: -3px;
}
</style>
