const events = {
  googleAnalytics: {
    partner_signup_click: 'partner_signup_click',
    partner_signup_form_view: 'partner_signup_form_view',
    partner_signup_form_password_completed:
      'partner_signup_form_password_completed',
    partner_signup_form_basic_completed: 'partner_signup_form_basic_completed',
    partner_signup_form_business_completed:
      'partner_signup_form_business_completed',
    partner_signup_form_pricing_completed:
      'partner_signup_form_pricing_completed',
    partner_signup_form_completed: 'partner_signup_form_completed',
    partner_kyc_legal_information_completed:
      'partner_kyc_legal_information_completed',
    partner_kyc_bank_information_completed:
      'partner_kyc_bank_information_completed',
    partner_kyc_additional_information_completed:
      'partner_kyc_additional_information_completed',
    partner_kyc_completed: 'partner_kyc_completed',
    // video events
    partner_signup_video_started: 'partner_signup_video_started',
    partner_signup_video_paused: 'partner_signup_video_paused',
    partner_signup_video_watched_0_percent:
      'partner_signup_video_watched_0_percent',
    partner_signup_video_watched_25_percent:
      'partner_signup_video_watched_25_percent',
    partner_signup_video_watched_50_percent:
      'partner_signup_video_watched_50_percent',
    partner_signup_video_watched_75_percent:
      'partner_signup_video_watched_75_percent',
    partner_signup_video_watched_100_percent:
      'partner_signup_video_watched_100_percent',
  },
  facebookPixel: {
    partner_signup_click: 'partner_signup_click',
    partner_signup_form_view: 'partner_signup_form_view',
    partner_signup_form_basic_completed: 'partner_signup_form_basic_completed',
    partner_signup_form_business_completed:
      'partner_signup_form_business_completed',
    partner_signup_form_pricing_completed:
      'partner_signup_form_pricing_completed',
    partner_signup_form_completed: 'SubmitApplication',
    partner_kyc_legal_information_completed:
      'partner_kyc_legal_information_completed',
    partner_kyc_bank_information_completed:
      'partner_kyc_bank_information_completed',
    partner_kyc_additional_information_completed:
      'partner_kyc_additional_information_completed',
    partner_kyc_completed: 'partner_kyc_completed',
  },
  googleAds: {
    partner_signup_click: 'mWT6CKCY4fIYELfa754q',
    partner_signup_form_view: 'w5ZDCIn71e8YELfa754q',
    partner_signup_form_basic_completed: '4FelCJX71e8YELfa754q',
    partner_signup_form_business_completed: 'a4AFCJL71e8YELfa754q',
    partner_signup_form_pricing_completed: '9SfJCI_71e8YELfa754q',
    partner_signup_form_completed: 'CV6uCIz71e8YELfa754q',
  },
  linkedin: {
    partner_signup_click: 16025777,
    partner_signup_form_view: 16024937,
    partner_signup_form_basic_completed: 16024969,
    partner_signup_form_business_completed: 16024961,
    partner_signup_form_pricing_completed: 16024953,
    partner_signup_form_completed: 16024945,
  },
}

const fireEvent = (eventName) => {
  if (
    typeof window === 'undefined' ||
    typeof events === 'undefined' ||
    typeof eventName === 'undefined'
  )
    return

  // Check if external scripts are loaded
  const isGtagLoaded = window.gtag instanceof Function
  const isFbqLoaded = window.fbq instanceof Function
  const isLintrkLoaded = window.lintrk instanceof Function

  // Check if event has already been fired
  const firedEvents = JSON.parse(localStorage.getItem('firedEvents')) || {}
  if (firedEvents[eventName]) {
    return
  }

  // Fire Google Analytics Event
  if (
    events.googleAnalytics &&
    eventName in events.googleAnalytics &&
    isGtagLoaded
  ) {
    window.gtag('event', events.googleAnalytics[eventName])
  }

  // Fire Facebook Pixel Event
  if (
    events.facebookPixel &&
    eventName in events.facebookPixel &&
    isFbqLoaded
  ) {
    window.fbq('track', events.facebookPixel[eventName])
  }

  // Fire Google Ads Event
  if (
    events.googleAds &&
    eventName in events.googleAds &&
    isGtagLoaded &&
    process.env.VUE_APP_GOOGLE_ADS_ID
  ) {
    window.gtag('event', 'conversion', {
      send_to:
        process.env.VUE_APP_GOOGLE_ADS_ID + '/' + events.googleAds[eventName],
    })
  }

  // Fire LinkedIn Event
  if (events.linkedin && eventName in events.linkedin && isLintrkLoaded) {
    window.lintrk('track', { conversion_id: events.linkedin[eventName] })
  }

  // Mark event as fired
  firedEvents[eventName] = true
  localStorage.setItem('firedEvents', JSON.stringify(firedEvents))
}

export { fireEvent }

const ADS_GA_EVENTS = Object.freeze({
  PAGE_VIEW: 'ads_page_view',
  CAMPAIGN_CREATE_START: 'ads_campaign_create_start',
  CAMPAIGN_CREATE_PROGRESS: 'ads_campaign_create_progress',
  CAMPAIGN_CREATE_COMPLETE: 'ads_campaign_create_complete',
  CAMPAIGN_EDIT_START: 'ads_campaign_edit_start',
  CAMPAIGN_EDIT_COMPLETE: 'ads_campaign_edit_complete',
  TOPUP_START: 'ads_topup_start',
  TOPUP_PROGRESS: 'ads_topup_progress',
  TOPUP_COMPLETE: 'ads_topup_complete',
  CAMPAIGN_MANAGE_PAUSE: 'ads_campaign_manage_pause',
  CAMPAIGN_MANAGE_DELETE: 'ads_campaign_manage_delete',
  CAMPAIGN_MANAGE_EDIT: 'ads_campaign_manage_edit',
  CAMPAIGN_MANAGE_REVIEW: 'ads_campaign_manage_review',
  CAMPAIGN_MANAGE_DUPLICATE: 'ads_campaign_manage_duplicate',
  METRIC_VIEW: 'ads_metrics_view',
  DASHBOARD_COMPAREPREVIOUS: 'ads_dashboard_compareprevious',
  CAMPAIGN_MANAGE_RESUME: 'ads_campaign_manage_resume',
  CAMPAIGN_CREATE_CREATIVE_START: 'ads_campaign_create_creative_start',
  CAMPAIGN_CREATE_CREATIVE_NEW_COMPLETE: 'ads_campaign_create_creative_new_end',
  CAMPAIGN_CREATE_CREATIVE_EXISTING_COMPLETE:
    'ads_campaign_create_creative_exist_end',
  CAMPAIGN_EDIT_CREATIVE_START: 'ads_campaign_edit_creative_start',
  CAMPAIGN_EDIT_CREATIVE_NEW_COMPLETE: 'ads_campaign_edit_creative_new_end',
  CAMPAIGN_EDIT_CREATIVE_EXISTING_COMPLETE:
    'ads_campaign_edit_creative_exist_end',
  ADS_BANNER_CLICK: 'ads_banner_click',
  ADS_CREATE_ADVERTISER: 'ads_advertiser_created',
})

// Custom Session ID field name to store form session id without conflicting with each other flows
const SESSION_ID_FIELD = {
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_START]: 'create_campaign_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_PROGRESS]: 'create_campaign_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_COMPLETE]: 'create_campaign_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_EDIT_START]: 'edit_campaign_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_EDIT_COMPLETE]: 'edit_campaign_session_id',
  [ADS_GA_EVENTS.TOPUP_START]: 'topup_session_id',
  [ADS_GA_EVENTS.TOPUP_PROGRESS]: 'topup_session_id',
  [ADS_GA_EVENTS.TOPUP_COMPLETE]: 'topup_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_CREATIVE_START]:
    'campaign_create_creative_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_CREATIVE_NEW_COMPLETE]:
    'campaign_create_creative_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_CREATE_CREATIVE_EXISTING_COMPLETE]:
    'campaign_create_creative_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_EDIT_CREATIVE_START]:
    'campaign_edit_creative_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_EDIT_CREATIVE_NEW_COMPLETE]:
    'campaign_edit_creative_session_id',
  [ADS_GA_EVENTS.CAMPAIGN_EDIT_CREATIVE_EXISTING_COMPLETE]:
    'campaign_edit_creative_session_id',
}

const SESSION_START_EVENTS = [
  ADS_GA_EVENTS.CAMPAIGN_CREATE_START,
  ADS_GA_EVENTS.CAMPAIGN_EDIT_START,
  ADS_GA_EVENTS.TOPUP_START,
  ADS_GA_EVENTS.CAMPAIGN_CREATE_CREATIVE_START,
  ADS_GA_EVENTS.CAMPAIGN_EDIT_CREATIVE_START,
]

const emitAdsEvent = (event, parameters = {}) => {
  if (!event || !window || !(window.gtag instanceof Function)) {
    return
  }

  if (SESSION_START_EVENTS.includes(event)) {
    localStorage.setItem(SESSION_ID_FIELD[event], Date.now())
  }

  if (SESSION_ID_FIELD[event]) {
    parameters.form_session_id = JSON.parse(
      localStorage.getItem(SESSION_ID_FIELD[event])
    )
  }

  parameters.merchant_id = localStorage.getItem('selectedMerchant')

  window.gtag('event', event, parameters)
}

export { ADS_GA_EVENTS, emitAdsEvent }
