import {
  fetchApiKeys,
  fetchOtherKeys,
  sendOtp,
  confirmOtp,
  verifyOtp2FA,
  resendOtp2FA,
} from '@/api/api'
import { setToken } from '@/utils/auth'

const state = {
  fetching: false,
  apiKeys: [],
  otherKeys: [],
}

const getters = {
  isFetching: (state) => state.fetching,
  getApiKeys: (state) => {
    return state.apiKeys || []
  },
  getOtherKeys: (state) => {
    return state.otherKeys || []
  },
}

const mutations = {
  UPDATE_FETCHING: (state, fetching) => {
    state.fetching = fetching
  },
  UPDATE_API_KEYS: (state, { apis }) => {
    state.apiKeys = apis
  },
  UPDATE_OTHER_KEYS: (state, { apis = {} }) => {
    const keys = apis?.keys || []
    state.otherKeys = keys.map((key) => ({
      ...key,
      merchantName: apis.merchantName,
      merchantId: apis.merchantId,
    }))
  },
}

const actions = {
  async fetchApiKeys({ commit, dispatch, state }) {
    commit('UPDATE_FETCHING', true)

    const { data } = await fetchApiKeys()

    commit('UPDATE_API_KEYS', { apis: data?.data || [] })
    commit('UPDATE_FETCHING', false)

    return { data }
  },

  async fetchOtherKeys({ commit, dispatch, state }) {
    commit('UPDATE_FETCHING', true)

    const { data } = await fetchOtherKeys()

    commit('UPDATE_OTHER_KEYS', { apis: data?.data[0] || {} })
    commit('UPDATE_FETCHING', false)

    return data
  },

  async sendOtp({ commit }, { email }) {
    commit('UPDATE_FETCHING', true)

    const { data } = await sendOtp({ email })

    commit('UPDATE_FETCHING', false)

    return data
  },

  async confirmOtp({ commit }, { email, code }) {
    commit('UPDATE_FETCHING', true)

    const { data } = await confirmOtp({ email, code })

    commit('UPDATE_FETCHING', false)

    return data
  },

  async verifyOtp2FA({ commit }, { email, otp }) {
    commit('UPDATE_FETCHING', true)
    try {
      const { data } = await verifyOtp2FA({ email, otp })
      if (data.token) {
        commit('user/SET_TOKEN', data.token, { root: true })
        setToken(data.token)
      }
      commit('UPDATE_FETCHING', false)
      return data
    } catch (error) {
      commit('UPDATE_FETCHING', false)

      return { error: error?.response?.data }
    }
  },

  async resendOtp2FA({ commit }, { email }) {
    commit('UPDATE_FETCHING', true)
    try {
      const { data } = await resendOtp2FA({ email })
      commit('UPDATE_FETCHING', false)
      return data
    } catch (error) {
      commit('UPDATE_FETCHING', false)
      return { error: error?.response?.data }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
