<template>
  <div class="navbar">
    <div class="navbar__right">
      <OrderIds />
      <WebhookId />
      <KycId />
      <UserId />
      <div v-if="this.$route && this.$route.name === 'order-detail'">
        <Tutorial />
      </div>
      <div v-if="isDashboard" class="navbar__last-update">
        <div> {{ $t('dashboard_last_update') }} 20:00 pm </div>
        <div @click="refreshPage">
          <svg-icon icon-class="refresh" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderIds from '@/components/OrderIds'
import WebhookId from '@/components/WebhookId'
import KycId from '@/components/KycId'
import UserId from '@/components/UserId'
import Tutorial from '@/views/tutorial/index.vue'

export default {
  name: 'Navbar',
  components: {
    Tutorial,
    OrderIds,
    WebhookId,
    KycId,
    UserId,
  },
  computed: {
    isDashboard() {
      if (this.$route && this.$route.name !== 'something-that-doesnot-exist')
        return false
      return this.$route && this.$route.name === 'dashboard-home'
    },
  },
  methods: {
    refreshPage() {
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_breakpoints';
.navbar {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: auto;
  padding-top: 16px;
  padding-right: 30px;
  padding-bottom: 8px;
  padding-left: 39px;
  overflow: hidden;
  background: #fff;

  /* border-bottom: 1.5px solid #e5e5e5; */

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }
  .vertical-align-text-bottom {
    vertical-align: text-bottom;
  }
}
.navbar__right {
  display: flex;
  margin-right: 45px;
  [dir='rtl'] & {
    margin-right: 0;
    margin-left: 25px;
  }
}

.navbar__last-update {
  display: flex;
  align-items: center;
  > div:first-child {
    margin-right: 10px;
    font-size: 14px;
    color: #666;
    [dir='rtl'] & {
      margin-left: 10px;
    }
  }
  > div:last-child {
    cursor: pointer;
  }
}
</style>
