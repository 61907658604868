<script>
import { mapGetters } from 'vuex'
import ContactDetails from '@/api/merchant/contact-details'
import { countryData } from '@/constants/country-data'
import { isValidUrlWithOptionalProtocol } from '@/utils/validate'

export default {
  name: 'ContactDialog',
  props: {
    contactDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.contactDialogVisible,
      countryData,
      contactData: {
        email: '',
        country_code: '',
        phone: '',
        whatsapp_country_code: '',
        whatsapp: '',
        support_centre_url_en: '',
        support_centre_url_ar: '',
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    rules() {
      return {
        email: [
          {
            type: 'email',
            message: this.$t('contact_email_validation'),
            trigger: 'blur',
          },
        ],
        phone: [
          {
            validator: this.checkPhoneNumber,
            trigger: 'blur',
          },
        ],
        whatsapp: [
          {
            validator: this.checkWhatsAppPhone,
            trigger: 'blur',
          },
        ],
        support_centre_url_en: [
          {
            validator: this.validateWebsiteUrl,
            trigger: 'blur',
          },
        ],
        support_centre_url_ar: [
          {
            validator: this.validateWebsiteUrl,
            trigger: 'blur',
          },
        ],
      }
    },
    firstName() {
      return this.userProfile.firstName
    },
  },
  created() {
    this.fetchDetails()
    this.setDefaultCountryCodes()
  },
  methods: {
    setDefaultCountryCodes() {
      const defaultCountryCode = this.getTelephoneCodeByCountry(
        this.profile.country
      )
      if (defaultCountryCode) {
        this.contactData.country_code = defaultCountryCode
        this.contactData.whatsapp_country_code = defaultCountryCode
      }
    },
    getTelephoneCodeByCountry(countryCode) {
      const country = this.countryData.find(
        (c) => c.countryCode === countryCode
      )
      return country ? country.telephoneCode : null
    },
    closeDialog() {
      this.dialogVisible = false
      this.$emit('closeDialog')
    },
    async fetchDetails() {
      this.loading = true
      await this.fetchMerchantContact()
      this.loading = false
    },
    validateWebsiteUrl(rule, value, callback) {
      if (value.trim() === '') {
        callback()
      }
      if (!isValidUrlWithOptionalProtocol(value.trim())) {
        callback(new Error(this.$t('contact_details_website_url_format')))
      }
      callback()
    },
    async submitForm() {
      const isValid = await this.$refs.contactForm.validate()
      if (!isValid) return
      this.loading = true
      await this.submitContactDetails()
      this.closeDialog()
      this.loading = false
      this.$msgbox({
        title: this.$t('contact_dialog_updated_title'),
        message: this.$t('contact_dialog_updated_description'),
        customClass:
          'global__notification-box global__notification-box--success',
        center: true,
        showClose: true,
        showConfirmButton: false,
      })
    },
    prefixHttpsIfNeeded(url) {
      if (url.length === 0) return ''
      if (url.startsWith('https://')) return url
      return `https://${url}`
    },
    async fetchMerchantContact() {
      const { data } = await ContactDetails.fetchMerchantContactDetails(
        this.profile.merchantId
      )
      const dataForCountry = data.data.find(
        (contactObj) => contactObj.country === this.profile.country
      )
      if (dataForCountry) {
        this.contactData.email = dataForCountry.email
        this.contactData.support_centre_url_en =
          dataForCountry.supportCentreUrlEn
        this.contactData.support_centre_url_ar =
          dataForCountry.supportCentreUrlAr
        this.contactData.phone = dataForCountry?.phone?.substring(3) || ''

        // Set country code from API if available
        this.contactData.country_code =
          dataForCountry?.phone?.substring(0, 3) ||
          this.getTelephoneCodeByCountry(this.profile.country)
        this.contactData.whatsapp_country_code =
          dataForCountry.whatsapp?.substring(0, 3) ||
          this.getTelephoneCodeByCountry(this.profile.country)
        this.contactData.whatsapp = dataForCountry.whatsapp?.substring(3) || ''
      } else {
        // Fallback to default country code from profile if not available from API
        const defaultCountryCode = this.getTelephoneCodeByCountry(
          this.profile.country
        )
        if (defaultCountryCode) {
          this.contactData.country_code = defaultCountryCode
          this.contactData.whatsapp_country_code = defaultCountryCode
        }
      }
    },
    async submitContactDetails() {
      await ContactDetails.submitMerchantContactDetails(
        this.profile.merchantId,
        {
          ...this.contactData,
          support_centre_url_en: this.prefixHttpsIfNeeded(
            this.contactData.support_centre_url_en
          ),
          support_centre_url_ar: this.prefixHttpsIfNeeded(
            this.contactData.support_centre_url_ar
          ),
          country: this.profile.country,
        }
      )
    },
    checkWhatsAppPhone(rule, value, callback) {
      const selectedCountryData = this.countryData.find((countryObj) => {
        return (
          countryObj.telephoneCode === this.contactData.whatsapp_country_code
        )
      })
      if (selectedCountryData && value.length) {
        this.checkPhone(value, callback, selectedCountryData)
      } else {
        callback()
      }
    },
    checkPhoneNumber(rule, value, callback) {
      const selectedCountryData = this.countryData.find((countryObj) => {
        return countryObj.telephoneCode === this.contactData.country_code
      })
      if (selectedCountryData && value.length) {
        this.checkPhone(value, callback, selectedCountryData)
      } else {
        callback()
      }
    },
    checkPhone(value, callback, selectedCountryData) {
      const countryName = this.isArabic
        ? selectedCountryData.countryNameAr
        : selectedCountryData.countryName
      if (!value) {
        callback(new Error(this.$t('general_empty_field_message')))
      }
      const isNum = /^\d+$/.test(value)
      if (!isNum) {
        callback(new Error(this.$t('general_numbers_only_field_message')))
      }
      if (value.length < selectedCountryData.numberMinLength) {
        callback(
          new Error(
            `"${countryName}" ${this.$t('general_phone_validation_text1')} ${
              selectedCountryData.numberMinLength
            } ${this.$t('general_phone_validation_text2')}`
          )
        )
      }
      if (value.length > selectedCountryData.numberMaxLength) {
        callback(
          new Error(
            `"${countryName}" ${this.$t('general_phone_validation_text3')} ${
              selectedCountryData.numberMaxLength
            } ${this.$t('general_phone_validation_text2')}`
          )
        )
      }
      const firstNumber = Number(value.substring(0, 1))
      if (!selectedCountryData.numberStartsWith.includes(firstNumber)) {
        const message = this.isArabic
          ? `يرجي ادخال رقم خاتف خاص بـ "${countryName}" صحيح `
          : `Please enter a valid "${countryName}" phone number`
        callback(new Error(message))
      }

      callback()
    },
  },
}
</script>
<template>
  <div>
    <el-dialog
      :custom-class="'contact-dialog__form-dialog'"
      center
      show-close
      :close-on-click-modal="false"
      :title="
        `${$t('contact_dialog_hi')} ${firstName}, ${$t('contact_dialog_hi2')}`
      "
      :visible.sync="dialogVisible"
      @close="closeDialog"
    >
      <div class="contact-dialog__form-dialog-subtitle">
        {{ $t('contact_dialog_subtitle') }}
      </div>
      <div class="contact-dialog">
        <el-form
          ref="contactForm"
          :model="contactData"
          label-position="top"
          :disabled="loading"
          :rules="rules"
        >
          <el-form-item prop="email" :label="$t('contact_detail_email_label')">
            <el-input v-model.trim="contactData.email" type="email" />
          </el-form-item>
          <div class="grid grid-cols-2 gap-4">
            <el-form-item prop="phone">
              <span slot="label">
                <span>
                  {{ $t('contact_detail_phone_label') }}
                </span>
              </span>
              <div class="contact-dialog__phone-input">
                <el-select v-model.trim="contactData.country_code">
                  <el-option
                    v-for="item in countryData"
                    :key="item.countryCode"
                    :value="item.telephoneCode"
                    :label="`+${item.telephoneCode}`"
                  >
                    <span class="contact-dialog__phone-prefix">
                      <div>
                        <svg-icon
                          class="contact-dialog__phone-prefix-flag"
                          :icon-class="item.countryCode.toLowerCase()"
                        />
                      </div>
                      <div class="global__always-ltr">
                        +{{ item.telephoneCode }}
                      </div>
                    </span>
                  </el-option>
                </el-select>
                <el-input v-model.trim="contactData.phone" />
              </div>
            </el-form-item>
            <el-form-item prop="whatsapp">
              <span slot="label">
                <span>
                  {{ $t('contact_detail_whatsapp_label') }}
                </span>
              </span>
              <div class="contact-dialog__phone-input">
                <el-select v-model.trim="contactData.whatsapp_country_code">
                  <el-option
                    v-for="item in countryData"
                    :key="item.countryCode"
                    :value="item.telephoneCode"
                    :label="`+${item.telephoneCode}`"
                  >
                    <span class="contact-dialog__phone-prefix">
                      <div>
                        <svg-icon
                          class="contact-dialog__phone-prefix-flag"
                          :icon-class="item.countryCode.toLowerCase()"
                        />
                      </div>
                      <div class="global__always-ltr">
                        +{{ item.telephoneCode }}
                      </div>
                    </span>
                  </el-option>
                </el-select>
                <el-input v-model.trim="contactData.whatsapp" />
              </div>
            </el-form-item>
            <el-form-item
              prop="support_centre_url_en"
              :label="$t('contact_detail_url_en_label')"
            >
              <el-input
                v-model.trim="contactData.support_centre_url_en"
                type="url"
              />
            </el-form-item>
            <el-form-item
              prop="support_centre_url_ar"
              :label="$t('contact_detail_url_ar_label')"
            >
              <el-input
                v-model.trim="contactData.support_centre_url_ar"
                type="url"
              />
            </el-form-item>
          </div>
          <div class="flex center">
            <el-button
              :loading="loading"
              class="contact-dialog__submit global__button-black"
              @click="submitForm"
            >
              {{ $t('contact_dialog_submit') }}
            </el-button>
          </div>
          <div class="contact-dialog__cancel center" @click="closeDialog">
            {{ $t('contact_dialog_update_later') }}
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<style lang="scss">
.contact-dialog__form-dialog {
  max-width: 672px;
  padding: 64px 64px 38px;
  border-radius: 24px;

  .contact-dialog__form-dialog-subtitle {
    margin-bottom: 36px;
  }

  .el-input--medium .el-input__inner {
    height: 44px;
    padding: 12px 16px;
  }

  .el-textarea__inner {
    min-height: 240px !important;
    padding: 12px 16px;
    margin: 8px 0;
  }

  .el-dialog__close {
    padding: 10px;
    font-size: 1.1rem;
    color: #000;
    border: 1.5px solid #e5e5e5;
    border-radius: 100%;
  }

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0 !important;
    margin: 0 !important;
  }

  .el-form-item__label {
    width: 100%;
    margin-bottom: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
    color: #000;
    [dir='ltr'] & {
      font-family: 'Helvetica Neue', sans-serif;
      text-align: left;
    }

    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
      text-align: right;
    }
  }

  .el-dialog__footer {
    margin-top: 35px;
  }

  .el-dialog__title {
    display: block;
    margin-bottom: 12px;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    color: #000;
    text-align: left;
    [dir='ltr'] & {
      font-family: 'Space Grotesk', sans-serif;
      text-align: left;
    }
    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
      text-align: right;
    }
  }

  .el-tooltip {
    margin: 0;
  }
  input,
  textarea {
    border-color: #e5e5e5;
    border-radius: 8px !important;
  }

  .el-input__count {
    margin-bottom: -33px;
    background: transparent;
  }

  .el-dialog__headerbtn:focus .el-dialog__close,
  .el-dialog__headerbtn:hover .el-dialog__close {
    color: #000;
  }
}

.contact-dialog {
  &__page-description {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    margin: 30px 0;
    border-bottom: 1px solid #f2f2f2;

    svg {
      margin-right: 10px;
    }
  }
  &__subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: bold;
  }
  .el-input__inner {
    min-height: 44px !important;
    border: 1.5px solid #e5e5e5;
    border-radius: 8px;
  }

  .el-form-item__label {
    padding: 0;
  }
}

.contact-dialog form {
  margin-bottom: 0;
  border-radius: 12px;
}

.contact-dialog__phone-prefix-flag {
  width: 18px;
  height: 18px;
}

.contact-dialog__phone-prefix-arrow {
  width: 7px;
  height: 7px;
}

.contact-dialog__phone-input {
  display: flex;
  align-self: center;
  justify-content: center;

  .el-select .el-input__inner {
    min-width: 90px;
  }

  > *:first-child {
    width: 80px;

    [dir='rtl'] & {
      order: 2;
    }
  }

  > *:last-child {
    width: 80%;

    [dir='rtl'] & {
      direction: ltr;
    }
  }
}

.contact-dialog__phone-input .el-input--suffix > .el-input__inner {
  direction: ltr;

  &:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.contact-dialog__phone-input > .el-input .el-input__inner {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.contact-dialog__phone-input .el-input__suffix {
  [dir='rtl'] & {
    right: 5px;
    left: initial;
  }
}

.contact-dialog__phone-input ::v-deep .el-input--medium {
  direction: ltr;

  &:first-child .el-input__inner {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.contact-dialog__phone-prefix {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;

  > *:not(:last-child) {
    [dir='ltr'] & {
      margin-right: 8px;
    }

    [dir='rtl'] & {
      margin-left: 8px;
    }
  }
}

.contact-dialog__submit {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.contact-dialog__cancel {
  width: fit-content;
  margin: 17px auto 12px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  color: #000;
  cursor: pointer;
  background: #fff;
}
</style>
