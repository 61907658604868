import crypto from 'crypto'

import { fetchDashboardOverview, submitFarhaForm } from '@/api/dashboard'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  dashboardOverviewData: null,
  queryHash: '',
  lastError: undefined,
  fetchingDashboardOverview: false,
}

const mutations = {
  ADD_DATA(state, { data }) {
    state.dashboardOverviewData = data.data
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
}

const getters = {
  dashboardOverviewData: (state) => {
    return state.dashboardOverviewData ? state.dashboardOverviewData : null
  },
  fetchingDashboardOverview: (state) => {
    return state.fetchingDashboardOverview
  },
}

const actions = {
  async fetchDashboardOverview({ commit, dispatch, state }, params) {
    try {
      const { ...query } = params

      if (query['settlementStatus[eq]'] === 'not_settled') {
        // XXX dirty hack because the db value for settlement status is 'settled' or empty
        // But empty string, will be removed once pushed to vue-router
        // We have to patching its special value here
        query['settlementStatus[eq]'] = ''
      }

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        // commit('REMOVE_LIST')
      }

      commit('SET_FETCHING', { name: 'fetchingDashboardOverview', value: true })

      const { data } = await fetchDashboardOverview({ ...query })
      commit('SET_FETCHING', {
        name: 'fetchingDashboardOverview',
        value: false,
      })
      commit('ADD_DATA', { data })
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', {
        name: 'fetchingDashboardOverview',
        value: false,
      })
    }
  },

  async submitFarhaForm({ commit }, { data, recaptchaToken }) {
    try {
      const response = await submitFarhaForm(data, recaptchaToken)
      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
