import formatDate from 'date-fns/format'

import {
  uploadCreativeFile,
  createCampaign,
  getCampaign,
  updateCampaign,
  getAdvertiser,
  getCampaigns,
  getSegment,
} from '@/api/ads'
import { SPONSORED_STORE_ID } from '@/constants/placement-data'
import { ADTEC_USER_TYPE } from '@/constants/adtech-general'

const getDefaultState = () => {
  return {
    adsPlacementsData: [],
    budgetingData: {},
    isFetchingPlacement: false,
    lastError: undefined,
    detail: {},
    isFetchingDetail: false,
    targeting: {},
    isFetchingAdvertiser: false,
    advertiser: null,
    paymentData: null,
    userType: null,
    adsSegment: null,
    isFetchingAdsSegment: false,
    targetAudienceSegments: null,
    underMaintenance: false,
    underMaintenanceFrom: null,
    underMaintenanceTo: null,
    campaignFormMode: null,
    isAdsTopUpBannerEnabled: false,
  }
}

const mutations = {
  SET_ADS_PLACEMENTS_DATA(state, { data }) {
    state.adsPlacementsData = data
  },
  SET_BUDGETING_DATA(state, { data }) {
    state.budgetingData = data
  },
  SET_IS_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_CAMPAIGN_DETAIL(state, { data }) {
    state.detail = data
  },
  SET_TARGETING(state, { data }) {
    state.targeting = data
  },
  SET_ADVERTISER(state, { data }) {
    state.advertiser = data
  },
  SET_PAYMENT_DATA(state, data) {
    state.paymentData = data
  },
  SET_USER_TYPE(state, data) {
    state.userType = data
  },
  SET_ADS_SEGMENT(state, { data }) {
    state.adsSegment = data
  },
  SET_TARGET_AUDIENCE_SEGMENTS(state, { data }) {
    state.targetAudienceSegments = data
  },
  RESET_ALL_DATA(state) {
    Object.assign(state, getDefaultState())
  },
  SET_UNDER_MAINTENANCE(state, data) {
    state.underMaintenance = data.underMaintenance
    state.underMaintenanceTo = data.underMaintenanceTo
    state.underMaintenanceFrom = data.underMaintenanceFrom
  },
  SET_CAMPAIGN_FORM_MODE(state, { data }) {
    state.campaignFormMode = data
  },
  SET_ADS_TOP_UP_BANNER_ENABLED(state, isEnabled) {
    state.isAdsTopUpBannerEnabled = isEnabled
  },
}

const getters = {
  adsPlacementsData: (state) => state.adsPlacementsData,
  isFetchingPlacement: (state) => state.isFetchingPlacement,
  budgetingData: (state) => state.budgetingData,
  lastError: (state) => state.lastError,
  detail: (state) => state.detail,
  isFetchingDetail: (state) => state.isFetchingDetail,
  targeting: (state) => state.targeting,
  advertiser: (state) => state.advertiser ?? {},
  isFetchingAdvertiser: (state) => state.isFetchingAdvertiser,
  paymentData: (state) => state.paymentData,
  userType: (state) => state.userType,
  isFetchingAdsSegment: (state) => state.isFetchingAdsSegment,
  adsSegment: (state) => state.adsSegment,
  targetAudienceSegments: (state) => state.targetAudienceSegments,
  underMaintenance: (state) => state.underMaintenance,
  underMaintenanceFrom: (state) => state.underMaintenanceFrom,
  underMaintenanceTo: (state) => state.underMaintenanceTo,
  campaignFormMode: (state) => state.campaignFormMode,
}

const actions = {
  async createAdsPlacements({ commit }, { data }) {
    try {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingPlacement',
        value: true,
      })
      const res = await uploadCreativeFile(data)
      return res?.data?.data
    } catch (error) {
      commit('SET_ERROR', error?.response?.data)
      throw error
    } finally {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingPlacement',
        value: false,
      })
    }
  },
  setBudgetingData({ commit }, { data }) {
    commit('SET_BUDGETING_DATA', { data })
  },
  setPaymentData({ commit }, data) {
    commit('SET_PAYMENT_DATA', data)
  },
  setCampaignFormMode({ commit }, data) {
    commit('SET_CAMPAIGN_FORM_MODE', { data })
  },
  async submitCampaign(
    { commit, state },
    { campaignName, placementsData, budgetingData, storeCPC, bannerCPC }
  ) {
    const data = {
      name: campaignName,
      ...budgetingData,
      placements: placementsData.map((el) => ({
        creative_id: el.id,
        targeting: {
          languages: [el.language],
        },
        creative_metadata: {
          advertiser_internal_id: el.metadata.advertiserInternalId,
          merchant_id: el.metadata.merchantId,
          store_logo: el.metadata.storeLogo,
          store_name: el.metadata.storeName,
          language: el.metadata.language,
        },
        placement_id: el.placementId,
        price: el.placementId === SPONSORED_STORE_ID ? storeCPC : bannerCPC,
      })),
      targeting: {
        ...state.targeting,
      },
      segments: state.targetAudienceSegments,
    }

    if (budgetingData.start_date) {
      const startDate = formatDate(
        new Date(budgetingData.start_date),
        'yyyy-MM-dd'
      )
      data.start_date = `${startDate}T00:00:00Z`
    }

    if (budgetingData.end_date) {
      const endDate = formatDate(new Date(budgetingData.end_date), 'yyyy-MM-dd')
      data.end_date = `${endDate}T23:59:59Z`
    }

    const res = await createCampaign(data)
    return res
  },
  async fetchCampaignDetail({ commit }, { campaignId }) {
    try {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingDetail',
        value: true,
      })
      const res = await getCampaign(campaignId)
      if (res?.data?.data) {
        commit('SET_CAMPAIGN_DETAIL', { data: res?.data?.data })
      }
    } finally {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingDetail',
        value: false,
      })
    }
  },
  setTargeting({ commit }, { data }) {
    commit('SET_TARGETING', { data })
  },
  async updateCampaign(
    { state, dispatch },
    {
      targetAudiencesData,
      segments,
      placementsData,
      budgetingData,
      campaignId,
      campaignName,
      storeCPC,
      bannerCPC,
    }
  ) {
    const originalPlacements = state?.detail?.placements || []
    const placements = placementsData.map((el) => {
      const indexSelected = originalPlacements.findIndex(
        (item) =>
          item.placementId === el.placementId &&
          item.targeting.languages.includes(el.language)
      )

      const item = {
        creative_id: el.id,
        creative_metadata: {
          advertiser_internal_id: el.metadata.advertiserInternalId,
          merchant_id: el.metadata.merchantId,
          store_logo: el.metadata.storeLogo,
          store_name: el.metadata.storeName,
          language: el.metadata.language,
        },
        placement_id: el.placementId,
        price: el.placementId === SPONSORED_STORE_ID ? storeCPC : bannerCPC,
        title: el.title,
        click_url: el.clickUrl,
        image_url: el.imageLink,
        targeting: {
          languages: [el.language],
        },
      }
      if (indexSelected > -1) {
        item.external_id = originalPlacements[indexSelected].externalId
      }

      return item
    })

    const data = {
      id: state.detail.id,
      external_advertiser_id: state.detail.externalAdvertiserId,
      external_id: state.detail.externalId,
      review_status: state.detail.reviewStatus,
      review_message_ar: state.detail.reviewMessageAr,
      review_message_en: state.detail.reviewMessageEn,
      risk_level: state.detail.riskLevel,
      status: state.detail.status,
      ...budgetingData,
      is_active: state.detail.isActive,
      created_at: state.detail.createdAt,
      updated_at: state.detail.updatedAt,
      placements,
      targeting: targetAudiencesData,
      name: campaignName,
      segments,
    }

    if (budgetingData.start_date) {
      const startDate = formatDate(
        new Date(budgetingData.start_date),
        'yyyy-MM-dd'
      )
      data.start_date = `${startDate}T00:00:00Z`
    }

    if (budgetingData.end_date) {
      const endDate = formatDate(new Date(budgetingData.end_date), 'yyyy-MM-dd')
      data.end_date = `${endDate}T23:59:59Z`
    }

    const res = await updateCampaign({
      campaignId,
      data,
    })
    return res
  },
  async fetchAdvertiser({ commit }) {
    try {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingAdvertiser',
        value: true,
      })
      const res = await getAdvertiser()

      if (res?.data?.data) {
        commit('SET_ADVERTISER', { data: res?.data?.data })
        return res?.data?.data
      }
    } catch (_) {
      // TODO: handle it later
    } finally {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingAdvertiser',
        value: false,
      })
    }
  },
  async checkUserType({ commit, state, dispatch }) {
    if (state.underMaintenance) {
      return null
    }

    let userType = null
    try {
      // In case advertiser didn't fetch yet
      // fetch and check if advertiser created
      if (!state.advertiser) {
        const advertiser = await dispatch('fetchAdvertiser')

        if (Object.keys(advertiser).length === 0) {
          return null
        }
      }

      const res = await getCampaigns({
        page: 1,
        pageSize: 1,
      })

      userType =
        res?.data?.data.length > 0
          ? ADTEC_USER_TYPE.EXISTING
          : ADTEC_USER_TYPE.NEW

      commit('SET_USER_TYPE', userType)
    } catch {
      commit('SET_USER_TYPE', null)
    }

    return userType
  },
  async fetchSegment({ commit, state }, { currentLocale }) {
    try {
      commit('SET_ADS_SEGMENT', {
        data: null,
      })
      commit('SET_IS_FETCHING', {
        name: 'isFetchingAdsSegment',
        value: true,
      })
      const res = await getSegment()

      const titleName = currentLocale === 'en_US' ? 'nameEn' : 'nameAr'
      const descriptionName =
        currentLocale === 'en_US' ? 'descriptionEn' : 'descriptionAr'

      const ageGroups = res.data.data.ageGroups.map((el) => ({
        title: el[titleName],
        value: el?.values?.[0],
      }))

      const genders = res.data.data.genders.map((el) => ({
        title: el[titleName],
        value: el?.values?.[0],
      }))

      const countries = res.data.data.countries.map((el) => ({
        title: el[titleName],
        value: el?.values?.[0],
      }))

      const interests = res.data.data.interests.map((el) => ({
        key: el.id,
        title: el[titleName],
        titleEN: el.nameEn,
        desc: el[descriptionName],
        isChecked: false,
        value: el?.values || [],
        type: el.type,
      }))

      const customInterests = res.data.data.customInterests.map((el) => ({
        key: el.id,
        title: el[titleName],
        titleEN: el.nameEn,
        desc: el[descriptionName],
        isChecked: false,
        value: el?.values || [],
        type: el.type,
      }))

      if (res?.data?.data) {
        commit('SET_ADS_SEGMENT', {
          data: {
            ageGroups,
            genders,
            countries,
            interests: (interests || [])
              .concat(customInterests)
              .sort((prev, next) =>
                prev.titleEN
                  .toLowerCase()
                  .localeCompare(next.titleEN.toLowerCase())
              ),
          },
        })
      }
    } finally {
      commit('SET_IS_FETCHING', {
        name: 'isFetchingAdsSegment',
        value: false,
      })
    }
  },
  setTargetAudienceSegments({ commit }, { data }) {
    commit('SET_TARGET_AUDIENCE_SEGMENTS', { data })
  },
  resetAllData({ commit }) {
    commit('RESET_ALL_DATA')
  },
  setFeatureFlags({ commit }, data) {
    commit('SET_UNDER_MAINTENANCE', data)

    // Should only enable Banner in case adtech enabled and not under maintainance
    commit(
      'SET_ADS_TOP_UP_BANNER_ENABLED',
      data.isTopUpBannerEnabled &&
        data.isAdtechEnabled &&
        !data.underMaintenance
    )
  },
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations,
  actions,
  getters,
}
