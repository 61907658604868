import request from '@/utils/request'

const fetchWebhookList = () => {
  return request(
    {
      path: `/webhooks/list`,
      method: 'GET',
    },
    {
      options: {
        camelizeRes: false,
      },
    }
  )
}

const fetchWebhookDetail = (webhookId) => {
  return request(
    {
      path: `/webhooks/${webhookId}`,
      method: 'GET',
    },
    {
      options: {
        camelizeRes: false,
      },
    }
  )
}

const createWebhook = ({ url, events, headers }) => {
  return request(
    {
      path: `/webhooks`,
      method: 'POST',
      data: {
        url,
        events,
        headers,
      },
    },
    {
      options: {
        camelizeRes: false,
      },
    }
  )
}

const updateWebhook = ({ webhookId, url, events, headers }) => {
  return request(
    {
      path: `/webhooks/${webhookId}`,
      method: 'PUT',
      data: {
        url,
        events,
        headers,
      },
    },
    {
      options: {
        camelizeRes: false,
      },
    }
  )
}

const deleteWebhook = (webhookId) => {
  return request({
    path: `/webhooks/${webhookId}`,
    method: 'DELETE',
  })
}

export {
  fetchWebhookList,
  fetchWebhookDetail,
  createWebhook,
  updateWebhook,
  deleteWebhook,
}
