import requestCaller from '@/utils/request'

class BaseApi {
  constructor(uri, caller = requestCaller) {
    this.uri = uri
    this.request = caller
  }
}

export default BaseApi
