import BaseApi from '@/api/base-api'

class MerchantUserApi extends BaseApi {
  getMerchantUserProfile = () => {
    return this.request(
      {
        method: 'GET',
        path: this.uri + '/me',
      },
      {
        options: {
          hideMessage: true,
        },
      }
    )
  }
}

export default new MerchantUserApi('/merchants/users')
