import Config from '@/config'
import { loadRemoteScript } from '@/utils/loader'

const UNSUPPORTED_ADA_BOT_PATHS = [
  '/ads/ads-credit',
  '/ads/ads-credit/top-up',
  '/ads/top-up-success',
  '/ads/top-up-pending',
  '/ads/top-up-failed',
]

const ADA_EMBED_URL = 'https://static.ada.support/embed2.js'

const SUPPORTED_ADA_BOT_LANG = ['en', 'ar']

const settings = {
  handle: 'tamara',
  testMode: Config.isDev,
}

/**
 * @param targetLanguage
 * @returns {boolean}
 */
const changeAdaBotLanguage = (targetLanguage) => {
  if (!SUPPORTED_ADA_BOT_LANG.includes(targetLanguage)) {
    console.warn(
      `Ada: Set 'language' was failed. We don't support [${targetLanguage}] for ada.`
    )

    return false
  }

  if (window.adaSettings) {
    window.adaSettings.language = targetLanguage
  }

  if (window.adaEmbed) {
    window.adaEmbed.setLanguage(targetLanguage)

    return true
  }

  console.warn('Ada is not available! Please try again later.')

  return false
}

const hideAdaBot = async () => {
  if (!window.adaEmbed) {
    console.warn('Ada is not available!')

    return
  }

  // We call stop directly. If Ada is not available, it will throw an error. Ignore it and we don't need to anything!
  try {
    await window.adaEmbed.stop()
  } catch (error) {
    console.warn(error.message)
  }
}

const displayAdaBot = async () => {
  try {
    // Check Ada is available or not. If Ada is not available. It will throw an error.
    // If we can get the information. We ignore it because it is already started.
    await window.adaEmbed.getInfo()

    window.adaEmbed.reset(window.adaSettings)
  } catch (error) {
    if (!window.adaEmbed) {
      return
    }

    // Trigger start ada bot if we cannot get information.
    await window.adaEmbed.start(window.adaSettings || settings)
  }
}

const setAdaMetaFields = async (newFields) => {
  if (!window.adaSettings) {
    window.adaSettings = {}
  }

  const currentMetaFields = window.adaSettings.metaFields || {}
  const targetMetaFields = newFields || {}

  window.adaSettings.metaFields = {
    ...currentMetaFields,
    ...targetMetaFields,
  }

  if (window.adaEmbed) {
    window.adaEmbed.reset(window.adaSettings)
  }
}

const toggleAdaBotIfNeeded = (routeTo) => {
  const path = routeTo.path
  const shouldHideAdaBot = UNSUPPORTED_ADA_BOT_PATHS.includes(path)

  if (shouldHideAdaBot) {
    return hideAdaBot()
  }

  return displayAdaBot()
}

const startAdaBot = async (settings) => {
  if (!window.adaEmbed) {
    // It will start after loaded
    await loadRemoteScript(ADA_EMBED_URL, {
      cancelOnTimeout: true,
      attributes: {
        id: '__ada',
        'data-lazy': true,
        'data-handle': 'tamara',
      },
    })

    return
  }

  try {
    await window.adaEmbed.start(settings)
  } catch (e) {
    console.warn('Ada Bot could not be started', e)
  }
}

/**
 *
 * @param language
 * @param metaFields
 * @param sensitiveMetaFields
 */
const initAdaBot = ({ language, metaFields, sensitiveMetaFields }) => {
  const initSettings = {
    ...settings,
    language,
    metaFields,
    sensitiveMetaFields,
    chatterTokenCallback: () => {
      const metaFields = window.adaSettings.metaFields || {}

      window.adaEmbed.setMetaFields(metaFields)
    },
  }

  window.adaSettings = initSettings

  startAdaBot(initSettings)
}

export {
  initAdaBot,
  changeAdaBotLanguage,
  toggleAdaBotIfNeeded,
  setAdaMetaFields,
}
