import router from '@/router'
import { queryParser } from '@/utils/query-helpers'

const FIRST_PAGINATION_VALUE = {
  key: 'page',
  value: 1,
}

const state = {
  queries: [],
}

const getters = {
  findQuery: (state) => (keys) =>
    state.queries.find(
      ({ key, identifier }) =>
        keys.includes(key) || keys.includes(`${key}[${identifier}]`)
    ),
  buildQuery: (state) => (filterKeys) => {
    let queries = state.queries

    if (filterKeys) {
      queries = queries.filter((query) => filterKeys.includes(query.key))
    }

    const filteredQueries = queries.reduce((result, query) => {
      const { key, value, identifier } = query
      const keyIdentifier = identifier ? `[${identifier}]` : ''
      const fullKey = `${key}${keyIdentifier}`

      return { ...result, [fullKey]: value }
    }, {})

    return Object.entries(filteredQueries)
      .filter(([_, value]) => !!value)
      .reduce((result, [key, value]) => ({ ...result, [key]: value }), {})
  },
}

const mutations = {
  SET_ROUTE_CHANGED(state, route) {
    const { query = {} } = route.to || {}

    state.queries = queryParser(query)
  },
  SET_QUERIES(state, queries) {
    state.queries = queries
  },
}

const actions = {
  changeRoute({ commit }, route) {
    commit('SET_ROUTE_CHANGED', route)
  },
  updateQueries(
    { commit, state, getters },
    { identifyKeys, value, values, meta }
  ) {
    const { resetPagination = false, updateRoute = true } = meta || {}

    if (resetPagination) {
      identifyKeys.push('page')
    }

    let filteredQueries = state.queries.filter(
      ({ key }) => !identifyKeys.includes(key)
    )

    values = values || [value]
    filteredQueries = [...filteredQueries, ...values]

    if (resetPagination) {
      filteredQueries.push(FIRST_PAGINATION_VALUE)
    }

    commit('SET_QUERIES', filteredQueries)

    if (updateRoute) {
      router.push({ query: getters.buildQuery() })
    }
  },
  paginateTo({ dispatch, getters }, { page }) {
    const nextPagination = {
      key: 'page',
      value: page,
    }

    dispatch('updateQueries', {
      identifyKeys: ['page'],
      value: nextPagination,
      meta: { resetPagination: false },
    })
  },
  resetPaginationOnFilterChange({ state }, { to, from }) {
    const toQuery = to.query
    const fromQuery = from.query

    // Check if any query other than 'page' has changed
    const filtersHasUpdated = Object.keys(toQuery).some((key) => {
      return key !== 'page' && toQuery[key] !== fromQuery[key]
    })

    // If any other query has changed and page is not already 1, reset page to 1
    if (filtersHasUpdated && toQuery.page !== '1') {
      // Create a new query object with the updated pagination
      const newQuery = { ...toQuery, page: '1' }

      // Use Vue Router to update the route with the new query
      router.push({ name: to.name, query: newQuery })
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }
