import Vue from 'vue'
import Router from 'vue-router'
import * as Sentry from '@sentry/vue'
import adsRouteGuard from '@/views/ads/permission'
import { toggleAdaBotIfNeeded } from '@/plugins/ada'

/* Layout */

/* Router Modules */
import Layout from '@/layout/index.vue'
import AdsTopUpResponse from '@/views/ads/ads-top-up-response/index'
import {
  OPEN_STATUS,
  UNDER_REVIEW_STATUS,
} from '@/views/dispute/constants/status-options'

Vue.use(Router)

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
 permissions: ['view_order_list','edit_order']    control the page roles (you can set multiple permissions)
 title: 'title'               the name show in sidebar and breadcrumb (recommend set)
 icon: 'svg-name'             the icon show in the sidebar
 noCache: true                if set true, the page will no be cached(default is false)
 affix: true                  if set true, the tag will affix in the tags-view
 breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
 activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
 }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all permissions can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index'),
      },
    ],
  },
  {
    path: '/user-email-verification',
    component: () => import('@/views/user-email-verification/index'),
    hidden: true,
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },
  {
    path: '/onboarding',
    component: () => import('@/views/onboarding/index'),
    hidden: true,
  },
  {
    path: '/forgot-password',
    component: () => import('@/views/forgot-password/index'),
    hidden: true,
  },
  {
    path: '/reset-password',
    component: () => import('@/views/reset-password/index'),
    hidden: true,
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    hidden: true,
    redirect: '/dashboard/home',
  },
  // // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true },
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user permissions
 */
export const asyncRoutes = [
  {
    path: '/dashboard',
    component: Layout,
    name: 'dashboard',
    meta: {
      title: 'Dashboard',
      label: 'Dashboard',
      icon: 'dashboard',
      iconActive: 'dashboard-active',
      breadcrumb: true,
      permissions: ['view_dashboard_overview'],
    },
    children: [
      {
        path: '/dashboard/home',
        component: () => import('@/views/dashboard'),
        name: 'dashboard-home',
        meta: {
          title: 'tab_dashboard_list_home',
          label: 'tab_dashboard_list_home',
          icon: 'dashboard',
          affix: true,
          breadcrumb: true,
          permissions: ['view_dashboard_overview'],
        },
      },
    ],
  },
  {
    path: '/orders',
    component: Layout,
    name: 'order',
    redirect: '/order/list',
    meta: {
      title: 'header_orders',
      label: 'header_orders',
      icon: 'orders',
      iconActive: 'orders-active',
      breadcrumb: true,
      permissions: ['order_view_list_v2'],
    },
    children: [
      {
        path: '/order/list',
        component: () => import('@/views/order/list'),
        name: 'order-list',
        meta: {
          title: 'tab_order_list',
          label: 'tab_order_list',
          affix: true,
          breadcrumb: true,
          permissions: ['order_view_list_v2'],
        },
      },
      {
        path: '/order/list?status[eq]=authorised&statusPage=toCapture',
        component: () => import('@/views/order/list'),
        name: 'order-list-to-capture',
        meta: {
          title: 'tab_order_list_to_capture',
          label: 'tab_order_list_to_capture',
          affix: true,
          breadcrumb: true,
          permissions: ['order_view_list_v2'],
        },
      },
      {
        path: '/order/list?status[eq]=approved&statusPage=toAuthorise',
        component: () => import('@/views/order/list'),
        name: 'order-list-to-authorize',
        meta: {
          title: 'tab_order_list_to_authorize',
          label: 'tab_order_list_to_authorize',
          affix: true,
          breadcrumb: true,
          permissions: ['order_view_list_v2'],
        },
      },
      {
        path: '/order/detail/:orderId',
        component: () => import('@/views/order/detail'),
        name: 'order-detail',
        meta: {
          title: 'header_order_detail',
          label: 'header_order_detail',
          affix: false,
          breadcrumb: true,
          permissions: ['order_view_list_v2', 'order_view_detail'],
        },
        hidden: true,
      },
      {
        path: '/orders/new',
        component: () => import('@/views/order/create'),
        meta: {
          title: 'header_order_create',
          label: 'header_order_create',
          affix: false,
          breadcrumb: true,
          permissions: ['order_create'],
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/dispute',
    component: Layout,
    name: 'dispute',
    redirect: '/dispute/list',
    meta: {
      title: 'header_disputes',
      label: 'header_disputes',
      icon: 'disputes',
      iconActive: 'disputes-active',
      breadcrumb: true,
      permissions: ['get_dispute_listing'],
    },
    children: [
      {
        path: '/dispute/list',
        component: () => import('@/views/dispute/list'),
        name: 'dispute-list',
        meta: {
          title: 'tab_dispute_list',
          label: 'tab_dispute_list',
          affix: true,
          breadcrumb: true,
          permissions: ['get_dispute_listing'],
        },
      },
      {
        path: `/dispute/list?statuses=${encodeURIComponent(
          OPEN_STATUS
        )}&statusPage=action-required`,
        component: () => import('@/views/dispute/list'),
        name: 'dispute-list-action-required',
        meta: {
          title: 'tab_dispute_list_action_required',
          label: 'tab_dispute_list_action_required',
          affix: true,
          breadcrumb: true,
          permissions: ['get_dispute_listing'],
        },
      },
      {
        path: `/dispute/list`,
        component: () => import('@/views/dispute/list'),
        name: 'dispute-list-action-required-top-bar',
        meta: {
          title: 'tab_dispute_list_action_required',
          label: 'tab_dispute_list_action_required',
          affix: true,
          breadcrumb: true,
          permissions: ['get_dispute_listing'],
        },
        hidden: true,
      },
      {
        path: `/dispute/list?statuses=${UNDER_REVIEW_STATUS}&statusPage=opened`,
        component: () => import('@/views/dispute/list'),
        name: 'dispute-list-opened-cases',
        meta: {
          title: 'tab_dispute_list_opened-cases',
          label: 'tab_dispute_list_opened-cases',
          affix: true,
          breadcrumb: true,
          permissions: ['get_dispute_listing'],
        },
      },
      {
        path: '/dispute/detail/:disputeId',
        component: () => import('@/views/dispute/detail'),
        name: 'dispute-detail',
        meta: {
          title: 'header_dispute_detail',
          label: 'header_dispute_detail',
          affix: false,
          breadcrumb: true,
          permissions: ['get_dispute_listing', 'get_dispute_details'],
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/transaction',
    component: Layout,
    name: 'transactional',
    redirect: '/transaction/list',
    meta: {
      title: 'header_transactional',
      label: 'header_transactional',
      icon: 'transactions',
      iconActive: 'transactions-active',
      breadcrumb: true,
      permissions: ['view_transaction'],
    },
    children: [
      {
        path: '/transaction/list',
        component: () => import('@/views/transactional/list'),
        name: 'transactional-list',
        meta: {
          title: 'header_transactional',
          label: 'header_transactional',
          affix: true,
          breadcrumb: true,
          permissions: ['view_transaction'],
        },
      },
    ],
  },
  {
    path: '/ads',
    component: Layout,
    name: 'ads',
    redirect: '/ads/dashboard',
    beforeEnter: adsRouteGuard,
    meta: {
      title: 'ads_header',
      label: 'ads_header',
      icon: 'tamara-ads',
      iconActive: 'tamara-ads-active',
      breadcrumb: true,
      //! Temporary for testing, remove later
      permissions: ['access_adtech'],
    },
    children: [
      {
        path: '/ads/dashboard',
        component: () => import('@/views/ads/dashboard'),
        name: 'ads-dashboard',
        meta: {
          title: 'ads_header',
          label: 'ads_header',
        },
      },
      {
        path: '/ads/create',
        component: () => import('@/views/ads/create'),
        name: 'ads-create',
        meta: {
          title: 'ads_create_new_campaign',
          label: 'ads_header',
        },
        hidden: true,
      },
      {
        path: '/ads/ads-credit/top-up',
        component: () => import('@/views/ads/ads-top-up'),
        name: 'ads-top-up',
        meta: {
          title: 'ads_top_up_nav_label',
          label: 'ads_top_up_nav_label',
        },
        hidden: true,
      },
      {
        path: '/ads/ads-credit',
        component: () => import('@/views/ads/ads-credit'),
        name: 'ads-credit',
        meta: {
          title: 'ads_credit_nav_label',
          label: 'ads_credit_nav_label',
        },
        hidden: true,
      },
      {
        path: '/ads/detail/:campaignId',
        component: () => import('@/views/ads/detail'),
        name: 'ads-detail',
        meta: {
          title: 'ads_header',
          label: 'ads_header',
          affix: true,
          breadcrumb: true,
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/ads/top-up-success',
    component: AdsTopUpResponse,
    name: 'ads-top-up-success',
    beforeEnter: adsRouteGuard,
    meta: {
      title: 'ads_top_up_success_title',
      responseType: 'success',
      //! Temporary for testing, remove later
      permissions: ['access_adtech'],
    },
    hidden: true,
  },
  {
    path: '/ads/top-up-failed',
    component: AdsTopUpResponse,
    name: 'ads-top-up-failed',
    beforeEnter: adsRouteGuard,
    meta: {
      title: 'ads_top_up_failed_title',
      responseType: 'error',
      //! Temporary for testing, remove later
      permissions: ['access_adtech'],
    },
    hidden: true,
  },
  {
    path: '/ads/pay-now',
    component: AdsTopUpResponse,
    name: 'ads_top_up_pay_now',
    beforeEnter: adsRouteGuard,
    meta: {
      title: 'ads_top_up_pay_now',
      responseType: 'pay-now',
      //! Temporary for testing, remove later
      permissions: ['access_adtech'],
    },
    hidden: true,
  },
  {
    path: '/settlement',
    component: Layout,
    name: 'settlements',
    redirect: '/settlement/information',
    meta: {
      title: 'tab_settlement',
      label: 'tab_settlement',
      icon: 'settlements',
      iconActive: 'settlements-active',
      breadcrumb: true,
      permissions: ['settlement_view', 'invoice_view'],
    },
    children: [
      {
        path: '/settlement/information',
        component: () => import('@/views/settlement/index'),
        name: 'settlement',
        meta: {
          title: 'tab_settlement_info',
          label: 'tab_settlement_info',
          icon: '',
          affix: true,
          permissions: ['settlement_view'],
        },
      },
      {
        path: '/invoices/list',
        component: () => import('@/views/invoice/list'),
        name: 'invoice-list',
        meta: {
          title: 'tab_invoice_list',
          label: 'tab_invoice_list',
          icon: '',
          affix: true,
          breadcrumb: true,
          permissions: ['invoice_view'],
        },
      },
    ],
  },
  {
    path: '/address-collection',
    component: Layout,
    name: 'address-collection',
    redirect: '/address-collection/view',
    meta: {
      title: 'address-collection',
      label: 'address-collection',
      icon: 'contact',
      iconActive: 'contact-active',
      breadcrumb: true,
      permissions: ['get_merchant_address_list'],
    },
    children: [
      {
        path: '/address-collection/view',
        component: () => import('@/views/users/address-collection'),
        name: 'address-collection-view',
        meta: {
          title: 'address-collection',
          label: 'address-collection',
          affix: true,
          breadcrumb: true,
          permissions: ['get_merchant_address_list'],
        },
      },
    ],
  },
  {
    path: '/exports',
    component: Layout,
    name: 'exports',
    redirect: '/exports/list',
    meta: {
      title: 'header_exports',
      label: 'header_exports',
      icon: 'exports',
      iconActive: 'exports-active',
      breadcrumb: true,
      isNew: true,
      permissions: ['get_exported_reports'],
    },
    children: [
      {
        path: '/exports/list',
        component: () => import('@/views/exports/list'),
        name: 'exports-list',
        meta: {
          title: 'header_exports',
          label: 'header_exports',
          affix: true,
          breadcrumb: true,
          permissions: ['get_exported_reports'],
        },
      },
    ],
  },
  {
    path: '/contact-details',
    component: Layout,
    name: 'contact-details',
    redirect: '/contact-details/view',
    meta: {
      title: 'contact-details',
      label: 'contact-details',
      icon: 'contact',
      iconActive: 'contact-active',
      breadcrumb: true,
      permissions: ['get_merchant_contacts'],
    },
    children: [
      {
        path: '/contact-details/view',
        component: () => import('@/views/contact-details/index'),
        name: 'contact-details-view',
        meta: {
          title: 'contact-details',
          label: 'contact-details',
          affix: true,
          breadcrumb: true,
          permissions: ['get_merchant_contacts'],
        },
      },
    ],
  },

  {
    path: '/webhooks',
    component: Layout,
    name: 'webhooks',
    redirect: '/webhooks/list',
    meta: {
      title: 'Webhooks',
      label: 'Webhooks',
      icon: 'globe',
      iconActive: 'globe-active',
      breadcrumb: true,
      permissions: ['view_webhook'],
    },
    children: [
      {
        path: '/webhooks/list',
        component: () => import('@/views/webhooks/list'),
        name: 'webhooks-list',
        meta: {
          title: 'tab_webhooks_list',
          label: 'tab_webhooks_list',
          icon: 'webhooks',
          affix: true,
          breadcrumb: true,
          permissions: ['view_webhook'],
        },
      },
      {
        path: '/webhooks/:webhookId',
        component: () => import('@/views/webhooks/detail'),
        meta: {
          title: 'header_webhooks_detail',
          label: 'header_webhooks_detail',
          affix: false,
          breadcrumb: true,
          permissions: ['view_webhook', 'create_webhook', 'edit_webhook'],
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/settings',
    component: Layout,
    name: 'settings',
    redirect: '/settings/store/list',
    alwaysShow: true,
    meta: {
      title: 'header_settings',
      label: 'header_settings',
      icon: 'settings',
      iconActive: 'settings-active',
      breadcrumb: true,
      permissions: [
        'merchant_get_barcode_setting',
        'merchant_index_store',
        'merchant_view_store',
        'merchant_create_store',
        'merchant_update_store',
        'merchant_activate_store',
      ],
    },
    children: [
      {
        path: '/settings/barcode',
        component: () => import('@/views/settings/barcode/index.vue'),
        name: 'settings-barcode',
        meta: {
          title: 'setting_barcode_title',
          label: 'setting_barcode_title',
          icon: '',
          breadcrumb: true,
          affix: true,
          permissions: ['merchant_get_barcode_setting'],
        },
      },
      {
        path: '/settings/store/list',
        component: () => import('@/views/settings/store/list'),
        name: 'store-list',
        meta: {
          title: 'tab_store_list',
          label: 'tab_store_list',
          affix: true,
          breadcrumb: true,
          permissions: [
            'merchant_index_store',
            'merchant_view_store',
            'merchant_create_store',
            'merchant_update_store',
            'merchant_activate_store',
          ],
        },
      },
      {
        path: '/settings/store/detail/:storeId',
        component: () => import('@/views/settings/store/detail'),
        meta: {
          title: 'header_store_detail',
          label: 'header_store_detail',
          affix: false,
          breadcrumb: true,
          permissions: [
            'merchant_view_store',
            'merchant_update_store',
            'merchant_activate_store',
          ],
        },
        hidden: true,
      },
      {
        path: '/settings/store/new',
        component: () => import('@/views/settings/store/create'),
        meta: {
          title: 'header_store_create',
          label: 'header_store_create',
          affix: false,
          breadcrumb: true,
          permissions: ['merchant_create_store'],
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/api',
    component: Layout,
    name: 'api',
    redirect: '/api/list',
    meta: {
      title: 'header_api',
      label: 'header_api',
      icon: 'api',
      iconActive: 'api-active',
      breadcrumb: true,
      permissions: ['generate_api_key'],
    },
    children: [
      {
        path: '/api/list',
        component: () => import('@/views/api/index'),
        name: 'api-list',
        meta: {
          title: 'header_api',
          label: 'header_api',
          affix: true,
          breadcrumb: true,
          permissions: ['generate_api_key'],
        },
      },
    ],
  },
  {
    path: '/users',
    component: Layout,
    name: 'users',
    redirect: '/users/list',
    meta: {
      title: 'header_users',
      label: 'header_users',
      icon: 'users',
      iconActive: 'users-active',
      breadcrumb: true,
    },
    children: [
      {
        path: '/users/list',
        component: () => import('@/views/users/list'),
        name: 'users-list',
        meta: {
          title: 'header_user_settings',
          label: 'header_user_settings',
          icon: '',
          affix: true,
          breadcrumb: true,
          permissions: ['view_user'],
        },
      },
      {
        path: '/users/detail/:userId',
        component: () => import('@/views/users/detail'),
        meta: {
          title: 'header_users_detail',
          label: 'header_users_detail',
          affix: false,
          breadcrumb: true,
          permissions: ['view_user', 'add_user', 'edit_user'],
        },
        hidden: true,
      },
      {
        path: '/roles/list',
        component: () => import('@/views/roles/list'),
        name: 'roles-list',
        meta: {
          title: 'header_roles_settings',
          label: 'header_roles_settings',
          icon: '',
          affix: true,
          breadcrumb: true,
        },
      },
      {
        path: '/roles/:roleId',
        component: () => import('@/views/roles/detail'),
        name: 'roles-create',
        meta: {
          title: 'header_roles_details',
          label: 'header_roles_details',
          affix: true,
          breadcrumb: true,
          permissions: ['view_roles', 'add_role', 'edit_role'],
        },
        hidden: true,
      },
    ],
  },
  {
    path: '/profile',
    component: Layout,
    name: 'profile',
    redirect: '/profile',
    meta: {
      title: 'header_profile',
      label: 'header_profile',
      icon: 'profile',
      iconActive: 'profile-active',
      breadcrumb: false,
    },
    hidden: true,
    children: [
      {
        path: '/profile',
        component: () => import('@/views/profile'),
        name: 'profile',
        meta: {
          title: 'header_profile',
          label: 'header_profile',
          icon: '',
          affix: true,
          breadcrumb: true,
        },
      },
    ],
  },
  {
    path: '/kyc',
    component: Layout,
    name: 'kyc',
    redirect: '/kyc-legal',
    meta: {
      title: 'tab_kyc-onboarding',
      label: 'tab_kyc-onboarding',
      icon: 'onboarding-hand',
      iconActive: 'onboarding-hand',
      breadcrumb: false,
      isKyc: true,
      permissions: ['get_kyc', 'store_kyc'],
    },
    children: [
      {
        path: '/kyc-business',
        component: () => import('@/views/kyc/kyc-business.vue'),
        name: 'kyc-business',
        meta: {
          title: 'tab_kyc-business',
          label: 'tab_kyc-business',
          affix: true,
          breadcrumb: true,
          isKyc: true,
          permissions: ['get_kyc', 'store_kyc'],
        },
      },
      {
        path: '/kyc-legal',
        component: () => import('@/views/kyc/kyc-legal.vue'),
        name: 'kyc-legal',
        meta: {
          title: 'tab_kyc-legal',
          label: 'tab_kyc-legal',
          affix: true,
          breadcrumb: true,
          isKyc: true,
          permissions: ['get_kyc', 'store_kyc'],
        },
      },
      {
        path: '/kyc-bank',
        component: () => import('@/views/kyc/kyc-bank.vue'),
        name: 'kyc-bank',
        meta: {
          title: 'tab_kyc-bank',
          label: 'tab_kyc-bank',
          affix: true,
          breadcrumb: true,
          isKyc: true,
          permissions: ['get_kyc', 'store_kyc'],
        },
      },
      {
        path: '/kyc-additional-info',
        component: () => import('@/views/kyc/additional-info.vue'),
        name: 'kyc-additional-info',
        meta: {
          title: 'tab-kyc-additional-info',
          label: 'tab-kyc-additional-info',
          affix: true,
          breadcrumb: true,
          isKyc: true,
          permissions: ['get_kyc', 'store_kyc'],
        },
      },
      {
        path: '/kyc-contact',
        component: () => import('@/views/contact-details/index'),
        name: 'kyc-contact',
        meta: {
          title: 'tab_kyc-contact',
          label: 'tab_kyc-contact',
          affix: true,
          breadcrumb: true,
          isKyc: true,
          permissions: ['get_merchant_onboarding_contacts'],
        },
      },
    ],
  },
]

const createRouter = () =>
  new Router({
    routes: constantRoutes,
    scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
    },
  })

const router = createRouter()

if (
  process.env.VUE_APP_ENV === 'production' ||
  process.env.VUE_APP_ENV === 'pm'
) {
  const ERROR_LIMIT = 10
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENV,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/partners-dev6\.tamara\.co\//,
          /^https:\/\/partners\.tamara\.co\//,
        ],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    tracesSampleRate: 0.1,
    ignoreErrors: [
      'Non-Error promise rejection captured with value: false',
      'Non-Error promise rejection captured with value: cancel',
      'Non-Error promise rejection captured with value: close',
      'Non-Error promise rejection captured with value: Timeout',
      'Non-Error promise rejection captured with value: XhrError',
      'ResizeObserver loop limit exceeded',
      'Request failed with status code 401',
      'Loading CSS chunk',
      'Invalid Date',
      'RangeError',
    ], // Ignore specific error messages
    beforeSend(event, hint) {
      // client-side rate limiting
      let errorCount = parseInt(localStorage.getItem('errorCount') || '0')
      if (errorCount > ERROR_LIMIT) {
        return null
      }
      errorCount += 1
      localStorage.setItem('errorCount', errorCount.toString())

      // Check for non-critical errors
      if (event.exception) {
        let shouldIgnore = false
        event.exception.values.forEach((exception) => {
          if (exception.type === 'NonCriticalError') {
            shouldIgnore = true
          }
        })
        if (shouldIgnore) return null
      }

      // Check for large render blocking asset error
      const error = hint.originalException
      if (
        error &&
        error.message &&
        /Large Render Blocking Asset/.test(error.message)
      ) {
        return null
      }

      return event
    },
  })
}

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

// Listener after resolved
router.afterEach((to) => {
  toggleAdaBotIfNeeded(to)
})

export default router
