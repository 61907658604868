<script>
import clipboard from '@/directive/clipboard/clipboard.js'

export default {
  directives: {
    clipboard,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      required: true,
    },
    shortend: {
      type: Boolean,
      default: false,
    },
    cap: {
      type: String,
      default: '12',
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideCopiedTextFromSuccessMsg: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    valuePreview() {
      return this.shortend
        ? '...' + this.value.substring(this.value.length - this.cap)
        : this.value
    },
  },
  methods: {
    onCopySuccess(e) {
      const copiedText = this.hideCopiedTextFromSuccessMsg ? '' : e.text
      this.$message({
        type: 'success',
        message: `${this.$t('general_copied_text1')} ${copiedText} ${this.$t(
          'general_copied_text2'
        )}`,
      })
    },
    onCopyError() {
      this.$message({
        type: 'error',
        message: this.$t('general_copied_text3'),
      })
    },
  },
}
</script>

<template>
  <div class="copytext" dir="ltr" :title="value">
    <span>{{ label || valuePreview }}</span>
    <button
      v-clipboard:copy="value"
      v-clipboard:success="onCopySuccess"
      v-clipboard:error="onCopyError"
      class="inline mx-1"
      @click.stop
    >
      <svg-icon v-if="!hideIcon" icon-class="copy" />
    </button>
  </div>
</template>

<style scoped>
.copytext {
  display: flex;
}
</style>
