import requestCaller from '@/utils/request'

const baseUri = '/merchants/contacts'

const fetchMerchantContactDetails = (merchantId) => {
  return requestCaller({
    method: 'GET',
    path: `${baseUri}/${merchantId}`,
  })
}

const submitMerchantContactDetails = (merchantId, data) => {
  return requestCaller({
    method: 'POST',
    path: `${baseUri}/${merchantId}/update`,
    data,
  })
}

const ContactDetails = {
  fetchMerchantContactDetails,
  submitMerchantContactDetails,
}

export default ContactDetails
