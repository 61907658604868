import arLocale from 'element-ui/lib/locale/lang/ar'
import enLocale from 'element-ui/lib/locale/lang/en'

const getElMessages = (locale) => {
  const [lang] = (locale || '').replace('-', '_').split('_')

  return {
    ar: arLocale,
    en: enLocale,
  }[lang || 'en']
}

export { getElMessages }
