import crypto from 'crypto'

import OrdersStatusApi from '@/api/order/status'
import MerchantOrderApi from '@/api/merchant/orders'
import {
  fetchOrderList,
  fetchOrderDetail,
  createInStoreOrder,
  createInStoreOrderQr,
  cancelInStoreOrder,
  fetchOrderEventHistories,
  fetchStores,
  updateOrderReferenceId,
  fetchPaymentPlans,
  fetchMerchantBranches,
} from '@/api/order'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  list: [],
  detail: {},
  eventHistories: {},
  merchantStores: [],
  merchantBranches: [],
  paymentPlans: [],
  orderId: '',
  page: 1,
  limit: 25,
  queryHash: '',
  lastError: undefined,
  updatingStatus: false, // Trigger when merchant update status manually
  fetchingList: false,
  ordersTotalRecords: 0,
  approvedOrdersRecords: 0,
  authorisedOrdersRecords: 0,
  fetchingDetail: false,
  fetchingEventHistories: false,
  downloadingOrder: false,
  eventHistoriesOrderId: undefined,
  fetchingMerchantStores: false,
  fetchingMerchantBranches: false,
  creatingInStoreOrder: false,
}

const mutations = {
  ADD_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = data.data
  },
  REMOVE_LIST(state) {
    state.page = 1
    state.list = []
  },
  ADD_ORDER_DETAIL(state, { orderId, detail }) {
    state.detail = { ...state.detail, [orderId]: detail }
  },
  ADD_ORDER_EVENT_HISTORIES(state, { orderId, histories }) {
    state.eventHistories = { ...state.eventHistories, [orderId]: histories }
  },
  ADD_MERCHANT_STORES(state, { merchantStores }) {
    state.merchantStores = merchantStores
  },
  ADD_MERCHANT_BRANCHES(state, { merchantBranches }) {
    state.merchantBranches = merchantBranches
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.ordersTotalRecords = newValue
  },
  SET_APPROVED_ORDERS_RECORDS(state, newValue) {
    state.approvedOrdersRecords = newValue
  },
  SET_AUTHORISED_ORDERS_RECORDS(state, newValue) {
    state.authorisedOrdersRecords = newValue
  },
  SET_CURRENT_PAGE(state, newValue) {
    state.page = newValue
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
  ADD_PAYMENT_PLANS(state, { paymentPlans }) {
    state.paymentPlans = paymentPlans
  },
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getOrdersTotalRecords: (state) => {
    return state.ordersTotalRecords
  },
  getOrderDetail: (state) => (orderId) => {
    return state.detail[orderId] || {}
  },
  getHistories: (state) => (orderId) => {
    return state.eventHistories[orderId] || {}
  },
  getMerchantStores: (state) => {
    return state.merchantStores
  },
  getPaymentPlans: (state) => {
    return state.paymentPlans
  },
}

const actions = {
  async fetchOrders({ commit, dispatch, state }, params) {
    try {
      const { page, limit, ...query } = params

      if (query['settlementStatus[eq]'] === 'not_settled') {
        // XXX dirty hack because the db value for settlement status is 'settled' or empty
        // But empty string, will be removed once pushed to vue-router
        // We have to patching its special value here
        query['settlementStatus[eq]'] = ''
      }

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        commit('REMOVE_LIST')
      }

      commit('SET_FETCHING', { name: 'fetchingList', value: true })

      if (query.merchantIds) {
        query.merchantIds = Array.isArray(query.merchantIds)
          ? query.merchantIds
          : [query.merchantIds]
      }

      const { data } = await fetchOrderList({ page, limit, ...query })

      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('ADD_LIST', { data, page })
      commit('SET_CURRENT_PAGE', page)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },
  async fetchOrdersRecordsCount({ commit }) {
    try {
      const approvedOrders = await fetchOrderList({
        'status[eq]': 'approved',
        page: 1,
        limit: 1,
      })
      const approvedOrdersRecords =
        approvedOrders.data.data.length > 0 ? approvedOrders.data.total : 0
      commit('SET_APPROVED_ORDERS_RECORDS', approvedOrdersRecords)

      const authorisedOrders = await fetchOrderList({
        'status[eq]': 'authorised',
        page: 1,
        limit: 1,
      })
      const authorisedOrdersRecords =
        authorisedOrders.data.data.length > 0 ? authorisedOrders.data.total : 0
      commit('SET_AUTHORISED_ORDERS_RECORDS', authorisedOrdersRecords)
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async fetchOrderDetail({ commit }, data) {
    const orderId = data.orderId
    try {
      commit('SET_FETCHING', { name: 'fetchingDetail', value: true })

      const response = await fetchOrderDetail(data)
      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
      commit('ADD_ORDER_DETAIL', { orderId, detail: response.data })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async createInStoreOrder({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: true })

      const response = await createInStoreOrder(data)

      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: false })

      throw error
    }
  },

  async createInStoreOrderQr({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: true })

      const response = await createInStoreOrderQr(data)

      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'creatingInStoreOrder', value: false })

      throw error
    }
  },

  async cancelInStoreOrder({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'cancellingInStoreOrder', value: true })

      const response = await cancelInStoreOrder(data)

      commit('SET_FETCHING', { name: 'cancellingInStoreOrder', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'cancellingInStoreOrder', value: false })

      throw error
    }
  },

  async fetchMerchantStores({ commit }) {
    try {
      commit('SET_FETCHING', { name: 'fetchingStores', value: true })

      const response = await fetchStores()

      commit('SET_FETCHING', { name: 'fetchingStores', value: false })
      commit('ADD_MERCHANT_STORES', { merchantStores: response.data })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async fetchMerchantBranches({ commit }, merchantIds) {
    try {
      commit('SET_FETCHING', { name: 'fetchingMerchantBranches', value: true })

      if (
        !merchantIds ||
        !Array.isArray(merchantIds) ||
        merchantIds.length === 0
      )
        return
      const params = merchantIds.map((id) => `merchantIds[]=${id}`).join('&')

      const response = await fetchMerchantBranches(params)

      commit('SET_FETCHING', { name: 'fetchingMerchantBranches', value: false })
      commit('ADD_MERCHANT_BRANCHES', { merchantBranches: response.data.data })
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingMerchantBranches', value: false })
    }
  },

  async fetchOrderEventHistories({ commit }, params) {
    const orderId = params.orderId
    if (!orderId) {
      return
    }

    try {
      commit('SET_FETCHING', { name: 'fetchingEventHistories', value: true })

      const response = await fetchOrderEventHistories(params)

      commit('SET_FETCHING', { name: 'fetchingEventHistories', value: false })
      commit('ADD_ORDER_EVENT_HISTORIES', { orderId, histories: response.data })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async updateStatusOrder({ commit, dispatch }, { orderId, payload, action }) {
    try {
      commit('SET_FETCHING', { name: 'updatingStatus', value: true })

      const actionHandler = {
        authorise: OrdersStatusApi.authoriseOrder,
        capture: MerchantOrderApi.captureOrder,
        cancel: MerchantOrderApi.cancelOrder,
        refund: MerchantOrderApi.refundOrder,
        disputeCancel: MerchantOrderApi.disputeCancelOrder,
      }[action]

      if (typeof actionHandler !== 'function') {
        throw new Error('The action is not allowed!')
      }

      const response = await actionHandler(orderId, payload)

      // Trigger fetch new status
      const { merchantId } = payload
      await fetchOrderDetail({ orderId, merchantId })

      commit('SET_FETCHING', { name: 'updatingStatus', value: false })

      return response
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'updatingStatus', value: false })

      throw error
    }
  },

  async downloadOrders({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'downloadingOrder', value: true })

      if (data['settlementStatus[eq]'] === 'not_settled') {
        // hack because the db value for settlement status is 'settled' or empty
        data['settlementStatus[eq]'] = ''
      }

      const response = await MerchantOrderApi.downloadOrders(data)

      commit('SET_FETCHING', { name: 'downloadingOrder', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadingOrder', value: false })

      throw error
    }
  },

  async updateOrderReferenceId({ commit, dispatch }, data) {
    if (!data.orderId) {
      return
    }

    try {
      const response = await updateOrderReferenceId(data)
      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async fetchPaymentPlans({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'fetchingPaymentPlans', value: true })

      const response = await fetchPaymentPlans(data)

      commit('SET_FETCHING', { name: 'fetchingPaymentPlans', value: false })
      if (response.data && response.data.length === 0) {
        commit('ADD_PAYMENT_PLANS', {
          paymentPlans: [{ name: 'PAY_BY_INSTALMENTS' }],
        })
      } else {
        commit('ADD_PAYMENT_PLANS', { paymentPlans: response.data })
      }

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingPaymentPlans', value: false })

      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
