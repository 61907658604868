<script>
import { mapGetters } from 'vuex'
import AdsPaymentMethod from './components/ads-payment-method.vue'
import TopUpSuccess from './components/top-up-success.vue'
import { ADS_GA_EVENTS, emitAdsEvent } from '@/utils/analytics'
import { getPaymentStatus } from '@/api/ads'

const waitFor = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
const SUCCESS_POLLING_TIME = 60000 // ms

const STATUS_TO_TRANSLATE = {
  Declined: 'ads_top_up_failed_card_declined',
  Expired: 'ads_top_up_failed_card_expired',
}

const FAILED_STATUS = ['Failed', 'Declined', 'Expired']

export default {
  name: 'AdsTopUpResponse',
  components: { TopUpSuccess, AdsPaymentMethod },
  data() {
    return {
      isLoading: false,
      topUpAmount: 0,
      topUpStatus: null,
      currentBalance: {
        amount: 0,
      },
    }
  },
  computed: {
    ...mapGetters('ads', ['userType']),
    responseType() {
      return this.$route.meta.responseType
    },
    isTopUpFailed() {
      return FAILED_STATUS.includes(this.topUpStatus)
    },
    topUpFailedMessage() {
      if (!this.topUpStatus) {
        return ''
      }

      return this.$t('ads_top_up_failed_desc', {
        value: this.$t(STATUS_TO_TRANSLATE[this.topUpStatus]),
      })
    },
  },
  created() {
    if (this.responseType !== 'pay-now' && !this.$route.query.id) {
      this.$router.replace('/dashboard')
      return
    }

    // Remove any params that we got when redirect back from Checkout site
    const url = new URL(window.location)
    if (url.searchParams.size > 0) {
      url.search = ''
      window.history.replaceState({}, document.title, url.toString())
    }

    if (this.responseType !== 'pay-now') {
      this.getStatus()
    }
  },
  methods: {
    async getStatus() {
      try {
        this.isLoading = true
        const startTime = Date.now()

        while (true) {
          const { data } = await getPaymentStatus(this.$route.query.id)
          const { data: topUpStatus } = data

          this.topUpAmount = Number(topUpStatus.netAmount)

          // Check if the process has been checking for more than SUCCESS_POLLING_TIME
          // if so => stop and show message that payment still processing
          const timePassedSinceStart = Date.now() - startTime
          if (timePassedSinceStart > SUCCESS_POLLING_TIME) {
            this.topUpStatus = 'Pending'
            break
          }

          // If Pending => wait for 1000 ms then process to get status again
          // unless it pass the SUCCESS_POLLING_TIME
          if ((topUpStatus.status || '').toLowerCase() === 'pending') {
            await waitFor(1000)
            continue
          }

          // Should be the case Success, Expired or Declined if reach here
          this.topUpStatus = topUpStatus.status
          break
        }
      } catch {
        this.topUpStatus = 'Failed'
      } finally {
        emitAdsEvent(ADS_GA_EVENTS.TOPUP_COMPLETE, {
          amount: this.topUpAmount,
          user_type: this.userType,
          topup_status: this.topUpStatus,
        })

        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <div class="ads-top-up-response">
    <main>
      <template v-if="responseType === 'pay-now'">
        <AdsPaymentMethod />
      </template>

      <div v-if="isLoading" class="payment-processing">
        <i class="el-icon-loading" />
        <h1>{{ $t('ads_top_up_processing_message') }}</h1>
      </div>

      <template v-if="!isLoading && topUpStatus">
        <TopUpSuccess
          v-if="topUpStatus === 'Success'"
          :top-up-amount="topUpAmount"
        />

        <template v-if="isTopUpFailed">
          <div class="icon-container error-icon">
            <svg-icon icon-class="warning-circle-colorless" />
          </div>
          <h1>{{ $t('ads_top_up_failed_title') }}</h1>
          <p>
            {{ topUpFailedMessage }}
          </p>
          <div class="inline-cta-container">
            <router-link to="/ads/dashboard" class="secondary-cta-btn" replace>
              {{ $t('ads_top_up_failed_later') }}
            </router-link>
            <router-link
              to="/ads/ads-credit/top-up"
              class="primary-cta-btn"
              replace
            >
              {{ $t('ads_top_up_failed_try_again') }}
            </router-link>
          </div>
        </template>

        <template v-if="topUpStatus === 'Pending'">
          <div class="icon-container pending-icon">
            <svg-icon icon-class="clock-yellow" />
          </div>
          <h1>
            {{ $t('ads_top_up_pending_title') }}
          </h1>
          <p>
            {{ $t('ads_top_up_pending_desc') }}
          </p>
          <router-link
            to="/ads/ads-credit"
            class="primary-cta-btn mb-3"
            replace
          >
            {{ $t('ads_top_up_pending_to_balance') }}
          </router-link>
          <router-link to="/ads/dashboard" class="secondary-cta-btn" replace>
            {{ $t('ads_top_up_success_to_dashboard') }}
          </router-link>
        </template>
      </template>
    </main>
  </div>
</template>

<style lang="scss" scoped>
@mixin direction-aware-font {
  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }
  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 522px;
  padding: 48px;
  text-align: center;
  background: #fff;
  border-radius: 20px;
}

.ads-top-up-response {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  // Due to body hav zoom: 90%
  // => we have to set min-height to 111.11vh to cover entire screen height
  min-height: 111.11vh;
  background: #0009;
}

.ads-top-up-response ::v-deep {
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    margin-block-end: 20px;
    border-radius: 100%;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  h1 {
    @include direction-aware-font;

    margin-block-end: 8px;
    font-size: 24px;
    font-weight: 600;
    line-height: 31px;
  }

  p {
    margin-block-end: 20px;
    font-size: 14px;
    line-height: 22px;
    color: #666;
  }

  .primary-cta-btn {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    color: #fff;
    background: black;
    border: 1px solid rgb(0, 0, 0);
    border-radius: 10px;
  }

  .secondary-cta-btn {
    width: 100%;
    padding: 16px;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
}

.error-icon {
  background: #e835501a;

  svg {
    color: #e83550;
  }
}

.pending-icon {
  background: #efa91c33;
}

.inline-cta-container {
  display: flex;
  gap: 12px;
  width: 100%;
}

.payment-processing {
  i {
    margin-block-end: 20px;
    font-size: 64px;
    color: #ab8dff;
  }
}
</style>
