import {
  fetchOnboardingData,
  updateBank,
  addContact,
  updateLegal,
  verifyCr,
  verifyId,
  verifyIban,
  uploadFile,
  uploadLogo,
  getCountryList,
} from '@/api/kyc'

const state = {
  onboardingData: {},
  countryList: [],
  lastError: undefined,
  fetchingCountryList: false,
  fetchingOnboardingData: false,
}

const mutations = {
  ADD_ONBOARDING_DATA(state, { data }) {
    state.onboardingData = { ...data }
  },
  ADD_COUNTRIES(state, { data }) {
    state.countryList = { ...data }
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
}

const getters = {
  onboardingData: (state) => state.onboardingData,
  fetchingOnboardingData: (state) => state.fetchingOnboardingData,
  countryList: (state) => state.countryList,
  fetchingCountryList: (state) => state.fetchingCountryList,
}

const actions = {
  async fetchOnboardingData({ commit }, id) {
    try {
      commit('SET_FETCHING', { name: 'fetchingOnboardingData', value: true })
      const { data } = await fetchOnboardingData(id)
      commit('SET_FETCHING', { name: 'fetchingOnboardingData', value: false })
      commit('ADD_ONBOARDING_DATA', { data })
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingOnboardingData', value: false })
      throw error
    }
  },

  async getCountryList({ commit }) {
    try {
      commit('SET_FETCHING', { name: 'fetchingCountryList', value: true })
      const response = await getCountryList()

      commit('ADD_COUNTRIES', response.data)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingCountryList', value: false })
      throw error
    }
  },

  async updateLegal({ commit }, data) {
    try {
      return await updateLegal(data)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  async updateBank({ commit }, params) {
    try {
      return await updateBank(params)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  async addContact({ commit }, params) {
    try {
      await addContact(params)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  uploadFile: async ({ commit }, { id, formData }) => {
    try {
      return await uploadFile(id, formData)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },
  uploadLogo: async ({ commit }, { id, formData, reCaptchaToken }) => {
    try {
      return await uploadLogo(id, formData, reCaptchaToken)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  async verifyCr({ commit }, data) {
    try {
      return await verifyCr(data)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  async verifyId({ commit }, data) {
    try {
      return await verifyId(data)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },

  async verifyIban({ commit }, data) {
    try {
      const res = await verifyIban(data)
      return res
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
