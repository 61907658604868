import axios from 'axios'
import { camelizeKeys } from 'humps'
import Vue from 'vue'

import store from '@/store'
import router from '@/router'
import attachment from '@/utils/request-attachment'
import { translate } from '@/utils/i18n'
import RequestCacheInstance from '@/utils/request-cache'

const LIST_APIS_IGNORE_MERCHANT_ID = ['merchants/login', 'merchants/users/me']

const fetcher = ({ path, customBaseURL, ...params }) =>
  axios({
    url: path,
    baseURL: customBaseURL || __BASE_URL__,
    timeout: 30000,
    ...params,
  })

const validMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE']
const isValidMethod = (method) => validMethods.includes(method)

const onlyTrue = (options = {}) => ([handlerName]) => options[handlerName]

const makeHandlers = (handlers = {}, options = {}) =>
  Object.entries(handlers)
    .filter(onlyTrue(options))
    .map(([, func]) => func)

const makeFetchParams = ({ method = 'GET', headers, data }) => {
  if (!isValidMethod(method)) {
    throw new TypeError(
      `Method [${method}] is invalid. The method must be one of [${validMethods}]`
    )
  }

  const dataKey = ['POST', 'PUT', 'PATCH', 'DELETE'].includes(method)
    ? 'data'
    : 'params'

  return {
    headers,
    method,
    [dataKey]: data,
  }
}

const builderHeaders = ({ options = {} }) => {
  const customHeaders = options.headers || {}
  const handlers = makeHandlers(attachment.headers, options)
  const mainHandler = compose(...handlers)

  const { headers } = mainHandler({ headers: {} })

  return {
    ...headers,
    ...customHeaders,
  }
}

const isSuccessStatus = (status) => status >= 200 && status < 400

const parseResponse = (res, options = {}) => {
  const status = res && res.status

  if (!isSuccessStatus(status)) {
    throw new Error(`ERROR:: Request failed with status ${status}`, res)
  }

  const { data, headers } = res

  if (options.camelizeRes) {
    return camelizeKeys({ data, headers, status })
  }

  return { data, headers, status }
}

const handleResponseError = async (error, options = {}) => {
  const response = error.response || {}
  const { status, data } = response
  const message =
    (data && data.message) ||
    (data?.errors || []).map((el) => el.message).join('\n\n') ||
    'cr_server_error_heading'

  let translatedMessage = translate(message)
  // reset customer data and redirect to the login page
  if (status === 401) {
    await store.dispatch('permission/setSessionExpired', true) // set session expired flag
    await store.dispatch('user/logout')
    router.replace({
      name: 'login',
      query: {
        redirectFrom: router.fullPath,
      },
    })

    return
  }

  // Error: Network Error
  if (message && !options.hideMessage) {
    // TODO: find better solution
    if (message.indexOf('merchant.user.change_password_link') > -1) {
      const splKey = message.split('_')
      const hours = splKey[splKey.length - 1]
      translatedMessage = translate('merchant.user.change_password_link', {
        hours,
      })
    }

    if (message === 'not_allowed.') {
      translatedMessage = translate('action_is_not_allowed')
    }
    // END TODO

    Vue.prototype.$message({
      message: translatedMessage, // translate message if key existed
      type: 'error',
      duration: options.messageDuration || 3000,
    })
  }
}

const compose = (...funcs) => {
  if (funcs.length === 0) {
    return (arg) => arg
  }

  if (funcs.length === 1) {
    return funcs[0]
  }

  return funcs.reduce((a, b) => (...args) => a(b(...args)))
}

// For unit test
export { fetcher, makeFetchParams, parseResponse, builderHeaders }

// Usage - See more at @docs/request-util.md
export default async (payload = {}, meta = {}) => {
  const { path, method, data, customBaseURL } = payload
  const { options = {}, ...customParams } = meta

  // only `/login` and `/me` api refuse `x-requested-with` header
  if (LIST_APIS_IGNORE_MERCHANT_ID.every((api) => !path.includes(api))) {
    const merchantId = store.getters['userProfile/selectedMerchant']
    if (merchantId && !payload?.skipMerchantId) {
      options.headers = {
        ...(options.headers || {}),
        'X-Requested-With': merchantId,
      }
    }
  }

  try {
    const headers = builderHeaders({
      options: { attachToken: true, ...options },
    })
    const params = makeFetchParams({ method, headers, data })

    const fetcherParams = {
      path,
      customBaseURL,
      ...params,
      ...customParams,
    }

    const isEnabledCache = options?.cache ?? false
    let promise = null

    if (isEnabledCache) {
      const cacheKeyParams = {
        path,
        method,
        data,
      }
      const dataFromCache = RequestCacheInstance.getCachedData(cacheKeyParams)

      if (dataFromCache) {
        promise = dataFromCache
      } else {
        promise = fetcher(fetcherParams)

        RequestCacheInstance.setCachedData(cacheKeyParams, promise, 2_000)
      }
    } else {
      promise = fetcher(fetcherParams)
    }

    const response = await promise

    const result = parseResponse(response, {
      camelizeRes: !options.disableCamelizeRes,
      ...options,
    })

    return result
  } catch (error) {
    await handleResponseError(error, options)

    throw error
  }
}
