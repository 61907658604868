<template>
  <div class="topbar">
    <div class="logo-container">
      <router-link to="/">
        <div>
          <img
            v-if="logoImage"
            :src="currentLocale === 'en_US' ? logoImage : logoImageAr"
            class="sidebar-logo"
          />
        </div>
      </router-link>
    </div>

    <div class="right-menu">
      <template v-if="!isMobileDevice && !isProduction">
        <ErrorLog class="errLog-container right-menu-item hover-effect" />
      </template>

      <div v-if="userCanViewDisputes" class="topbar__notifications">
        <el-dropdown
          ref="notificationsDropdown"
          trigger="click"
          @visible-sync="notificationsDropdownVisible"
        >
          <div
            class="topbar__notifications-icon"
            :class="
              notificationsDropdownVisible
                ? 'topbar__notifications-icon--active'
                : ''
            "
          >
            <svg-icon
              v-if="notificationsDropdownVisible"
              icon-class="notifications-active"
            />
            <svg-icon
              v-if="!notificationsDropdownVisible"
              icon-class="notifications-blank"
            />
            <div
              v-if="!notificationsDropdownVisible"
              class="topbar__notifications-number"
            >
              {{ actionRequiredCount }}
            </div>
          </div>
          <el-dropdown-menu
            slot="dropdown"
            class="topbar__notifications-dropdown"
          >
            <div v-if="latestDisputeList && latestDisputeList.length > 0">
              <el-dropdown-item
                v-for="(item, index) in latestDisputeList"
                :key="index"
              >
                <div
                  class="topbar__notifications-item"
                  @click="goToDisputeDetail(item)"
                >
                  <div class="topbar__notifications-item-content">
                    <div class="topbar__notifications-item-title">
                      {{ $t('dispute_notification_text1') }} #{{
                        item.disputeId
                      }}
                      {{ $t('dispute_notification_text2') }}
                    </div>
                    <div
                      class="topbar__notifications-item-respond"
                      @click="goToDisputesActionRequiredPage"
                    >
                      {{ $t('dispute_respond_now') }}
                    </div>
                    <!--                  <div class="topbar__notifications-item-time">-->
                    <!--                    {{ $t('dispute_2_hours_ago') }}-->
                    <!--                  </div>-->
                  </div>
                  <!--                  <div class="topbar__notifications-item-icon">-->
                  <!--                    <svg-icon icon-class="arrow-right" />-->
                  <!--                  </div>-->
                </div>
                <div class="topbar__notifications-item-divider" />
              </el-dropdown-item>
              <el-dropdown-item class="topbar__notifications-see-all">
                <div @click="goToDisputesActionRequiredPage">
                  {{ $t('dispute_see_all') }}
                </div>
              </el-dropdown-item>
            </div>
            <el-dropdown-item v-else :disabled="true">
              <div
                class="topbar__notifications-item topbar__notifications-item--empty"
              >
                <div>
                  <svg-icon icon-class="notifications-empty" />
                </div>
                <div>
                  {{ $t('dispute_no_new_notifications_title') }}
                </div>
                <div>
                  {{ $t('dispute_no_new_notifications_description') }}
                </div>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <el-link
        type="text"
        target="_blank"
        class="navbar-help"
        :href="serviceStatusUrl"
      >
        <div>
          {{ $t('tab_service_status') }}
        </div>
      </el-link>

      <el-link
        type="text"
        target="_blank"
        class="navbar-help"
        :href="supportUrl"
      >
        <div>
          {{ $t('tab_contact_support') }}
        </div>
        <div>
          <svg-icon icon-class="info" />
        </div>
      </el-link>

      <el-link
        :class="
          `navbar-lang ${currentLocale === 'en_US' ? 'navbar-lang--ar' : ''}`
        "
        type="text"
        @click="handleSwitchLocale"
      >
        {{ $t('text_locale_switch_link') }}
      </el-link>
      <div class="divider" />
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="flex flex-row items-center">
          <svg-icon icon-class="account" class="mx-2" />
          <div class="items-center email-bar">
            <span v-if="!isMobileDevice" class="mx-2">
              {{
                `${capitalizeFirstLetter(userProfile.firstName)} ${
                  userProfile.lastName
                    ? userProfile.lastName.substring(0, 1).toUpperCase()
                    : ''
                }`
              }}
            </span>
            <i class="el-icon-caret-bottom" />
          </div>
        </div>

        <el-dropdown-menu slot="dropdown" class="topbar__actions-popup">
          <el-dropdown-item
            v-if="isMobileDevice"
            class="topbar__actions-item"
            disabled
          >
            {{ userProfile.email }}
          </el-dropdown-item>
          <el-dropdown-item class="topbar__actions-item" disabled>
            {{ $t('account_text') }}
            <br />
            {{ merchantName }}
          </el-dropdown-item>
          <el-dropdown-item
            class="topbar__actions-item"
            @click.native="viewProfile"
          >
            {{ $t('profile_dropdown') }}
          </el-dropdown-item>
          <el-dropdown-item
            class="topbar__actions-item topbar__actions-item--delete"
            @click.native="logout"
          >
            {{ $t('header_logout') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import logoImage from '@/assets/images/sidebar-logo.svg'
import logoImageAr from '@/assets/images/sidebar-logo-ar.svg'
import ErrorLog from '@/components/ErrorLog'
import { capitalizeFirstLetter } from '@/utils'
import userHasPermission from '@/utils/user-has-permission'

export default {
  components: {
    ErrorLog,
  },
  data() {
    return {
      logoImage,
      logoImageAr,
      capitalizeFirstLetter,
      notificationsDropdownVisible: false,
    }
  },
  computed: {
    ...mapGetters(['sidebar', 'avatar', 'device']),
    ...mapGetters('profile', { merchantProfile: 'profile' }),
    ...mapGetters('translations', ['currentLocale']),
    ...mapGetters('profile', ['profile']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    ...mapState('dispute', ['actionRequiredCount']),
    ...mapGetters('dispute', {
      actionRequiredDisputesList: 'getActionRequiredList',
    }),
    userCanViewDisputes() {
      return this.merchantProfile && userHasPermission(['get_dispute_listing'])
    },
    latestDisputeList() {
      // return only latest 3 for notifications dropdown
      return this.actionRequiredDisputesList
        ? this.actionRequiredDisputesList.slice(0, 3)
        : null
    },
    serviceStatusUrl() {
      return `${this.$t('service_status_url')}`
    },
    supportUrl() {
      const queries = []

      if (this.merchantProfile.merchantId) {
        queries.push(`merchant_id=${this.merchantProfile.merchantId}`)
      }

      const queryString = queries.join('&')

      return `${this.$t(
        'zendesk_helper_url_without_hash'
      )}?${queryString}#merchants`
    },
    isMobileDevice() {
      return this.device === 'mobile'
    },
    isProduction() {
      return process.env.VUE_APP_ENV === 'production'
    },
    merchantName() {
      const arMerchantName =
        this.profile.arMerchantName || this.profile.merchantName
      const merchantName =
        this.profile.merchantName || this.profile.arMerchantName

      return (
        {
          ar_SA: arMerchantName,
          en_US: merchantName,
        }[this.currentLocale] || 'N/A'
      )
    },
  },
  methods: {
    goToDisputeDetail(item) {
      this.$router.push({
        path: `/dispute/detail/${item.disputeId}?merchantId=${item.merchantId}`,
      })
    },
    goToDisputesActionRequiredPage() {
      this.$router.push({
        name: 'dispute-list-action-required-top-bar',
        query: {
          statuses: 'open',
          statusPage: 'action-required',
        },
      })
    },
    handleSwitchLocale() {
      const nextLocale = this.currentLocale === 'en_US' ? 'ar_SA' : 'en_US'
      const query = { ...this.$route.query, locale: nextLocale }
      this.$router.push(
        {
          path: this.$route.path,
          query: query,
        },
        () => {
          window.location.reload()
        }
      )
    },
    viewProfile() {
      this.$router.push('/profile')
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/_breakpoints';
.email-bar {
  font-size: 14px;
  font-weight: 400;
}

.logo-container {
  display: flex;
  align-items: center;
}

.sidebar-logo {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(104deg) brightness(103%) contrast(103%);
}

.navbar-help,
.navbar-lang {
  margin: 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.navbar-lang.navbar-lang--ar {
  font-family: 'IBM Plex Sans Arabic', sans-serif;
}

.divider {
  width: 1px;
  height: 20px;
  margin: 0 12px;
  background: #666;
}

.navbar-help ::v-deep .el-link--inner {
  display: flex;
  align-items: center;
  > div {
    margin: 0 3px;
  }
}

.topbar {
  position: sticky;
  z-index: 1000;
  display: flex;
  width: 100%;
  min-width: 1485px;
  height: 60px;
  padding-right: 36px;
  padding-left: 36px;
  overflow: hidden;
  background: #000;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

  .breadcrumb-container {
    float: left;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    line-height: 60px;
    text-align: right;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      height: 100%;
      padding: 0 8px;
      font-size: 18px;
      color: #fff;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      margin-right: 10px;

      .avatar-wrapper {
        position: relative;
        margin-top: 15px;

        .user-avatar {
          width: 40px;
          height: 40px;
          cursor: pointer;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          position: absolute;
          top: 10px;
          right: -20px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .vertical-align-text-bottom {
    vertical-align: text-bottom;
  }
}

.topbar__actions-popup {
  min-width: 230px;
  padding: 0;
  border-radius: 12px;
  [dir='rtl'] & {
    margin-right: -80px;
    margin-left: initial;
  }
}

.topbar__actions-popup .el-dropdown-menu__item {
  padding: 10px 17px;
  &:hover,
  &:focus,
  &:active {
    font-weight: normal;
    color: #000;
    background-color: #f2f2f2;
  }
}

.topbar__actions-popup .el-dropdown-menu__item > * {
  display: flex;
  align-items: center;
  width: 100%;
}

.el-dropdown-menu__item.topbar__actions-item--delete {
  color: #e02d48;
  border-bottom: none;
  &:hover,
  &:focus,
  &:active {
    font-weight: normal;
    color: #e02d48;
  }
}

.topbar__notifications {
  display: flex;
}

.topbar__notifications-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
  svg {
    width: 25px;
    height: 25px;
  }
  [dir='ltr'] & {
    margin-right: 12px;
  }
  [dir='rtl'] & {
    margin-left: 12px;
  }
}

.topbar__notifications-icon.topbar__notifications-icon--active {
  background-color: rgba(60, 60, 60, 0.7);
  border-radius: 10px;
}

.topbar__notifications-number {
  position: absolute;
  top: 7px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  font-size: 9px;
  font-weight: 500;
  color: white;
  background-color: #e02d48;
  border-radius: 100%;
  outline: 3px solid #000;
}
</style>

<style lang="scss">
.topbar__notifications-dropdown {
  min-width: 352px;
  max-width: 352px;
  border-radius: 14px;
  box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.15);
}
.topbar__notifications-dropdown .el-dropdown-menu__item {
  padding: 0;
}
.topbar__notifications-dropdown .el-dropdown-menu__item:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.topbar__notifications-item {
  padding: 17px 24px 17px;
}

.topbar__notifications-item.topbar__notifications-item--empty {
  display: flex;
  flex-direction: column;
  align-content: center;
  svg {
    width: 64px;
    height: 64px;
    margin: 0 auto;
  }
  > div {
    margin-bottom: 10px;
  }
  > div:nth-child(2) {
    margin-top: 14px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    text-align: center;
  }
  > div:nth-child(3) {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
  }
}
.topbar__notifications-item-divider {
  height: 1px;
  background-color: #f2f2f2;
}
.topbar__notifications-item-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: #000;
  cursor: pointer;
}

.topbar__notifications-item-time {
  margin-top: 14px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
}

.topbar__notifications-item-respond {
  width: fit-content;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-decoration-line: underline;
  &:hover {
    color: #666;
  }
}

.topbar__notifications-see-all {
  width: fit-content;
  margin: 14px 24px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #999;
  text-decoration-line: underline;
  cursor: pointer;
  &:hover {
    background-color: #fff !important;
  }
}
</style>
