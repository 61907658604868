import { isObject } from '@/utils/validate'

const LOADED = {}
const LOAD_TIMEOUT_MS = 150_000

const addScriptTag = (src, attributes, onLoad, onError) => {
  const script = document.createElement('script')

  script.src = src
  script.type = 'text/javascript'
  script.async = true
  script.crossorigin = 'anonymous'

  script.onload = onLoad
  script.onerror = onError

  document.head.appendChild(script)

  if (isObject(attributes)) {
    Object.entries(attributes).forEach(([key, value]) => {
      script[key] = value
    })
  }

  return () => {
    // return remove function
    document.head.removeChild(script)
  }
}

/**
 * Inject a script with given src to body, ready to use.
 * Return a cleanup function to remove if applicable
 */
const loadRemoteScript = (
  src,
  {
    onSuccess,
    onError,
    onTimeout,
    attributes = {},
    timeoutMs = LOAD_TIMEOUT_MS,
    cancelOnTimeout = false,
  } = {}
) => {
  let timeoutId = null
  let isCancelled = false

  const handleSuccess = (event) => {
    if (isCancelled) {
      return
    }

    LOADED[src] = true

    clearTimeout(timeoutId)

    if (typeof onSuccess === 'function') {
      onSuccess(event)
    }
  }

  const handleError = (event) => {
    if (isCancelled) {
      return
    }

    console.warn('loadRemoteScript error', { src })

    clearTimeout(timeoutId)

    if (typeof onError === 'function') {
      onError(event)
    }
  }

  if (LOADED[src]) {
    return handleSuccess()
  }

  const cleanupFn = addScriptTag(src, attributes, handleSuccess, handleError)

  const handleTimeout = () => {
    console.warn('loadRemoteScript timeout', { src, cancelOnTimeout })

    if (cancelOnTimeout) {
      isCancelled = true
      cleanupFn()
    }

    if (typeof onTimeout === 'function') {
      onTimeout()
    }
  }

  timeoutId = setTimeout(handleTimeout, timeoutMs)

  return cleanupFn
}

export { loadRemoteScript, addScriptTag }
