import MerchantProfileApi from '@/api/merchant/profile'
import { setAdaMetaFields } from '@/plugins/ada'

const state = {
  data: {},
  fetched: false,
  fetching: false,
  error: null,
}

const getters = {
  profile: (state) => {
    return state.data || {}
  },
}

const mutations = {
  SET_STATUS: (state, { key, value }) => {
    state[key] = value
  },
  SET_MERCHANT_DATA: (state, data) => {
    state.data = data
  },
  SET_MERCHANT_ERROR: (state, error) => {
    state.error = error
  },
}

const actions = {
  async getProfile({ commit }) {
    try {
      // checking for essential data from /profile api
      if (state.data && state.data.merchantId && state.data.merchantName) {
        return state.data
      }

      commit('SET_STATUS', { key: 'fetching', value: true })

      const { data } = await MerchantProfileApi.getProfile()

      commit('SET_STATUS', { key: 'fetched', value: true })
      commit('SET_STATUS', { key: 'fetching', value: false })
      commit('SET_MERCHANT_DATA', data)

      setAdaMetaFields({
        merchant_name: data.merchantName || '',
        merchant_id: data.merchantId || '',
        merchant_email: data.email || '',
      })
    } catch (error) {
      commit('SET_MERCHANT_ERROR', error)
    }
  },
  resetProfile({ commit }) {
    commit('SET_MERCHANT_DATA', {})
  },
}

export default { namespaced: true, state, getters, mutations, actions }
