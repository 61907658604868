import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import store from './store'
import router from './router'
import { ADS_GA_EVENTS, emitAdsEvent } from './utils/analytics'
import { getToken, setToken } from '@/utils/auth' // get token from cookie
import getPageTitle from '@/utils/get-page-title'
import {
  detectCurrentLocale,
  fetchMessagesTranslations,
  translate,
} from '@/utils/i18n'
import { isArray } from '@/utils/validate'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = [
  '/login',
  '/forgot-password',
  '/reset-password',
  '/user-email-verification',
  '/onboarding',
] // no redirect whitelist

const onboardingRoutes = ['/login', '/onboarding']

const kycRoutes = [
  '/profile',
  '/kyc',
  '/kyc-business',
  '/kyc-legal',
  '/kyc-bank',
  '/kyc-contact',
  '/kyc-additional-info',
]

function _findFirstMenuRoute(permissionRoutes) {
  return permissionRoutes.find((route) => route.meta && !route.meta.isKyc)
}

function _findPermissionRoute(permissionRoutes, targetRoute) {
  if (!isArray(permissionRoutes)) {
    return null
  }

  return permissionRoutes.find((route) => {
    if (
      route.path === targetRoute.path ||
      targetRoute.matched?.some(
        (matchedRoute) => matchedRoute.path === route.path
      )
    ) {
      return true
    }

    if (isArray(route.children)) {
      return _findPermissionRoute(route.children, targetRoute)
    }

    return false
  })
}

function _getFirstAvailableRoute(permissionRoutes) {
  const firstMenuRoute = _findFirstMenuRoute(permissionRoutes)

  if (firstMenuRoute) {
    return firstMenuRoute
  }

  const profileRoute = _findPermissionRoute(permissionRoutes, '/profile')

  if (profileRoute) {
    return profileRoute
  }

  return null
}

function _handleResolveToPermissionRoute(routeTo, next) {
  const permissionRoutes = store.getters.permissionRoutes

  const foundPermissionRoute = _findPermissionRoute(permissionRoutes, routeTo)

  // An available route
  if (foundPermissionRoute) {
    return next()
  }

  const firstAvailableRoute = _getFirstAvailableRoute(permissionRoutes)

  if (firstAvailableRoute) {
    return next({ path: firstAvailableRoute.path })
  }

  return next({ path: '/401', query: { redirect: routeTo.fullPath } })
}

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()

  const underMaintenance = store.getters['ads/underMaintenance']

  if (
    to.path !== '/ads/dashboard' &&
    /^\/ads/.test(to.path) &&
    underMaintenance
  ) {
    next('/ads/dashboard')
    return
  }

  if (to.path === '/auth/admin') {
    if (to.query.token) {
      const hasToken = getToken()
      if (hasToken) {
        await store.dispatch('user/logout')
      }
      setToken(to.query.token)
      store.dispatch('user/setToken', to.query.token)
      try {
        const data = await store.dispatch('userProfile/getMerchantUserProfile')
        const targetPath = data?.status !== 'active' ? '/' : '/dashboard/home'

        next({ path: targetPath })
        NProgress.done()
      } catch (err) {
        NProgress.done()
      }
      return
    }
  }

  // Detect locale and fetch messages if locale changed.
  const locale = detectCurrentLocale(to)

  if (
    !store.getters['translations/hasLocale'] ||
    locale !== store.getters['translations/currentLocale']
  ) {
    await fetchMessagesTranslations(locale)
  }

  // set page title
  document.title = getPageTitle(translate(to.meta.title))

  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    // Validate token
    try {
      await store.dispatch('userProfile/getMerchantUserProfile')
    } catch (error) {
      await store.dispatch('user/logout')
      // go to login page to re-login
      next(`/login?redirect=${to.path}`)
      NProgress.done()
      return
    }

    const userProfile = store.getters['userProfile/profile']
    if (userProfile && userProfile.role === 'MERCHANT_KYC_USER') {
      if (kycRoutes.includes(to.path)) {
        next()
      } else {
        next('/kyc')
      }
    }

    if (
      userProfile &&
      userProfile.onboardingId !== null &&
      userProfile.role === 'MERCHANT_ONBOARDING_USER'
    ) {
      if (onboardingRoutes.includes(to.path)) {
        next()
      } else {
        next('/onboarding')
      }
      return
    }

    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next({ path: '/' })
      NProgress.done()
    } else {
      // determine whether the user has obtained his permission roles through getInfo
      const userRoles = store.getters.roles
      const hasRoles = userRoles && userRoles.length > 0
      const permissionAdded = store.getters.permissionAdded

      if (hasRoles && permissionAdded) {
        return _handleResolveToPermissionRoute(to, next)
      }

      try {
        // Validate user. Set roles and routes: Note:: We don't need this in MVP version
        // get user info
        // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
        const { permissions, role } = await store.dispatch(
          'userProfile/getMerchantUserProfile'
        )

        if (!['MERCHANT_KYC_USER', 'MERCHANT_ONBOARDING_USER'].includes(role)) {
          await store.dispatch('profile/getProfile')
        }
        // generate accessible routes map based on roles
        const accessRoutes = await store.dispatch(
          'permission/generateRoutes',
          permissions
        )
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)
        // hack method to ensure that addRoutes is complete
        // set the "replace: true", so the navigation will not leave a history record
        next({ ...to, replace: true })
      } catch (error) {
        // fully logout and remove cached data and go to login page to re-login
        await store.dispatch('user/logout')
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } else {
    /* has no token */
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

const isAdTecPath = (path) => path.startsWith('/ads')

router.afterEach((to, from) => {
  store.dispatch('route/changeRoute', { to, from })

  if (isAdTecPath(to.path)) {
    emitAdsEvent(ADS_GA_EVENTS.PAGE_VIEW, {
      page_path: to.path,
      page_title: document.title,
    })
  }
  // finish progress bar
  NProgress.done()
})
