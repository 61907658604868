import OnboardingApi from '@/api/onboarding'

const state = {
  onboardingCategories: [],
}

const mutations = {
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_ONBOARDING_CATEGORIES(state, { onboardingCategories }) {
    state.onboardingCategories = onboardingCategories
  },
}

const getters = {
  onboardingCategories: (state) => {
    return state.onboardingCategories ? state.onboardingCategories : []
  },
}
const actions = {
  async fetchOnboardingCategories({ commit }, onboardingId) {
    try {
      commit('SET_FETCHING', {
        name: 'fetchingOnboardingCategories',
        value: true,
      })

      const response = await OnboardingApi.fetchOnboardingCategories(
        onboardingId
      )

      commit('SET_FETCHING', {
        name: 'fetchingOnboardingCategories',
        value: false,
      })
      commit('SET_ONBOARDING_CATEGORIES', {
        onboardingCategories: response.data.data,
      })
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', {
        name: 'fetchingOnboardingCategories',
        value: false,
      })
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
