const QUERY_PATTERN = /^([a-zA-Z]_){0,1}(?<key>[a-zA-Z]+)\[(?<identifier>[a-z]+)\]$/i

const queryParser = (query) => {
  // Accept none empty string only
  const filted = Object.entries({ ...query }).filter(([_, value]) => !!value)

  if (!filted) {
    return []
  }

  return filted.map(([key, value]) => {
    const matched = key && key.match(QUERY_PATTERN)

    if (!matched) {
      return { key, value }
    }

    return {
      key: matched.groups.key,
      identifier: matched.groups.identifier,
      value,
    }
  })
}

export { QUERY_PATTERN, queryParser }
