import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import subDays from 'date-fns/subDays'
import subYears from 'date-fns/subYears'
import isFuture from 'date-fns/isFuture'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { translate } from '@/utils/i18n'
import {
  SPONSORED_BANNER_ID,
  SPONSORED_STORE_ID,
} from '@/constants/placement-data'
import store from '@/store'

import { uploadCreativeFile } from '@/api/ads'

function formatURL(url) {
  return 'https://' + url.replace(/^(https:\/\/)/, '')
}

function validateURL(url) {
  try {
    const urlObj = new URL(url.startsWith('https://') ? url : `https://${url}`)
    // Allow only https protocol
    const isHttps = urlObj.protocol === 'https:'
    // Since url without top domain (TLD) is perfectly valid (like https://localhost:3000),
    // check include "." to make sure that it has top domain (TLD)
    const hasTopDomain = urlObj.origin.includes('.')
    return isHttps && hasTopDomain
  } catch {
    // If error occur => url invalid due to URL constructor unable to parse it
    return false
  }
}

export function urlValidator(_, value, callback) {
  if (value && !validateURL(value)) {
    callback(new Error('ads_url_invalid'))
  } else {
    callback()
  }
}

export const formatNumber = (value, maximumFractionDigits = 2) => {
  const number = Number(value || 0)
  // Convert the number to a string and split it into integer and decimal parts
  const [integerPart, decimalPart] = number
    .toFixed(maximumFractionDigits)
    .split('.')

  // Add commas as thousands separators to the integer part
  const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  // Combine the formatted integer part with the decimal part and return the result
  return decimalPart
    ? formattedIntegerPart + '.' + decimalPart
    : formattedIntegerPart
}

export function generatePlacementsFormData({
  destinationURL,
  language,
  placementId,
  title,
  storeName,
  storeLogo,
  imageLink,
  base64Image,
  sizeOverride = true,
}) {
  const requestBody = {}
  requestBody.placement_id = placementId
  requestBody.language = language
  requestBody.title = title
  requestBody.click_url = formatURL(destinationURL)
  requestBody.store_name = storeName
  requestBody.store_logo = storeLogo
  requestBody.image_link = imageLink
  requestBody.size_override = sizeOverride
  requestBody.image = base64Image

  return requestBody
}

async function createStorePlacement(store) {
  const base64Image = store.imageLink.split(';base64,')[1]
  const adsPlacementData = generatePlacementsFormData({
    destinationURL: store.destinationURL,
    language: store.language,
    placementId: SPONSORED_STORE_ID,
    title: store.name,
    storeName: store.name,
    storeLogo: base64Image ? '' : store.imageLink,
    imageLink: base64Image ? '' : store.imageLink,
    base64Image,
  })

  const res = await uploadCreativeFile(adsPlacementData)
  return res?.data?.data
}

async function createBannerPlacement(banner, store) {
  const base64Image = banner.imageLink.split(';base64,')[1]
  const adsPlacementData = generatePlacementsFormData({
    destinationURL: banner.destinationURL,
    language: banner.language,
    placementId: SPONSORED_BANNER_ID,
    title: banner.name,
    storeName: store?.title,
    storeLogo: store?.imageLink,
    imageLink: base64Image ? '' : banner.imageLink,
    base64Image,
  })

  const res = await uploadCreativeFile(adsPlacementData)
  return res?.data?.data
}

export async function submitAdsPlacements(
  { storeForms, bannerForms },
  originalPlacementData
) {
  const creatives = []
  const langToStoreMap = new Map()

  for (const store of Object.values(storeForms)) {
    let createdStore = {}
    const originalStore = (originalPlacementData || []).find(
      (el) =>
        el.placementId === SPONSORED_STORE_ID && store.language === el.language
    )

    // If using the same image, should only update the contents
    // Otherwise create new creative
    if (
      validateURL(store.imageLink) &&
      originalStore?.imageLink === store.imageLink
    ) {
      createdStore = { ...originalStore }
      createdStore.clickUrl = store.destinationURL
      createdStore.metadata.storeName = store.name
      createdStore.creativeMetadata.storeName = store.name
      createdStore.title = store.name
    } else {
      createdStore = await createStorePlacement(store)
    }

    langToStoreMap.set(store.language, createdStore)

    if (createdStore) {
      creatives.push(createdStore)
    }
  }

  if (!bannerForms) {
    return creatives
  }

  for (const banner of Object.values(bannerForms)) {
    let createdBanner = {}

    const storeData = langToStoreMap.get(banner.language)
    const originalBanner = (originalPlacementData || []).find(
      (el) =>
        el.placementId === SPONSORED_BANNER_ID &&
        banner.language === el.language
    )

    // If using the same image, should only update the contents
    // Otherwise create new creative
    if (
      validateURL(banner.imageLink) &&
      originalBanner?.imageLink === banner.imageLink
    ) {
      createdBanner = { ...originalBanner }
      createdBanner.clickUrl = banner.destinationURL
      createdBanner.metadata.storeName = storeData.title
      createdBanner.creativeMetadata.storeName = storeData.title
      createdBanner.title = banner.name
    } else {
      createdBanner = await createBannerPlacement(banner, storeData)
    }

    if (createdBanner) {
      creatives.push(createdBanner)
    }
  }

  return creatives
}

const nowInZone = () =>
  utcToZonedTime(new Date(), store.state.userProfile.timezone)
const startOfDayInZone = (date) =>
  startOfDay(utcToZonedTime(date, store.state.userProfile.timezone))
const endOfDayInZone = (date) =>
  endOfDay(utcToZonedTime(date, store.state.userProfile.timezone))

export function getPickerOptions(options = {}) {
  const { excludeShortcuts = [] } = options
  const shortcuts = []

  const shortcutDefinitions = {
    today: {
      text: translate('date_today'),
      onClick(picker) {
        const start = startOfDayInZone(nowInZone())
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    yesterday: {
      text: translate('date_yesterday'),
      onClick(picker) {
        const start = startOfDayInZone(subDays(nowInZone(), 1))
        const end = endOfDayInZone(subDays(nowInZone(), 1))
        picker.$emit('pick', [start, end])
      },
    },
    last_7_days: {
      text: translate('date_last_7days'),
      onClick(picker) {
        // In 7 days including current day, so we have to minus 1 from 7
        const start = startOfDayInZone(subDays(nowInZone(), 6))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    last_month: {
      text: translate('date_last_month'),
      onClick(picker) {
        const end = endOfDayInZone(nowInZone())
        // In 30 days including current day, so we have to minus 1 from 30
        const start = startOfDayInZone(subDays(end, 29))
        picker.$emit('pick', [start, end])
      },
    },
    last_60_days: {
      text: translate('ads_past_days', {
        value: 60,
      }),
      onClick(picker) {
        // In 60 days including current day, so we have to minus 1 from 60
        const start = startOfDayInZone(subDays(nowInZone(), 59))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    last_90_days: {
      text: translate('ads_past_days', {
        value: 90,
      }),
      onClick(picker) {
        // In 90 days including current day, so we have to minus 1 from 90
        const start = startOfDayInZone(subDays(nowInZone(), 89))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    last_year: {
      text: translate('date_last_year'),
      onClick(picker) {
        const start = startOfDayInZone(subYears(nowInZone(), 1))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
  }

  Object.entries(shortcutDefinitions).forEach(([key, shortcut]) => {
    if (!excludeShortcuts.includes(key)) {
      shortcuts.push(shortcut)
    }
  })

  return {
    disabledDate: (time) => {
      // Convert the incoming picker date to UTC to be able to compare it with the current time in user acc timezone
      const pickerDateUTC = zonedTimeToUtc(
        time,
        store.state.userProfile.timezone
      )
      // Get the current date and time in user acc timezone and convert it to UTC
      const nowInZoneUTC = zonedTimeToUtc(
        new Date(),
        store.state.userProfile.timezone
      )
      // Disable the date if it's in the future relative to user acc timezone
      return isFuture(pickerDateUTC) && pickerDateUTC > nowInZoneUTC
    },
    shortcuts: shortcuts,
  }
}

export function formatCPCNumber(value) {
  // number and dot only
  let res = value
    .replace(/[^\d.{1}]/g, '') // Remove all characters except digits and dots
    .replace(/(^\.)/, '0$1') // Add '0' before the dot if the number starts with a dot
    .replace(/\.(?=.*\.)/g, '') // Remove all dots except the last one

  const decimalIndex = res.indexOf('.')

  // If the decimal point is found and there are more than 2 decimal places, truncate the input
  if (decimalIndex !== -1 && res.slice(decimalIndex + 1).length > 2) {
    res = res.slice(0, decimalIndex + 3)
  }

  return res
}

export const getPlacementFormType = (placementId) => {
  switch (placementId) {
    case SPONSORED_STORE_ID:
      return 'storeForms'
    case SPONSORED_BANNER_ID:
      return 'bannerForms'
    default:
      return null
  }
}
