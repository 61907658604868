import { isString, isObject } from '@/utils/validate.js'

const DEFAULT_TIMEOUT = 2_000

class RequestCache {
  constructor() {
    this.cache = {}
  }

  // This function normalizes parameters to avoid cases where objects have the same data but have different key orders.
  _normalizeObjectParams = (params) => {
    if (!isObject(params)) {
      return params
    }

    return Object.keys(params)
      .sort()
      .reduce((result, key) => {
        result[key] = isObject(params[key])
          ? this._normalizeObjectParams(params[key])
          : params[key]

        return result
      }, {})
  }

  _generateCacheKey = (params) => {
    if (isString(params)) {
      return params
    }

    if (isObject(params)) {
      const normalizedParams = this._normalizeObjectParams(params)

      return JSON.stringify(normalizedParams)
    }

    // Unsupported key's type
    return null
  }

  _isCacheValid = (cachedItem) => {
    const currentTime = new Date().getTime()

    return currentTime - cachedItem.timestamp < cachedItem.timeout
  }

  getCachedData(params) {
    const key = this._generateCacheKey(params)
    const cachedItem = this.cache[key]

    if (!cachedItem) {
      return null
    }

    if (this._isCacheValid(cachedItem)) {
      return cachedItem.data
    }

    // Remove cache
    delete this.cache[key]

    return null
  }

  setCachedData(params, data, timeout = DEFAULT_TIMEOUT) {
    const timestamp = new Date().getTime()
    const key = this._generateCacheKey(params)

    this.cache[key] = { data, timestamp, timeout: timeout }
  }
}

export default new RequestCache()
