import BaseApi from '@/api/base-api'

class OnboardingApi extends BaseApi {
  initializeSession(data) {
    return this.request(
      {
        path: `${this.uri}/initialize`,
        method: 'POST',
        data,
      },
      {
        options: {
          attachToken: false,
        },
      }
    )
  }

  getSession(onboardingId) {
    return this.request(
      {
        path: `${this.uri}/${onboardingId}`,
        method: 'GET',
      },
      {
        options: {
          attachToken: false,
          camelizeRes: false,
        },
      }
    )
  }

  submit(data, onboardingId, tokenRequired = false) {
    if (onboardingId)
      return this.request(
        {
          path: `${this.uri}/${onboardingId}`,
          method: 'POST',
          data: {
            data,
          },
        },
        {
          options: {
            attachToken: tokenRequired,
          },
        }
      )
  }

  getPricing(onboardingId) {
    return this.request({
      path: `${this.uri}/${onboardingId}/pricing-bundle`,
      method: 'GET',
    })
  }

  fetchOnboardingCategories(onboardingId) {
    return this.request(
      {
        path: `${this.uri}/${onboardingId}/categories`,
        method: 'GET',
      },
      {
        options: {
          attachToken: false,
        },
      }
    )
  }
}

export default new OnboardingApi('/merchants/onboarding')
