<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import AddressCollectionDialog from '@/views/users/address-collection-dialog'
import ContactDialog from '@/components/ContactDialog'
import RatingDialog from '@/views/rating/rating-dialog.vue'
import ContactDetails from '@/api/merchant/contact-details'
import userHasPermission from '@/utils/user-has-permission'

export default {
  name: 'SitewideDialogs',
  components: {
    RatingDialog,
    AddressCollectionDialog,
    ContactDialog,
  },
  data() {
    return {
      skipSiteWideDialogsFlag: 'site-wide-dialogs-skip',
      currentDialog: null,
      addressesResponse: null,
      ratingResponse: null,
      contactResponse: null,
    }
  },
  computed: {
    ...mapGetters('profile', ['profile']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    ...mapGetters('kyc', ['onboardingData']),
    ...mapGetters('address', ['addresses', 'fetchingAddresses']),
    ...mapGetters('rating', ['loading', 'lastRating']),
    isKycUser() {
      return this.userProfile && this.userProfile.role === 'MERCHANT_KYC_USER'
    },
    isKycRoute() {
      return this.$route.meta.isKyc === true
    },
    shouldShowSiteWideDialog() {
      return !localStorage.getItem(this.skipSiteWideDialogsFlag)
    },
    userCanEditAddress() {
      return userHasPermission(['create_merchant_account_address'])
    },
    userCanEditContact() {
      return userHasPermission(['update_merchant_contact'])
    },
    addressDialogVisible() {
      return this.isAddressInfoMissing && this.userCanEditAddress
    },
    contactDialogVisible() {
      return this.isContactInfoMissing && this.userCanEditContact
    },
    ratingDialogVisible() {
      return this.isRatingInfoMissing
    },
    isAddressInfoMissing() {
      if (
        !this.addressesResponse ||
        !Array.isArray(this.addressesResponse) ||
        this.addressesResponse.length === 0
      ) {
        return true
      }
      const isAddressHaveKSA = this.addressesResponse.some(
        (item) => item.country === 'SA'
      )
      if (isAddressHaveKSA) {
        // Find a Saudi Arabia address object with a non-empty address
        const saudiAddress = this.addressesResponse.find(
          (obj) =>
            obj.country === 'SA' &&
            obj.address &&
            (Array.isArray(obj.address)
              ? obj.address.length > 0
              : obj.address.district)
        )

        // Determine if the found Saudi address has a valid district as it is the first required field
        const hasValidSaudiAddress = saudiAddress !== undefined

        return !hasValidSaudiAddress
      } else {
        const merchantCountryAddress = this.addressesResponse.find(
          (obj) =>
            obj.country === this.profile.country &&
            obj.address &&
            (Array.isArray(obj.address)
              ? obj.address.length > 0
              : obj.address.district)
        )

        // Determine if the found Merchant country address has a valid district as it is the first required field
        const hasValidAddress = merchantCountryAddress !== undefined

        return !hasValidAddress
      }
    },
    isContactInfoMissing() {
      if (
        !this.contactResponse ||
        !Array.isArray(this.contactResponse.data) ||
        this.contactResponse.data.length === 0
      ) {
        return true // Contact info is missing because there's no data
      }

      // Find the contact object for the user's country
      const contact = this.contactResponse.data.find(
        (c) => c.country === this.profile.country
      )

      // Check if the contact object doesn't exist or is missing all contact fields
      const hasValidContact =
        contact &&
        (contact.email ||
          contact.phone ||
          contact.supportCentreUrlAr ||
          contact.supportCentreUrlEn ||
          contact.whatsapp)
      return !hasValidContact
    },
    isRatingInfoMissing() {
      const shouldDisplayForNoRating = Object.keys(this.lastRating).length === 0

      const shouldDisplayForOldRating =
        this.lastRating &&
        this.lastRating.submittedOn &&
        moment(this.lastRating.submittedOn)
          .add(30, 'days')
          .isBefore(moment(), 'day')

      return shouldDisplayForNoRating || shouldDisplayForOldRating
    },
  },
  async created() {
    if (this.shouldShowSiteWideDialog && !this.isKycUser && !this.isKycRoute) {
      await this.determineDialogToShow()
    }
  },
  methods: {
    ...mapActions('address', ['fetchAddresses', 'updateAddress']),
    ...mapActions('rating', ['saveRating', 'fetchRating']),
    async fetchLatestRating() {
      try {
        await this.$recaptchaLoaded()
        const reCaptchaToken = await this.$recaptcha('fetchRating')
        await this.fetchRating({
          email: this.userProfile.email,
          reCaptchaToken,
        })
      } catch (error) {
        console.error('Error fetching latest rating:', error)
      }
    },
    async determineDialogToShow() {
      try {
        if (this.userCanEditAddress && !this.addressesResponse) {
          this.addressesResponse = (await this.fetchAddresses()) || []
          if (this.isAddressInfoMissing) {
            this.currentDialog = 'address'
            return
          }
        }

        if (this.userCanEditContact && !this.contactResponse) {
          const response = await ContactDetails.fetchMerchantContactDetails(
            this.profile.merchantId
          )
          this.contactResponse = response.data || []
          if (this.isContactInfoMissing) {
            this.currentDialog = 'contact'
            return
          }
        }

        if (!this.loading && !this.ratingResponse) {
          await this.fetchLatestRating()
        }
        if (this.isRatingInfoMissing) {
          this.currentDialog = 'rating'
        }
      } catch (error) {
        console.error('Error determining dialog to show:', error)
      }
    },
    closeDialog() {
      // When a dialog is completed or closed, we set the flag to skip dialogs for the current session
      localStorage.setItem(this.skipSiteWideDialogsFlag, 'true')
      this.currentDialog = null // Reset the current dialog
    },
  },
}
</script>

<template>
  <div>
    <AddressCollectionDialog
      v-if="currentDialog === 'address'"
      :address-dialog-visible="addressDialogVisible"
      @closeDialog="closeDialog"
    />
    <ContactDialog
      v-if="currentDialog === 'contact'"
      :contact-dialog-visible="contactDialogVisible"
      @closeDialog="closeDialog"
    />
    <RatingDialog
      v-if="
        currentDialog === 'rating' && !$route.path.includes('/ads/dashboard')
      "
      :rating-dialog-visible="ratingDialogVisible"
      @closeDialog="closeDialog"
    />
  </div>
</template>
