const removeSpaceStartAndEnd = (value) => {
  if (!value) return value
  // Replace '. ' with a space
  value = value.split('. ').join(' ')
  // Replace multiple spaces with a single space
  while (value.includes('  ')) {
    value = value.replace('  ', ' ')
  }
  // Remove leading spaces
  if (value.startsWith(' ')) {
    value = value.slice(1)
  }
  // Remove trailing spaces
  if (value.endsWith(' ')) {
    value = value.slice(0, -1)
  }

  return value
}

const removeAllSpace = (value) => {
  return value.split(' ').join('')
}

export { removeSpaceStartAndEnd, removeAllSpace }
