import request from '@/utils/request'

const fetchTransactionList = (data) => {
  return request({
    path: '/merchants/orders/transaction/list',
    method: 'GET',
    data,
  })
}

export { fetchTransactionList }
