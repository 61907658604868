import { getAdvertiser } from '@/api/ads'
import store from '@/store/index'

// Check if the merchant has initialized AdTech or not
const checkMerchantInitAdtech = async () => {
  try {
    const res = await getAdvertiser()
    return res?.data?.data
  } catch {
    return false
  }
}

// Check 2 cases:
// + When user switch to another merchant account => we should redirect back to dashboard page
// + When user is new => we should redirect to dashboard too
export default async function adsRouteGuard(to, from, next) {
  store.dispatch('ads/checkUserType')

  // If redirect back to ads dashboard then we can ignore the check
  // since ads dashboard is also the onboarding page for new adtech merchant
  if (to.path === '/ads/dashboard') {
    next()
    return
  }

  const selectedMerchant = localStorage.getItem('selectedMerchant')
  const adTechMerchant = localStorage.getItem('adTechMerchant')
  let proceedToNext = false

  // If the merchant is new (adTechMerchant null) or user select different merchant (adTechMerchant !== selectedMerchant)
  // then redirect to ads dashboard
  // If NOT then continue routing
  if (!adTechMerchant || adTechMerchant !== selectedMerchant) {
    const isMerchantInitialized = await checkMerchantInitAdtech()

    if (isMerchantInitialized) {
      localStorage.setItem('adTechMerchant', selectedMerchant)
      proceedToNext = true
    }
  } else {
    proceedToNext = true
  }

  next(proceedToNext ? undefined : '/ads/dashboard')
}
