import BaseApi from '@/api/base-api'

class MerchantAddressApi extends BaseApi {
  getAddresses = () => {
    return this.request({
      method: 'GET',
      path: `${this.uri}/address`,
    })
  }

  updateAddress = (merchantAccountId, data) => {
    return this.request({
      method: 'POST',
      path: `${this.uri}/account/${merchantAccountId}/address/create-update`,
      data: data,
    })
  }
}

export default new MerchantAddressApi('/merchants')
