import currencyFormatter from 'currency-formatter'

import isNumber from 'lodash/isNumber'
import lte from 'lodash/lte'
import { parseISO, format } from 'date-fns'
import i18n from '@/i18n/index'
import { formatDate } from '@/utils/date-time'
import { countryData } from '@/constants/country-data'
import { getDecimalDigitsByCurrency } from '@/utils/amount'

// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from '@/utils'

/**
 * Upper case first char
 * @param {String} string
 */
const uppercaseFirst = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

/**
 * @param {String} date
 */
const dateTimeFormatter = (date) => {
  if (!date) {
    return 'N/A'
  }

  if (typeof date !== 'string') {
    return date
  }

  return formatDate(date)
}

/**
 * @param {String} date
 * @param format
 * @param timeZone
 */
const dateFormatter = (date, { format = 'dd/MM/yyyy', timeZone } = {}) => {
  if (!date) {
    return 'N/A'
  }

  if (typeof date !== 'string') {
    return date
  }

  return formatDate(date, { format, timeZone })
}

/**
 * @param {String} date
 * @param format
 * @param timeZone
 */
const timeFormatter = (date, { format = 'hh:mm aa', timeZone } = {}) => {
  if (!date) {
    return 'N/A'
  }

  if (typeof date !== 'string') {
    return date
  }

  return formatDate(date, { format, timeZone })
}

/**
 * Format amount with currency
 * @param {Object} totalAmount
 */
const amountFormatter = (totalAmount) => {
  const { amount = 0, currency = 'SAR' } = totalAmount || {}

  return currencyFormatter.format(amount, {
    precision: getDecimalDigitsByCurrency(currency),
    format: '%v %s',
  })
}

/**
 * @param {String} paymentType
 */
const paymentTypeFormatter = (paymentType) => {
  return (
    {
      PAY_BY_INSTALMENTS: 'payment_type_instalment',
      PAY_BY_LATER: 'payment_type_paylater',
      PAY_NEXT_MONTH: 'payment_type_paynextmonth',
      PAY_NOW: 'payment_type_paynow',
    }[paymentType] || 'N/A'
  )
}

/**
 * @param {String} key
 */
const translateKey = (key) => {
  return i18n.t(key)
}

/**
 * @param {String} key
 */
const translatePaymentType = (key, numberOfInstallments) => {
  return i18n.t(key, { numberOfInstallments: numberOfInstallments || 3 })
}

const formatCountryNameByCode = (code) => {
  const isoToCountryCodeMap = countryData.reduce(
    (obj, country) => ({
      ...obj,
      [country.countryCode]: country.countryName,
    }),
    {}
  )

  return isoToCountryCodeMap[code] || '-'
}

/**
 * Get translated text of invoice type
 * @return {string} the translated text
 * @param invoice
 */
const invoiceTypeDisplay = (invoice = '') => {
  switch (invoice.toLowerCase()) {
    case 'tax':
      return i18n.t('text_tax_invoice')
    case 'settlement':
      return i18n.t('text_settlement_invoice')
    case 'credit-note':
      return i18n.t('text_credit_note')
    case 'other':
      return i18n.t('text_other_invoice')
    default:
      return ''
  }
}

/**
 * Get country based on currency
 * @returns {string} - country code
 * @param currency
 */
const countryOfCurrency = (currency = '') => {
  const country = countryData.find(
    (country) => country.currency.toLowerCase() === currency.toLowerCase()
  )
  return `${country?.countryName} (${country?.longCountryCode})`
}

const countryCurrencyDisplay = (title) => {
  if (title && title.includes('-')) {
    const countryCode = title.split('-')[0]
    const currency = title.split('-')[1]
    const countryName = countryData.find(
      (country) =>
        country.countryCode.toLowerCase() === countryCode.toLowerCase()
    )
    const currencyName = countryData.find(
      (country) => country.currency.toLowerCase() === currency.toLowerCase()
    )
    return `${countryName.shortCountryName} ${currencyName.currencyName}`
  }
  return ''
}

/**
 * Format amount based on decimal digits
 * @param amount
 * @param decimalDigits
 * @returns {string}
 */
const formatAmountBasedOnDecimalDigits = (amount, decimalDigits = 2) => {
  // Ensure that the amount is a number
  const parsedAmount = parseFloat(amount)

  // Check if the parsed amount is a valid number
  if (isNaN(parsedAmount)) {
    throw new Error('Invalid amount')
  }

  // Truncate the amount to the required number of decimal digits
  const truncRegex = new RegExp(
    '^-?\\d+(?:.\\d{0,' + (decimalDigits || -1) + '})?'
  )
  const truncated = amount.toString().match(truncRegex)[0]

  return parseFloat(truncated).toFixed(decimalDigits)
}

const isZeroOrLess = (num) => {
  // Check if the input is a number
  if (!isNumber(num)) {
    return false
  }

  // Check if the input is 0 or less
  return lte(num, 0)
}

/**
 * Converts a date string from "year-month-day" to "day-month-year".
 * @param {string} dateString - The date string to convert.
 * @returns {string} - The formatted date string.
 */
const convertToDayMonthYear = (dateString) => {
  try {
    const date = parseISO(dateString)
    return format(date, 'dd/MM/yyyy')
  } catch (error) {
    console.error('Failed to convert date:', error)
    return null
  }
}

export {
  amountFormatter,
  uppercaseFirst,
  dateTimeFormatter,
  dateFormatter,
  paymentTypeFormatter,
  translateKey,
  translatePaymentType,
  timeFormatter,
  formatCountryNameByCode,
  invoiceTypeDisplay,
  countryOfCurrency,
  countryCurrencyDisplay,
  formatAmountBasedOnDecimalDigits,
  isZeroOrLess,
  convertToDayMonthYear,
}
