import request from '@/utils/request'

const fetchDashboardOverview = (data) => {
  return request({
    path: '/dashboard/overview',
    method: 'GET',
    data,
  })
}

const submitFarhaForm = (data, recaptchaToken) => {
  return request(
    {
      path: `/farah/subscribe`,
      method: 'POST',
      data,
    },
    {
      options: {
        hideMessage: true,
        headers: {
          'x-tamara-token': recaptchaToken,
        },
      },
    }
  )
}

export { fetchDashboardOverview, submitFarhaForm }
