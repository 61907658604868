<template>
  <section class="app-main">
    <transition name="fade-transform" mode="out-in">
      <keep-alive :include="cachedViews">
        <router-view :key="`${selectedMerchant}${key}`" />
      </keep-alive>
    </transition>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AppMain',
  computed: {
    ...mapGetters('userProfile', ['selectedMerchant']),
    ...mapGetters('profile', { merchantProfile: 'profile' }),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    cachedViews() {
      return this.$store.state.tagsView.cachedViews
    },
    key() {
      return this.$route.path
    },
  },
  async created() {
    // only fetch profile if it's an active user
    if (
      !['MERCHANT_ONBOARDING_USER', 'MERCHANT_KYC_USER'].includes(
        this.userProfile.role
      )
    ) {
      await this.getProfile()
    }
  },
  methods: {
    ...mapActions('profile', ['getProfile']),
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  flex: 1;
  min-height: 90vh;
  padding: 10px 72px 150px 39px;
  background: #fff;

  [dir='rtl'] & {
    padding: 39px 39px 39px 72px;
  }
}
</style>
