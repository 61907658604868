import { isArray } from '@/utils/validate'
import userHasPermission from '@/utils/user-has-permission'

/**
 *
 * @param routes
 * @param targetPath
 * @returns {*|null}
 */
const findRouteByPath = (routes, targetPath) => {
  if (!isArray(routes) || !targetPath) {
    return null
  }

  for (const route of routes) {
    if (
      route.path === targetPath &&
      userHasPermission(route.meta?.permissions)
    ) {
      return route
    }

    const foundChildRoute = findRouteByPath(route.children, targetPath)

    if (foundChildRoute) {
      return foundChildRoute
    }
  }

  return null
}

export { findRouteByPath }
