import BaseApi from '@/api/base-api'

class RatingApi extends BaseApi {
  fetchLatestRating(email, recaptchaToken) {
    return this.request(
      {
        path: `${this.uri}/latest`,
        method: 'GET',
        data: {
          email,
        },
      },
      {
        options: {
          headers: {
            'x-tamara-token': recaptchaToken,
          },
          hideMessage: true,
        },
      }
    )
  }

  saveRating(rating, comment, recaptchaToken) {
    return this.request(
      {
        path: `${this.uri}/submit`,
        method: 'POST',
        data: {
          source: 'web',
          rating,
          comment,
        },
      },
      {
        options: {
          headers: {
            'x-tamara-token': recaptchaToken,
          },
        },
      }
    )
  }
}

export default new RatingApi('/merchants/rating')
