import RatingApi from '@/api/rating'

const state = {
  data: null,
  fetched: false,
  fetching: false,
  loading: false,
  error: null,
}
const getters = {
  lastRating: (state) => {
    return state.data || {}
  },
  loading: (state) => {
    return state.loading
  },
}
const mutations = {
  SET_STATUS(state, { key, value }) {
    state[key] = value
  },
  UPDATE_DATA(state, data) {
    state.data = data
  },
}
const actions = {
  async fetchRating({ commit }, { email, reCaptchaToken }) {
    try {
      commit('SET_STATUS', { key: 'loading', value: true })
      const response = await RatingApi.fetchLatestRating(email, reCaptchaToken)
      commit('UPDATE_DATA', response.data)
      commit('SET_STATUS', { key: 'loading', value: false })
      return response.data
    } catch (e) {
      if (e.response.status !== 404) {
        throw e
      }
    } finally {
      commit('SET_STATUS', { key: 'loading', value: false })
    }
  },
  async saveRating({ commit }, { rating, comment, reCaptchaToken }) {
    try {
      commit('SET_STATUS', { key: 'loading', value: true })
      await RatingApi.saveRating(rating, comment, reCaptchaToken)
      commit('SET_STATUS', { key: 'loading', value: false })
    } catch (e) {
      commit('SET_STATUS', { key: 'loading', value: false })
      throw e
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }
