import request from '@/utils/request'

const uploadCreativeFile = (data) => {
  return request({
    path: `/v1/creative`,
    method: 'POST',
    data,
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const createCampaign = (data) => {
  return request(
    {
      path: `/v1/campaign`,
      method: 'POST',
      data,
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    },
    {
      options: {
        hideMessage: true,
      },
    }
  )
}

const getCampaign = (campaignId, disableCamelizeRes = false) => {
  return request(
    {
      path: `/v1/campaign/${campaignId}`,
      method: 'GET',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    },
    {
      options: {
        disableCamelizeRes,
      },
    }
  )
}

const updateCampaign = ({ campaignId, data }) => {
  return request(
    {
      path: `/v1/campaign/${campaignId}`,
      method: 'PUT',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
      data,
    },
    {
      options: {
        hideMessage: true,
        disableErrorRedirect: true,
      },
    }
  )
}

const submitPayment = ({
  cardToken,
  amount,
  billingAccountId,
  paymentMethod,
  checkoutPublicKey,
}) => {
  return request({
    path: '/v1/payment/checkout/submit',
    method: 'POST',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    data: {
      card_token: cardToken,
      billing_account_id: billingAccountId,
      amount,
      payment_method: paymentMethod,
      checkout_public_key: checkoutPublicKey,
    },
  })
}

const getPaymentStatus = (paymentId) => {
  return request({
    path: `/v1/payment/${paymentId}`,
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const createAdvertiser = () => {
  return request({
    path: '/v1/advertiser',
    method: 'POST',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getAdvertiser = () => {
  return request(
    {
      path: '/v1/advertiser',
      method: 'GET',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    },
    {
      options: {
        hideMessage: true,
      },
    }
  )
}

const getWalletBalance = () => {
  return request({
    path: '/v1/wallet',
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getWalletHistory = ({ page, pageSize }) => {
  return request({
    path: '/v1/wallet/history',
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    data: {
      page,
      pageSize,
    },
  })
}

const getCampaigns = ({
  statusGroup = '',
  status = '',
  page = 1,
  pageSize = 10,
}) => {
  return request({
    path: '/v1/campaign',
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    data: {
      page,
      pageSize,
      statusGroup,
      status,
      includeStats: true,
    },
  })
}

const deleteCampaign = (campaignId) => {
  return request({
    path: `/v1/campaign/${campaignId}`,
    method: 'DELETE',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const activateCampaign = (campaignId) => {
  return request({
    path: `/v1/campaign/${campaignId}/activate`,
    method: 'PUT',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const deactivateCampaign = (campaignId) => {
  return request({
    path: `/v1/campaign/${campaignId}/deactivate`,
    method: 'PUT',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getAdvertiserReport = ({ startDate, endDate }) => {
  return request(
    {
      path: '/v1/advertiser/reports',
      method: 'GET',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
      data: {
        startDate,
        endDate,
      },
    },
    {
      options: {
        disableCamelizeRes: true,
      },
    }
  )
}

const getAddresses = () => {
  return request(
    {
      path: '/v1/advertiser/addresses',
      method: 'GET',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    },
    {
      options: {
        hideMessage: true,
      },
    }
  )
}

const getWalletHealth = () => {
  return request({
    path: '/v1/wallet/health',
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getAdtechFeatureFlag = () => {
  return request(
    {
      path: '/v1/featureflag',
      method: 'GET',
      customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    },
    {
      options: {
        hideMessage: true,
      },
    }
  )
    .then((res) => res.data.data)
    .catch(() => ({ isAdtechEnabled: false, isAdsTopUpBannerEnabled: false }))
}

const getCPCRecommendation = (data) => {
  return request({
    path: `/v1/recommendation/cpc?${data}`,
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getSegment = (campaignId) => {
  return request({
    path: `/v1/segment`,
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

const getAdsAsset = ({ placementId, page, pageSize }) => {
  return request({
    path: `/v1/ad-assets`,
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
    data: {
      placementId,
      page,
      pageSize,
    },
  })
}

const getLatestRating = ({ email, feature, reCaptchaToken }) => {
  return request(
    {
      path: '/merchants/rating/latest',
      method: 'GET',
      data: {
        email,
        feature,
      },
    },
    {
      options: {
        headers: {
          'x-tamara-token': reCaptchaToken,
        },
        hideMessage: true,
      },
    }
  )
}

const saveRating = ({ rating, comment, feature, reCaptchaToken }) => {
  return request(
    {
      path: '/merchants/rating/submit',
      method: 'POST',
      data: {
        source: 'web',
        rating,
        comment,
        feature,
      },
    },
    {
      options: {
        headers: {
          'x-tamara-token': reCaptchaToken,
        },
      },
    }
  )
}

const getCampaignStatusGroup = () => {
  return request({
    path: `/v1/campaign/count-by-status-group`,
    method: 'GET',
    customBaseURL: process.env.VUE_APP_ADTECH_BASE_URL,
  })
}

export {
  uploadCreativeFile,
  createCampaign,
  getCampaign,
  updateCampaign,
  submitPayment,
  getPaymentStatus,
  createAdvertiser,
  getAdvertiser,
  getWalletBalance,
  getWalletHistory,
  getCampaigns,
  deleteCampaign,
  activateCampaign,
  deactivateCampaign,
  getAdvertiserReport,
  getAddresses,
  getWalletHealth,
  getAdtechFeatureFlag,
  getCPCRecommendation,
  getSegment,
  getAdsAsset,
  getLatestRating,
  saveRating,
  getCampaignStatusGroup,
}
