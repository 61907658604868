import store from '@/store'

/**
 * @param {Array} permissions
 * @returns {Boolean}
 */
export default function userHasPermission(permissions) {
  if (permissions && Array.isArray(permissions) && permissions.length > 0) {
    const userPermissions = store.getters['userProfile/userPermissions']
    return userPermissions.some((permission) =>
      permissions.includes(permission.code)
    )
  }
  return false
}
