<template>
  <div :class="classObj" class="app-wrapper">
    <Topbar />
    <EnvironmentIndicator v-if="!isProduction" layout="main" />
    <div class="content-container">
      <DefaultSidebar class="sidebar-container" />
      <div class="main-container">
        <Navbar />
        <AppMain />
        <AppFooter />
      </div>
    </div>
    <SitewideDialogs />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import AppMain from '@/components/AppMain'
import AppFooter from '@/components/AppFooter'
import DefaultSidebar from '@/components/DefaultSidebar'
import Navbar from '@/components/NavBarr/index.vue'
import Topbar from '@/components/TopBarr/index.vue'
import EnvironmentIndicator from '@/components/EnvironmentIndicator/index.vue'
import SitewideDialogs from '@/components/SitewideDialogs'

export default {
  name: 'Layout',
  components: {
    EnvironmentIndicator,
    AppMain,
    Navbar,
    Topbar,
    DefaultSidebar,
    SitewideDialogs,
    AppFooter,
  },
  computed: {
    ...mapState({
      device: (state) => state.app.device,
      needTagsView: (state) => state.settings.tagsView,
    }),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    classObj() {
      return {
        mobile: this.device === 'mobile',
      }
    },
    isProduction() {
      return process.env.VUE_APP_ENV === 'production'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.scss';
@import '~@/styles/_breakpoints';

.app-wrapper {
  position: relative;
  width: fit-content;
  min-width: 100%;

  &::after {
    display: table;
    clear: both;
    content: '';
  }
}

.drawer-bg {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.content-container {
  display: flex;
  align-items: stretch;

  @include breakpoints(md) {
    min-height: 100%;
  }
}

.sidebar-container {
  flex-shrink: 0;

  [dir='ltr'] & {
    position: sticky;
    top: 0;
    align-self: flex-start;
    overflow-y: auto;
  }
}

.main-container {
  @apply flex flex-col;
}
</style>
