/**
 * Created by PanJiaChen on 16/11/18.
 */

import { regAlphabets, regEmail } from '@/utils/regex'

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const validMap = ['admin', 'editor']
  return validMap.indexOf(str.trim()) >= 0
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

export function isValidUrlWithOptionalProtocol(url) {
  const reg = /^(https?:\/\/)?([\p{L}\d.-]+)\.([a-z.]{2,6})([/\w.-]*)*(\?.*)?$/u
  return reg.test(url)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  return regAlphabets.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  return regEmail.test(email)
}

export function doesNotContainNumbersOrSpecialCharacters(str) {
  const reg = /^[^\d!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/
  return reg.test(str)
}

export function isValidDateString(date) {
  const reg = /^\d{4}-\d{2}-\d{2}$/
  return reg.test(date)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function doesNotContainSpecialCharacters(str) {
  const reg = /^[a-zA-Z0-9\s]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function doesNotContainSpecialCharactersAndAllowArabic(str) {
  const reg = /^[a-zA-Z0-9\s\u0600-\u06FF]+$/
  return reg.test(str)
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function containsOnlyNumericAndHash(str) {
  const reg = /^[0-9#]+$/
  return reg.test(str)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true
  }
  return false
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}

/**
 * @param {Number} target
 * @returns {Boolean}
 */
export function isNumber(target) {
  return typeof target === 'number' && !isNaN(target)
}

/**
 * @param {Object} target
 * @returns {Boolean}
 */
export function isObject(target) {
  return Object.prototype.toString.call(target) === '[object Object]'
}

export function isFile(target) {
  return Object.prototype.toString.call(target) === '[object File]'
}

export function isMSDocument(file) {
  if (!isFile(file)) {
    return false
  }

  const fileName = file.name || ''
  const fileExtension = fileName
    .split('.')
    .pop()
    .toLowerCase()

  // File is a DOC/DOCX file.
  if (fileExtension === 'doc' || fileExtension === 'docx') {
    return true
  }

  // Alternatively, you can use MIME type checking
  const mimeType = file.type

  // File is a DOC file based on MIME type. 'application/msword'
  // File is a DOCX file based on MIME type. When type is 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  return (
    mimeType === 'application/msword' ||
    mimeType ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  )
}
