<script>
import { getWalletBalance } from '@/api/ads'
import { formatNumber } from '@/views/ads/utils/index'

export default {
  name: 'TopUpSuccess',
  props: {
    topUpAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoading: true,
      currentBalance: {
        amount: 0,
      },
    }
  },
  computed: {
    successDesc() {
      return this.$t('ads_top_up_success_desc', {
        value: formatNumber(this.topUpAmount),
      })
    },
    topUpAsNeededDesc() {
      return this.$t('ads_top_up_as_needed', {
        value: formatNumber(Number(this.currentBalance?.amount || 0)),
      })
    },
  },
  mounted() {
    this.getBalance()
  },
  methods: {
    async getBalance() {
      try {
        this.isLoading = true

        // If Success => show success popup
        const { data: walletBalanceData } = await getWalletBalance()
        this.currentBalance = walletBalanceData.data.balance
      } catch {
        this.$message({
          type: 'error',
          message: 'Unable to load your balance, please try again later!',
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<template>
  <div class="top-up-success">
    <div class="icon-container success-icon">
      <svg-icon icon-class="circled-check" />
    </div>
    <h1>{{ $t('ads_top_up_success_title') }}</h1>
    <p>
      {{ successDesc }}
    </p>
    <div class="current-balance">
      <h3>{{ $t('ads_top_up_success_curr_balance') }}</h3>
      <div class="balance-value">
        <template v-if="isLoading">
          {{ $t('ads_balance_loading') }}
        </template>
        <template v-else>
          {{ currentBalance | amountFormatter }}
        </template>
      </div>
    </div>
    <p>
      {{ topUpAsNeededDesc }}
    </p>
    <router-link to="/ads/create" class="primary-cta-btn mb-3" replace>
      {{ $t('ads_top_up_success_create_campaign') }}
    </router-link>
    <router-link to="/ads/dashboard" class="secondary-cta-btn" replace>
      {{ $t('ads_top_up_success_to_dashboard') }}
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
@mixin direction-aware-font {
  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }
  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

.success-icon {
  background: #93ba301a;

  svg {
    color: #93ba30;
  }
}

.current-balance {
  width: 100%;
  padding: 14px;
  margin-block-end: 28px;
  background: #f2f2f2;
  border-radius: 12px;
}

.balance-value {
  @include direction-aware-font;

  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000;
}

h3 {
  margin-block-end: 6px;
  font-size: 14px;
  line-height: 22px;
  color: #666;
}

.top-up-success {
  display: contents;
}
</style>
