import BaseApi from '@/api/base-api'

class MerchantProfileApi extends BaseApi {
  getProfile = () => {
    return this.request(
      {
        method: 'GET',
        path: this.uri,
      },
      {
        options: {
          hideMessage: true,
        },
      }
    )
  }

  getProfileByMerchantId = (merchantId) => {
    return this.request(
      {
        method: 'GET',
        path: this.uri,
        skipMerchantId: true,
      },
      {
        options: {
          hideMessage: true,
          headers: {
            'X-Requested-With': merchantId,
          },
        },
      }
    )
  }
}

export default new MerchantProfileApi('/merchants/profile')
