import request from '@/utils/request'

const getAuthHeaders = ({ token }) => ({
  'x-tamara-token': token,
})

const getAuthMetaData = (params) => ({
  options: {
    headers: getAuthHeaders(params),
  },
})

export function login({ email, password, reCaptchaToken }) {
  return request(
    {
      path: '/merchants/login',
      method: 'POST',
      data: { email, password },
    },
    getAuthMetaData({ token: reCaptchaToken })
  )
}

export function fetchSsoSettings(provider) {
  return request({
    path: '/merchants/auth',
    method: 'GET',
    data: { auth_provider: provider },
  })
}

export function loginSso({ provider, authCode }) {
  return request({
    path: `/merchants/auth/confirm?auth_provider=${provider}&code=${authCode}`,
    method: 'POST',
  })
}

export function activate({
  reCaptchaToken,
  userId,
  newPassword,
  confirmPassword,
  hashCode,
}) {
  const meta = getAuthMetaData({ token: reCaptchaToken })

  meta.options = { ...meta.options, hideMessage: true, attachToken: false }

  return request(
    {
      method: 'POST',
      path: `/merchants/users/${userId}/activate`,
      data: {
        hash_code: hashCode,
        password: newPassword,
        confirm_password: confirmPassword,
      },
    },
    meta
  )
}

export function forgotPassword({ email, locale, reCaptchaToken }) {
  const meta = getAuthMetaData({ token: reCaptchaToken })

  meta.options = { ...meta.options, hideMessage: true, attachToken: false }

  return request(
    {
      path: '/merchants/users/send-reset-password-email',
      method: 'POST',
      data: { email, locale },
    },
    meta
  )
}

export function resendActivateEmail({ userId, locale, reCaptchaToken }) {
  const meta = getAuthMetaData({ token: reCaptchaToken })

  meta.options = { ...meta.options, hideMessage: true }

  return request(
    {
      path: `/merchants/users/${userId}/resend-activate-email`,
      method: 'POST',
      data: { locale },
    },
    meta
  )
}

export function resetPassword({
  userId,
  hashCode,
  password,
  confirmPassword,
  reCaptchaToken,
}) {
  const meta = getAuthMetaData({ token: reCaptchaToken })

  meta.options = { ...meta.options, hideMessage: true, attachToken: false }
  return request(
    {
      path: `/merchants/users/${userId}/reset-password`,
      method: 'POST',
      data: {
        hash_code: hashCode,
        password,
        confirm_password: confirmPassword,
      },
    },
    meta
  )
}

export function fetchUsers(data) {
  return request({
    path: '/merchants/users',
    method: 'GET',
    data,
  })
}

export function fetchUserDetail(userId) {
  return request({
    path: `/merchants/users/${userId}`,
    method: 'GET',
  })
}

export function createUser(data) {
  return request(
    {
      path: `/merchants/users/create`,
      method: 'POST',
      data: {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        role: data.role,
        locale: data.locale,
        stores: data.stores,
        branches: data.branches,
        time_zone: data.timeZone,
      },
    },
    {
      options: { hideMessage: true },
    }
  )
}

export function updateUser(data) {
  return request(
    {
      path: `/merchants/users/${data.userId}/update`,
      method: 'PUT',
      data: {
        first_name: data.firstName,
        last_name: data.lastName,
        role: data.role,
        locale: data.locale,
        stores: data.stores,
        branches: data.branches,
        time_zone: data.timeZone,
      },
    },
    {
      options: { hideMessage: true },
    }
  )
}

export function deleteUser({ userId, stores }) {
  return request(
    {
      path: `/merchants/users/${userId}/delete`,
      method: 'DELETE',
      data: {
        stores,
      },
    },
    {
      options: { hideMessage: true },
    }
  )
}

export function fetchRoles() {
  return request({
    path: '/users/roles/all',
    method: 'GET',
  })
}

export function fetchPermissionGroups() {
  return request({
    path: '/permissions/all',
    method: 'GET',
  })
}

export function fetchRoleDetail(roleId) {
  return request({
    path: `/users/roles/${roleId}/details`,
    method: 'GET',
  })
}

export function createRole(data) {
  return request(
    {
      path: `/users/roles/create`,
      method: 'POST',
      data: {
        role_name: data.roleName,
        role_name_ar: data.roleNameAr,
        role_description: data.roleDescription,
        role_description_ar: data.roleDescriptionAr,
        permissions: data.permissions,
      },
    },
    {
      options: { hideMessage: true },
    }
  )
}

export function updateRole(data) {
  return request(
    {
      path: `/users/roles/${data.roleId}/update`,
      method: 'PUT',
      data: {
        role_name: data.roleName,
        role_name_ar: data.roleNameAr,
        role_description: data.roleDescription,
        role_description_ar: data.roleDescriptionAr,
        permissions: data.permissions,
      },
    },
    {
      options: { hideMessage: true },
    }
  )
}

export function fetchUserBranches(params) {
  return request({
    path: `/merchants/users/branches?${params}`,
    method: 'GET',
  })
}

export function downloadUsers(data) {
  return request(
    {
      timeout: 100000, // ms
      method: 'GET',
      path: `/merchants/users/report`,
      data: data,
    },
    {
      options: {
        hideMessage: true,
        camelizeRes: false,
      },
    }
  )
}
