import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.permissions to determine if the current user has permission
 * @param permissions
 * @param route
 */
function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    return permissions.some((permission) =>
      route.meta.permissions.includes(permission.code)
    )
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param permissions
 */
export function filterAsyncRoutes(routes, permissions) {
  const res = []

  routes.forEach((route) => {
    const tmp = { ...route }
    if (hasPermission(permissions, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, permissions)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [...constantRoutes],
  addRoutes: [],
  addedRoutes: false,
  sessionExpired: false,
}

const getters = {
  sessionExpired: (state) => state.sessionExpired,
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addedRoutes = true
    state.addRoutes = routes
    state.routes = state.routes.concat(routes)
  },
  RESET_ROUTES: (state) => {
    state.routes = [...constantRoutes]
    state.addRoutes = []
    state.addedRoutes = false
  },
  SET_SESSION_EXPIRED: (state, value) => {
    state.sessionExpired = value
  },
}

const actions = {
  generateRoutes({ commit }, permissions) {
    return new Promise((resolve) => {
      const accessedRoutes = filterAsyncRoutes(asyncRoutes, permissions)
      commit('SET_ROUTES', accessedRoutes)
      resolve(accessedRoutes)
    })
  },
  resetRoutes({ commit }) {
    commit('RESET_ROUTES')
  },
  setSessionExpired({ commit, dispatch }) {
    commit('SET_SESSION_EXPIRED', '')

    return true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
