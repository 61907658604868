import request from '@/utils/request'

const fetchApiKeys = () => {
  return request({
    path: '/merchants/get-api-keys',
    method: 'GET',
  })
}

const fetchOtherKeys = () => {
  return request({
    path: '/merchants/get-other-keys',
    method: 'GET',
  })
}

const sendOtp = ({ email }) => {
  return request({
    path: '/merchants/otp/send_otp',
    method: 'POST',
    data: {
      email,
    },
  })
}

const confirmOtp = ({ email, code }) => {
  return request({
    path: '/merchants/otp/confirm',
    method: 'POST',
    data: {
      email,
      code,
    },
  })
}

const verifyOtp2FA = ({ email, otp }) => {
  return request(
    {
      path: '/merchants/2fa/otp/verify',
      method: 'POST',
      data: {
        email,
        otp,
      },
    },
    {
      options: {
        hideMessage: true,
      },
    }
  )
}
const resendOtp2FA = ({ email }) => {
  return request({
    path: '/merchants/2fa/otp/resend',
    method: 'POST',
    data: {
      email,
    },
  })
}
export {
  fetchApiKeys,
  fetchOtherKeys,
  sendOtp,
  confirmOtp,
  verifyOtp2FA,
  resendOtp2FA,
}
