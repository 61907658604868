<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Tutorial',
  data() {
    return {
      drawer: false,
      activeKey: null,
      tutorials: [
        {
          key: 1,
          displayOn: 'order-list',
          title: this.$t('tutorial_create_order_title'),
          description: this.$t('tutorial_create_order_description'),
          videoLink:
            'https://www.youtube.com/embed/c_X8r1kKDCw?si=gHOqHql6IgXn5Ukd',
          language: 'en_US',
        },
        {
          key: 2,
          displayOn: 'order-list',
          title: this.$t('tutorial_search_order_title'),
          description: this.$t('tutorial_search_order_description'),
          videoLink:
            'https://www.youtube.com/embed/X-hPRMy31IQ?si=FUHAdZuSMzfPOu-1',
          language: 'en_US',
        },
        {
          key: 3,
          displayOn: 'order-list',
          title: this.$t('tutorial_create_order_title'),
          description: this.$t('tutorial_create_order_description'),
          videoLink: 'https://www.youtube.com/embed/gD7cEOuXV4c',
          language: 'ar_SA',
        },
        {
          key: 4,
          displayOn: 'order-list',
          title: this.$t('tutorial_search_order_title'),
          description: this.$t('tutorial_search_order_description'),
          videoLink: 'https://www.youtube.com/embed/OdKTXepW1bU',
          language: 'ar_SA',
        },
        {
          key: 5,
          displayOn: 'order-detail',
          title: this.$t('tutorial_order_refund_title'),
          description: this.$t('tutorial_order_refund_description'),
          videoLink:
            'https://www.youtube.com/embed/tyx9PmD6its?si=bfsp5btOVO77PZ1v',
          language: 'en_US',
        },
        {
          key: 6,
          displayOn: 'order-detail',
          title: this.$t('tutorial_order_refund_title'),
          description: this.$t('tutorial_order_refund_description'),
          videoLink:
            'https://www.youtube.com/embed/8xSk1ClXBKY?si=_q4bnaO9A720Mw8k',
          language: 'ar_SA',
        },
        {
          key: 7,
          displayOn: 'store-list',
          title: this.$t('tutorial_create_branch_title'),
          description: this.$t('tutorial_create_branch_description'),
          videoLink: 'https://www.youtube.com/embed/LV1jGBFsbfU',
          language: 'en_US',
        },
        {
          key: 8,
          displayOn: 'store-list',
          title: this.$t('tutorial_create_branch_title'),
          description: this.$t('tutorial_create_branch_description'),
          videoLink: 'https://www.youtube.com/embed/JixxH5zmX80',
          language: 'ar_SA',
        },
        {
          key: 9,
          displayOn: 'settlement',
          title: this.$t('tutorial_settlement_title'),
          description: this.$t('tutorial_settlement_description'),
          videoLink:
            'https://www.youtube.com/embed/oAka6QxVrB0?si=uMmAWGXNDn9eTVeh',
          language: 'en_US',
        },
        {
          key: 10,
          displayOn: 'settlement',
          title: this.$t('tutorial_settlement_title'),
          description: this.$t('tutorial_settlement_description'),
          videoLink:
            'https://www.youtube.com/embed/PAYBmptKfrg?si=DFJyYwod-lZC_XtG',
          language: 'ar_SA',
        },
        {
          key: 11,
          displayOn: 'order-detail',
          title: this.$t('tutorial_capture_order_title'),

          description: this.$t('tutorial_capture_order_description'),
          videoLink:
            'https://www.youtube.com/embed/5na6LRRBmp8?si=B3so3JTeZp5J5sSt',
          language: 'en_US',
        },
        {
          key: 12,
          displayOn: 'order-detail',
          title: this.$t('tutorial_capture_order_title'),

          description: this.$t('tutorial_capture_order_description'),
          videoLink:
            'https://www.youtube.com/embed/khbc08jCUes?si=yT3Aa3_iqPcEcNub',
          language: 'ar_SA',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('translations', ['currentLocale', 'currentDirection']),
    currentTutorials() {
      return this.tutorials.filter(
        (tutorial) =>
          tutorial.displayOn === this.$route.name &&
          tutorial.language === this.currentLocale
      )
    },
  },
  watch: {
    drawer() {
      if (this.currentTutorials) {
        this.activeKey = this.currentTutorials[0]
          ? this.currentTutorials[0].key
          : null
      }
    },
  },
  mounted() {
    if (this.currentTutorials) {
      this.activeKey = this.currentTutorials[0]
        ? this.currentTutorials[0].key
        : null
    }
  },
}
</script>
<template>
  <div v-if="currentTutorials.length" class="tutorial">
    <el-button class="tutorial__trigger" @click="drawer = true">
      <svg-icon icon-class="play-yellow" />
      <span>{{ $t('tutorial_trigger_text') }}</span>
    </el-button>
    <el-drawer
      :visible.sync="drawer"
      :with-header="true"
      :show-close="false"
      size="472px"
      :direction="currentLocale === 'en_US' ? 'rtl' : 'ltr'"
    >
      <template v-slot:title>
        <div class="tutorial__drawer-close flex justify-end">
          <el-button
            type="text"
            class="tutorial__close-button"
            @click="drawer = false"
          >
            <el-icon class="el-icon-error" />
          </el-button>
        </div>
      </template>
      <div class="tutorial__inner">
        <el-collapse v-model="activeKey" accordion>
          <template v-for="tutorial in currentTutorials">
            <el-collapse-item :key="tutorial.key" :name="tutorial.key">
              <template slot="title">
                <h3>
                  {{ tutorial.title }}
                </h3>
                <div class="tutorial__item-icon">
                  <svg-icon
                    v-if="tutorial.key !== activeKey"
                    icon-class="plus"
                  />
                  <svg-icon
                    v-if="tutorial.key === activeKey"
                    icon-class="minus"
                  />
                </div>
              </template>
              <div class="tutorial__item-inner">
                <div class="tutorial__item-video">
                  <iframe
                    width="432"
                    height="315"
                    :src="tutorial.videoLink"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  />
                </div>
                <p class="tutorial__description">{{ tutorial.description }}</p>
              </div>
            </el-collapse-item>
          </template>
        </el-collapse>
      </div>
    </el-drawer>
  </div>
</template>
<style lang="scss" scoped>
.tutorial ::v-deep .el-drawer__header {
  padding: 20px;
  margin: 0;
  border-bottom: 1px solid #eee;
}

.tutorial ::v-deep .el-collapse-item__header {
  justify-content: space-between;
  height: auto;
  padding: 24px 0;

  h3 {
    [dir='ltr'] & {
      font-family: 'Space Grotesk', sans-serif;
    }

    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
    }

    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 31px;
    color: #000;
  }

  svg {
    width: 14px;
    height: 14px;
    fill: #666;
  }
}

.tutorial ::v-deep .tutorial__close-button {
  &,
  &:hover,
  &:active {
    font-size: 20px;
    color: #000;
    border: none;
    outline: none;

    svg {
      margin-left: 10px;
      filter: none;
    }
  }

  svg {
    [dir='ltr'] & {
      margin-left: 10px;
    }

    [dir='rtl'] & {
      margin-right: 10px;
    }
  }
}

.tutorial ::v-deep .el-drawer__body {
  overflow: auto;
}

.tutorial__inner {
  padding: 0 20px 20px 20px;
}

.tutorial__inner ::v-deep .el-collapse-item__arrow {
  display: none;
}

.tutorial__description {
  margin-top: 18px;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: #666;
}

.tutorial__item-video {
  overflow: hidden;
  border-radius: 12px;
}

.tutorial__trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  span {
    margin-top: 2px;
  }

  &,
  &:focus,
  &:hover {
    color: #c38000;
    background: rgba(239, 169, 28, 0.1);
    border: none;
    border-radius: 20px;
    outline: none;

    svg {
      filter: none;
    }
  }

  svg {
    width: 24px;
    height: 24px;

    [dir='ltr'] & {
      margin-right: 8px;
    }

    [dir='rtl'] & {
      margin-left: 8px;
    }
  }

  ::v-deep & > span {
    display: flex;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
</style>
