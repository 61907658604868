import request from '@/utils/request'
import { PAY_NOW } from '@/constants/payment-types'

const fetchOrderList = (data) => {
  return request({
    path: '/v2/merchants/orders',
    method: 'GET',
    data,
  })
}

const fetchOrderDetail = (data) => {
  return request(
    {
      path: `/merchants/orders/${data.orderId}?merchant_id=${data.merchantId}`,
      method: 'GET',
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const createInStoreOrder = (data) => {
  const requestData = {
    total_amount: data.totalAmount,
    phone_number: data.phoneNumber,
    email: data.email,
    order_reference_id: data.orderReferenceId,
    additional_data: data.additionalData,
    locale: data.locale,
    expiry_time: data.expiryTime,
  }
  if (data.paymentType === PAY_NOW && data.paymentPlans.length === 1) {
    requestData.payment_type = data.paymentType
  }
  return request(
    {
      path: `/checkout/in-store-session`,
      method: 'POST',
      data: requestData,
    },
    {
      options: { hideMessage: true },
    }
  )
}

const createInStoreOrderQr = (data) => {
  const requestData = {
    amount: data.totalAmount,
    order_reference_id: data.orderReferenceId,
    additional_data: data.additionalData,
    locale: data.locale,
  }
  if (data.paymentType === PAY_NOW) {
    requestData.payment_type = data.paymentType
  }
  return request(
    {
      path: `/checkout/in-store`,
      method: 'POST',
      data: requestData,
    },
    {
      options: { hideMessage: true },
    }
  )
}

const cancelInStoreOrder = (data) => {
  return request({
    path: `/checkout/${data.checkoutId}/void`,
    method: 'POST',
    data: {
      order_id: data.orderId,
    },
  })
}

const fetchOrderEventHistories = (data) => {
  return request({
    path: `/merchants/orders/${data.orderId}/histories?merchant_id=${data.merchantId}`,
    method: 'GET',
  })
}

const fetchStores = () => {
  return request({
    path: `/merchants/stores`,
    method: 'GET',
  })
}

const fetchMerchantBranches = (params) => {
  return request({
    path: `/merchants/branches/list?${params}`,
    method: 'GET',
  })
}

const updateOrderReferenceId = (data) => {
  return request({
    path: `/orders/${data.orderId}/reference-id`,
    method: 'PUT',
    data: {
      order_reference_id: data.referenceId,
      merchant_id: data.merchantId,
    },
  })
}

const fetchPaymentPlans = ({ countryCode, amount, currency, phoneNumber }) => {
  return request(
    {
      path: `/payment/types`,
      method: 'GET',
      data: {
        country: countryCode,
        order_value: amount,
        currency,
        phone_number: phoneNumber,
      },
    },
    {
      options: {
        cache: true,
      },
    }
  )
}

export {
  fetchOrderList,
  fetchOrderDetail,
  createInStoreOrder,
  cancelInStoreOrder,
  fetchOrderEventHistories,
  fetchStores,
  updateOrderReferenceId,
  fetchPaymentPlans,
  fetchMerchantBranches,
  createInStoreOrderQr,
}
