<template>
  <div class="order-ids">
    <div v-if="orderId && orderDetail">
      <div v-if="orderDetail.orderReferenceId">
        <div v-if="userCanEditReferenceId" class="order-ids__id-container">
          <div>
            <div class="order-ids__id-container">
              <div
                v-show="refIdEditingActive"
                v-outside="handleClickOutside"
                class="order-ids__inline-edit"
              >
                <el-form ref="referenceForm" :model="referenceForm">
                  <el-form-item
                    prop="referenceId"
                    :rules="rules.referenceId"
                    class="order-ids__input"
                  >
                    <el-input
                      v-model="referenceForm.referenceId"
                      placeholder="Reference Id"
                    />
                    <div
                      v-loading.fullscreen.lock="updatingReferenceId"
                      class="order-ids__save"
                      @click="handleRefIdSubmit('referenceForm')"
                    >
                      <div>
                        {{ $t('order_ids_save') }}
                      </div>
                      <div>
                        <svg-icon icon-class="inline-save" />
                      </div>
                    </div>
                  </el-form-item>
                </el-form>
              </div>
              <div>
                <img
                  v-if="profile.logo"
                  :src="profile.logo"
                  class="order-ids__merchant-logo"
                />
                <svg-icon v-else icon-class="order-id" />
              </div>
              <div>
                {{ !isArabic ? 'ID' : '' }} #{{
                  ellipsisCenter(orderDetail.orderReferenceId)
                }}
              </div>
            </div>
          </div>
          <el-dropdown trigger="click">
            <div class="order-ids__actions-trigger">
              <svg-icon icon-class="dots-id-detail" />
            </div>
            <el-dropdown-menu slot="dropdown" class="order-ids__actions">
              <el-dropdown-item index="2-1">
                <div class="order-ids__actions-item" @click="editIdClicked">
                  <div>
                    <svg-icon icon-class="edit" />
                  </div>
                  <div>
                    {{ $t('order_ids_edit') }}
                  </div>
                </div>
              </el-dropdown-item>
              <el-dropdown-item index="2-2">
                <div
                  class="order-ids__actions-item"
                  @click="copyIdClicked(orderDetail.orderReferenceId)"
                >
                  <div>
                    <svg-icon icon-class="copy" />
                  </div>
                  <div>
                    {{ $t('order_ids_copy') }}
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div v-if="!userCanEditReferenceId" class="order-ids__id-container">
          <div>
            <img
              v-if="profile.logo"
              :src="profile.logo"
              class="order-ids__merchant-logo"
            />
            <svg-icon v-else icon-class="order-id" />
          </div>
          <div class="order-ids__id-container ellipsis">
            {{ !isArabic ? 'ID' : '' }} #
            <CopyText
              v-if="orderDetail.orderReferenceId"
              :value="orderDetail.orderReferenceId"
            />
            <span v-if="!orderDetail.orderReferenceId">
              {{ $t('general_na') }}
            </span>
          </div>
        </div>
      </div>
      <div class="order-ids__outer">
        <div>
          <svg-icon icon-class="tamara-id" />
        </div>
        <div class="order-ids__id-container ellipsis">
          {{ !isArabic ? 'ID' : '' }} #
          <span class="order-ids__copy-icon">
            <CopyText
              v-if="orderId"
              :label="ellipsisCenter(orderId)"
              :value="orderId"
            />
          </span>
          <span v-if="!orderId">
            {{ $t('general_na') }}
          </span>
        </div>
      </div>
    </div>
    <div v-if="disputeDetail">
      <div class="order-ids__outer order-ids__outer--dot-separator">
        <div>
          <svg-icon icon-class="order-reference" />
        </div>
        <div class="order-ids__id-container ellipsis">
          {{ !isArabic ? 'ID' : '' }} #
          <span class="order-ids__copy-icon">
            <CopyText
              v-if="disputeOrderNumber"
              :label="ellipsisCenter(disputeOrderNumber)"
              :value="disputeOrderNumber"
            />
          </span>
          <span v-if="!disputeOrderNumber">
            {{ $t('general_na') }}
          </span>
        </div>
      </div>
      <div class="order-ids__outer">
        <div>
          <svg-icon icon-class="tamara-id" />
        </div>
        <div class="order-ids__id-container ellipsis">
          {{ !isArabic ? 'ID' : '' }} #
          <span class="order-ids__copy-icon">
            <CopyText
              v-if="disputeOrderId"
              :label="ellipsisCenter(disputeOrderId)"
              :value="disputeOrderId"
            />
          </span>
          <span v-if="!disputeOrderId">
            {{ $t('general_na') }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ellipsisCenter } from '@/utils/index.js'
import CopyText from '@/components/CopyText'
import outside from '@/directive/outside/outside.js'
import userHasPermission from '@/utils/user-has-permission'

export default {
  components: {
    CopyText,
  },
  directives: {
    outside,
  },
  data() {
    return {
      ellipsisCenter,
      refIdEditingActive: false,
      updatingReferenceId: false,
      referenceForm: {
        referenceId: '',
      },
      rules: {
        checkEmpty: [
          {
            required: true,
            message: this.$t('general_validation_empty'),
            trigger: 'change',
          },
        ],
        referenceId: [
          {
            required: true,
            message: this.$t('general_required'),
            trigger: 'change',
          },
          {
            validator: (rule, value, callback) => {
              if (value.trim() === '') {
                callback(new Error(this.$t('general_required')))
              } else {
                callback()
              }
            },
            trigger: 'change',
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('dispute', ['getDisputeDetail']),
    ...mapGetters('order', ['getOrderDetail']),
    ...mapGetters('profile', ['profile']),
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    ...mapGetters('translations', ['currentLocale']),
    isArabic() {
      return this.currentLocale === 'ar_SA'
    },
    orderDetail() {
      return this.getOrderDetail(this.orderId)
    },
    orderId() {
      return this.$route.params.orderId
    },
    userCanEditReferenceId() {
      return (
        this.profile &&
        this.profile.inStore &&
        userHasPermission(['order_update_by_ref_id'])
      )
    },
    disputeOrderId() {
      return this.getDisputeDetail(this.$route.params.disputeId)?.data?.orderId
    },
    disputeOrderNumber() {
      return this.getDisputeDetail(this.$route.params.disputeId)?.data
        ?.orderNumber
    },
    disputeDetail() {
      return this.$route.name === 'dispute-detail'
    },
  },
  watch: {
    orderDetail: function(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.referenceForm.referenceId = this.orderDetail.orderReferenceId
      }
    },
  },
  methods: {
    ...mapActions('order', ['updateOrderReferenceId']),
    editIdClicked() {
      this.refIdEditingActive = true
    },
    handleClickOutside() {
      if (!this.refIdEditingActive) {
        return
      }
      this.refIdEditingActive = false
    },
    async copyIdClicked(str) {
      try {
        await navigator.clipboard.writeText(str)
        this.onCopySuccess(str)
      } catch (e) {
        this.onCopyError()
      }
    },
    onCopySuccess(str) {
      this.$message({
        type: 'success',
        message: `Copied ${str} to clipboard`,
      })
    },
    onCopyError() {
      this.$message({
        type: 'error',
        message: this.$t('general_copied_text3'),
      })
    },
    refIdUpdateSuccess() {
      this.$msgbox({
        title: this.$t('order_ids_update_success_title'),
        message: this.$t('order_ids_update_success_description'),
        customClass:
          'global__notification-box global__notification-box--success',
        center: true,
        showClose: false,
        showConfirmButton: false,
        callback: (action) => {
          this.$router.go()
        },
      })
    },
    refIdUpdateFailed(err) {
      const response = err.response || {}
      const { data } = response
      const message =
        (data && data.message) ||
        this.$t('order_ids_update_failure_description')

      if (typeof err === 'string' && (err === 'cancel' || err === 'close')) {
        return
      }
      this.$msgbox({
        title: this.$t('order_ids_update_failure_title'),
        message: message,
        customClass: 'global__notification-box global__notification-box--error',
        center: true,
        showClose: false,
        showConfirmButton: false,
      })
    },
    handleRefIdSubmit(formName) {
      if (this.referenceForm.referenceId.trim() === '') {
        this.$message({
          type: 'error',
          message: this.$t('general_validation_empty'),
        })
        return
      }

      const that = this

      this.$refs[formName].validate(async (valid) => {
        if (valid && this.userCanEditReferenceId) {
          const data = {
            orderId: this.orderId,
            referenceId: this.referenceForm.referenceId,
            merchantId: this.orderDetail.merchantId,
          }

          try {
            that.updatingReferenceId = true
            const res = await that.updateOrderReferenceId(data)

            if (!res) {
              this.refIdUpdateFailed()
              return
            }

            this.refIdUpdateSuccess()

            return true
          } catch (err) {
            this.refIdUpdateFailed(err)
          } finally {
            this.refIdEditingActive = false
            this.updatingReferenceId = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
.order-ids__actions {
  padding: 0;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
}

.order-ids__actions .el-dropdown-menu__item {
  line-height: 40px !important;
}

.order-ids__actions-item {
  display: flex;
  align-items: center;
  min-width: 87px;

  &:last-child {
    border-top: 1px solid #e5e5e5;
  }

  svg {
    [dir='ltr'] & {
      margin-right: 14px;
    }

    [dir='rtl'] & {
      margin-left: 14px;
    }
  }
}
</style>

<style lang="scss" scoped>
.order-ids {
  display: flex;
  justify-content: flex-end;

  svg,
  img {
    max-width: 24px;
    max-height: 24px;
    margin: 0 6px;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  &__outer {
    display: flex;
  }
}

.order-ids__outer--dot-separator {
  position: relative;
  padding-right: 15px;
  margin-right: 15px;

  &::after {
    position: absolute;
    top: calc(50% - 3px);
    right: 0;
    display: block;
    width: 6px;
    height: 6px;
    padding: 2px;
    content: '';
    background: #ccc;
    border-radius: 50%;
  }
}

.order-ids > div {
  display: flex;
  align-items: center;

  &:first-child {
    margin: 0 25px;
  }

  &:last-child {
    margin-right: 0;

    [dir='rtl'] & {
      margin-left: 0;
    }
  }
}

.order-ids__id-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #666;
  text-align: left;
  letter-spacing: 0;
}

.order-ids__id-container > div {
  [dir='rtl'] & {
    direction: rtl;
  }
}

.order-ids__id-container button {
  margin-bottom: -3px;
}

.order-ids__actions-trigger svg {
  height: 16px;
}

.order-ids__inline-edit {
  position: absolute;
  top: -10px;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  min-width: 220px;
  height: fit-content;
  min-height: 40px;
  padding: 0 12px;
  background: white;
  border: 0.5px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

  [dir='ltr'] & {
    left: -20px;
  }

  [dir='rtl'] & {
    right: -20px;
  }
}

.order-ids__inline-edit ::v-deep .el-form {
  width: 100%;
}

.order-ids__inline-edit ::v-deep .el-form-item {
  margin-bottom: 0;
}

.order-ids__inline-edit ::v-deep .el-form-item__content {
  position: relative;
}

.order-ids__save {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  line-height: 1;
  color: #8e64aa;
  cursor: pointer;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 19%
  ) !important;
  background: #fff;

  [dir='ltr'] & {
    right: 0;
    padding: 11px 0 3px 15px;
  }

  [dir='rtl'] & {
    left: 0;
    padding: 11px 15px 3px 0;
  }

  svg {
    max-width: 9px;
    max-height: 9px;

    [dir='ltr'] & {
      margin-left: 6px;
    }

    [dir='rtl'] & {
      margin-right: 6px;
    }
  }
}

.order-ids__input ::v-deep .el-input__inner,
.order-ids__input ::v-deep .el-input__inner:focus {
  height: 20px;
  padding: 3px;
}

.order-ids__input:not(.is-error) ::v-deep .el-input__inner,
.order-ids__input:not(.is-error) ::v-deep .el-input__inner:focus {
  height: 25px;
  background: none;
  border: none;

  [dir='ltr'] & {
    padding-right: 64px;
  }

  [dir='rtl'] & {
    padding-left: 64px;
  }
}

.order-ids__input ::v-deep .el-form-item__error {
  position: initial;
  padding: 3px 0 4px;
  margin-top: -6px;
  font-size: 12px;
}

.order-ids__input .el-input {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.order-ids__input.is-error ::v-deep .el-input__inner,
.order-ids__input.is-error ::v-deep .el-input__inner:focus {
  border-top: none;
  border-right: none;
  border-left: none;
  border-radius: 0;
}

.order-ids__copy-icon ::v-deep .svg-icon {
  margin-bottom: -3px;
}
</style>
