import Cookies from 'js-cookie'

import i18n from '@/i18n'
import { getElMessages } from '@/i18n/locale'
import store from '@/store'
import { parseQueryString } from '@/utils/query-string'

const fetchMessagesTranslations = async (locale) => {
  const messages = await store.dispatch('translations/fetchMessages', locale)
  const tamaraMessages = messages || {}
  const elMessages = getElMessages(locale)
  const messageCompiled = { ...tamaraMessages, ...elMessages }

  i18n.setLocaleMessage(locale, messageCompiled || {})
  i18n.locale = locale
  Cookies.set('tamara-partners-locale', locale)
}

const getDeviceLocale = () => {
  const deviceLocale = String(window.navigator.language)
  // if locale is any ar (ar-SA, ar-AE, ar-EG, etc)
  if (deviceLocale.startsWith('ar')) {
    return 'ar_SA'
  }

  // locale is any en (en-US, en-CA, etc)
  if (deviceLocale.startsWith('en')) {
    return 'en_US'
  }

  // locale language is not supported
  return null
}

const detectCurrentLocale = (routeTo) => {
  const searchParams = parseQueryString(window.location.search)

  const queryLocale = routeTo?.query?.locale || searchParams?.locale || ''
  const storageLocale = Cookies.get('tamara-partners-locale')
  const deviceLocale = getDeviceLocale()
  const defaultLocale = 'en_US' // The default is en
  return queryLocale || storageLocale || deviceLocale || defaultLocale
}

const translate = (key, options = {}) => i18n.t(key, options)

const getLanguageFromLocale = (sourceLocale = '') => {
  const locale = sourceLocale.replace('-', '_')
  const [lang] = locale.split('_')

  return lang || 'en'
}

export {
  detectCurrentLocale,
  translate,
  getDeviceLocale,
  fetchMessagesTranslations,
  getLanguageFromLocale,
}
