import BaseApi from '@/api/base-api'

class MerchantSettingApi extends BaseApi {
  createBarcodeSetting = (merchantId, data) => {
    return this.request({
      method: 'POST',
      path: `${this.uri}/${merchantId}/create-barcode`,
      data,
    })
  }

  updateBarcodeSetting = (merchantId, data) => {
    return this.request({
      method: 'PUT',
      path: `${this.uri}/${merchantId}/update-barcode`,
      data,
    })
  }

  getBarcodeSetting = (merchantId) => {
    return this.request({
      method: 'GET',
      path: `${this.uri}/${merchantId}/get-barcode`,
    })
  }
}

export default new MerchantSettingApi('/merchants/settings')
