import MerchantAddressApi from '@/api/merchant/address'

const state = {
  addresses: [],
  fetchingAddresses: false,
}

const getters = {
  addresses: (state) => {
    return state.addresses
  },
  fetchingAddresses: (state) => {
    return state.fetchingAddresses
  },
}

const mutations = {
  SET_ADDRESSES(state, addresses) {
    state.addresses = addresses
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
}

const actions = {
  async fetchAddresses({ commit, dispatch, state }) {
    try {
      commit('SET_FETCHING', { name: 'fetchingAddresses', value: true })

      const response = await MerchantAddressApi.getAddresses()
      const { data } = response.data
      commit('SET_ADDRESSES', data)
      commit('SET_FETCHING', { name: 'fetchingAddresses', value: false })
      return data
    } catch (error) {
      commit('SET_FETCHING', { name: 'fetchingAddresses', value: false })
    }
  },
  async updateAddress({ commit, dispatch, state }, data) {
    await MerchantAddressApi.updateAddress(data.merchantAccountId, data.payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
