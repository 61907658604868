<template>
  <div
    :class="
      `environment-indicator ${
        layout === 'main' ? 'environment-indicator--fixed' : ''
      }`
    "
  >
    <div
      class="global__alert global__alert--yellow environment-indicator__inner"
    >
      <div>
        {{ $t('test_bar_text1') }}
      </div>
      <el-tooltip :placement="isArabic ? 'left' : 'right'">
        <template slot="content">
          {{ $t('test_bar_text2') }}
        </template>
        <svg-icon icon-class="tooltip-yellow" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'EnvironmentIndicator',
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('translations', ['currentLocale']),
    isArabic() {
      return this.currentLocale === 'ar_SA'
    },
  },
}
</script>

<style lang="scss" scoped>
.environment-indicator {
  position: relative;
  z-index: 1000;
  width: 100%;
}
.environment-indicator--fixed {
  position: sticky;
}
.environment-indicator__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: rgba(195, 128, 0, 1);
  background-color: #fdf6e8;
  border-radius: 0;

  > div:first-child {
    [dir='ltr'] & {
      margin-right: 10px;
    }

    [dir='rtl'] & {
      margin-left: 10px;
    }
  }
}
</style>

<style lang="scss">
@import '@/styles/_breakpoints';

// global styles overrides for when the environment indicator is present
.has-environment-indicator {
  .content-container {
    border-right: 10px solid #fdf6e8;
    border-bottom: 10px solid #fdf6e8;
    border-left: 10px solid #fdf6e8;
  }

  .login-container,
  .onboarding {
    border-right: 10px solid #fdf6e8;
    border-bottom: 10px solid #fdf6e8;
    border-left: 10px solid #fdf6e8;
  }

  .onboarding__first {
    // workaround fixes for onboarding pages
    min-height: 110vh;
  }
  .onboarding__success {
    height: 117vh !important;
  }

  .login-container__second,
  .onboarding__second {
    bottom: 10px !important;
    z-index: 1;
    [dir='ltr'] & {
      right: 10px !important;
    }
    [dir='rtl'] & {
      left: 10px !important;
    }
  }
}
</style>
