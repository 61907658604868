<template>
  <div class="app-footer">
    <div v-if="isAdsDashboard" class="flex gap-8 h-full">
      <a
        href="https://tamara.co/docs/Ads_Terms&Conditions.pdf"
        target="_blank"
        class="footer-link"
      >
        {{ $t('ads_terms_conditions') }}
      </a>
      <a
        href="https://tamara.co/docs/Ads_Promotion_Terms.pdf"
        target="_blank"
        class="footer-link"
      >
        {{ $t('ads_promotion_terms') }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    isAdsDashboard() {
      return this.$route.path.includes('/ads/dashboard')
    },
  },
}
</script>

<style lang="scss" scoped>
.app-footer {
  padding: 12px 0 32px 40px;
  .footer-link {
    @apply underline text-sm;
  }
}
</style>
