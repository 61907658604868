import crypto from 'crypto'
import {
  fetchDisputeList,
  fetchDisputeDetail,
  exportDisputeList,
  fetchMerchantBranches,
  uploadDisputeFile,
} from '@/api/dispute'
import MerchantDisputeApi from '@/api/merchant/disputes'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  list: [],
  actionRequiredList: [],
  detail: {},
  merchantBranches: [],
  paymentPlans: [{ name: 'PAY_BY_INSTALMENTS' }],
  disputeId: '',
  page: 1,
  limit: 25,
  queryHash: '',
  lastError: null,
  updatingStatus: false, // Trigger when merchant update status manually
  fetchingList: false,
  disputesTotalRecords: 0,
  actionRequiredCount: 0,
  staticOpenCasesCount: 0,
  fetchingDetail: false,
  downloadingDispute: false,
  fetchingMerchantBranches: false,
}

const mutations = {
  ADD_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = data.data
  },
  ADD_ACTION_REQUIRED_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.actionRequiredList = data.data
  },
  REMOVE_LIST(state) {
    state.page = 1
    state.list = []
  },
  ADD_DISPUTE_DETAIL(state, { disputeId, detail }) {
    state.detail = { ...state.detail, [disputeId]: detail }
  },
  ADD_ORDER_EVENT_HISTORIES(state, { orderId, histories }) {
    state.eventHistories = { ...state.eventHistories, [orderId]: histories }
  },
  ADD_MERCHANT_BRANCHES(state, { merchantBranches }) {
    state.merchantBranches = merchantBranches
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.disputesTotalRecords = newValue
  },
  SET_ACTION_REQUIRED_RECORDS(state, newValue) {
    state.actionRequiredCount = newValue
  },
  SET_STATIC_OPEN_CASES_RECORDS(state, newValue) {
    state.staticOpenCasesCount = newValue
  },
  SET_OPEN_CASES_RECORDS(state, newValue) {
    state.openCasesCount = newValue
  },
  SET_CLOSE_CASES_RECORDS(state, newValue) {
    state.closedCasesCount = newValue
  },
  SET_CURRENT_PAGE(state, newValue) {
    state.page = newValue
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
  ADD_PAYMENT_PLANS(state, { paymentPlans }) {
    state.paymentPlans = paymentPlans
  },
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getActionRequiredList: (state) => {
    return state.actionRequiredList
  },
  getDisputesTotalRecords: (state) => {
    return state.disputesTotalRecords
  },
  getDisputeDetail: (state) => (disputeId) => {
    return state.detail[disputeId] || {}
  },
  getMerchantBranches: (state) => {
    return state.merchantBranches
  },
  getPaymentPlans: (state) => {
    return state.paymentPlans
  },
  getActionRequiredCount: (state) => {
    return state.actionRequiredCount
  },
  getStaticOpenedCount: (state) => {
    return state.staticOpenCasesCount
  },
}

const actions = {
  async fetchDisputes({ commit, dispatch, state }, params) {
    try {
      const { page, limit, ...query } = params

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        commit('REMOVE_LIST')
      }

      commit('SET_FETCHING', { name: 'fetchingList', value: true })

      if (query.merchantIds) {
        query.merchantIds = Array.isArray(query.merchantIds)
          ? query.merchantIds
          : [query.merchantIds]
      }

      if (query.statuses) {
        query.statuses = Array.isArray(query.statuses)
          ? query.statuses
          : [query.statuses]
      }

      const { data } = await fetchDisputeList({ page, limit, ...query })

      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('SET_ACTION_REQUIRED_RECORDS', data.actionRequired)
      commit('SET_STATIC_OPEN_CASES_RECORDS', data.staticOpenedCases)
      commit('ADD_LIST', { data, page })
      commit('SET_CURRENT_PAGE', page)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },
  async fetchActionRequiredList({ commit, dispatch, state }, params) {
    try {
      const { page, limit, ...query } = params

      const { data } = await fetchDisputeList({ page, limit, ...query })

      commit('SET_ACTION_REQUIRED_RECORDS', data.actionRequired)
      commit('SET_STATIC_OPEN_CASES_RECORDS', data.staticOpenedCases)
      commit('ADD_ACTION_REQUIRED_LIST', { data, page })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async exportDisputes({ commit, dispatch, state }, params) {
    const { ...query } = params

    if (query.merchantIds) {
      query.merchantIds = Array.isArray(query.merchantIds)
        ? query.merchantIds
        : [query.merchantIds]
    }

    if (query.statuses) {
      query.statuses = Array.isArray(query.statuses)
        ? query.statuses
        : [query.statuses]
    }

    try {
      commit('SET_FETCHING', { name: 'downloadingDisputes', value: true })

      const response = await exportDisputeList(query)

      commit('SET_FETCHING', { name: 'downloadingDisputes', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadingDisputes', value: false })

      throw error
    }
  },

  async fetchDisputeDetail({ commit }, data) {
    const disputeId = data.disputeId
    try {
      commit('SET_FETCHING', { name: 'fetchingDetail', value: true })

      const response = await fetchDisputeDetail(data)
      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
      commit('ADD_DISPUTE_DETAIL', { disputeId, detail: response.data })
      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async fetchMerchantBranches({ commit }, merchantIds) {
    try {
      commit('SET_FETCHING', { name: 'fetchingMerchantBranches', value: true })

      if (
        !merchantIds ||
        !Array.isArray(merchantIds) ||
        merchantIds.length === 0
      )
        return
      const params = merchantIds.map((id) => `merchantIds[]=${id}`).join('&')

      const response = await fetchMerchantBranches(params)

      commit('SET_FETCHING', { name: 'fetchingMerchantBranches', value: false })
      commit('ADD_MERCHANT_BRANCHES', { merchantBranches: response.data.data })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async processRefund(
    { commit },
    { disputeId, merchantId, orderId, amount, comment }
  ) {
    try {
      return await MerchantDisputeApi.refundDispute(orderId, {
        totalAmount: amount,
        comment: comment,
        merchantId: merchantId,
        disputeId: disputeId,
      })
    } catch (error) {
      commit('SET_ERROR', error)
      return null
    }
  },
  async submitResponse({ commit }, data) {
    try {
      const response = await MerchantDisputeApi.submitResponse(data)
      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },
  uploadDisputeFile: async ({ commit }, { id, formData, reCaptchaToken }) => {
    try {
      return await uploadDisputeFile(id, formData, reCaptchaToken)
    } catch (error) {
      commit('SET_ERROR', error)
      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
