import Vue from 'vue'

import Cookies from 'js-cookie'
import Element from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import { VueReCaptcha } from 'vue-recaptcha-v3'

import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import VueApexCharts from 'vue-apexcharts'
import FlagIcon from 'vue-flag-icon'
import App from './App'
import store from './store'
import router from './router'
import i18n from './i18n'
import Config from './config'

import './assets/icons' // icon
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters'
import { initAdaBot } from '@/plugins/ada'
import { detectCurrentLocale, getLanguageFromLocale } from '@/utils/i18n'

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
})
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(FlagIcon)

// configure language for Element-UI components
locale.use(lang)

// Enable Google reCAPTCHA
if (process.env.VUE_APP_RECAPTCHA_SITEKEY) {
  // Config Google's reCAPTCHA v3
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY,
    autoHideBadge: true,
  })
}

// register global utility filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

const currentLocale = detectCurrentLocale(router?.currentRoute)
const detectedLang = getLanguageFromLocale(currentLocale)

// Init chatbot & tracking here
initAdaBot({ language: detectedLang })

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  i18n,
  created: () => {
    document.documentElement.setAttribute(
      'dir',
      store.getters['translations/currentDirection']
    )
  },
  render: (h) => h(App),
})

window.__version__ = Config.env + '.' + Config.releaseVersion
