import { isObject } from '@/utils/validate'
import MerchantSettingApi from '@/api/merchant/setting'

const state = {
  settings: {},
  creating: false,
  fetching: false,
  updating: false,
}

const getters = {
  hasSettings: (state) => {
    const { settings } = state

    return isObject(settings) && Object.keys(settings).length > 0
  },
}

const mutations = {
  SET_STATUS: (state, { key, value }) => {
    state[key] = value
  },
  SET_BARCODE_SETTING: (state, settings) => {
    state.settings = settings
  },
}

const actions = {
  async getBarcodeSetting({ commit }, { merchantId }) {
    try {
      commit('SET_STATUS', { key: 'fetching', value: true })

      const { data } = await MerchantSettingApi.getBarcodeSetting(merchantId)

      if (isObject(data)) {
        commit('SET_BARCODE_SETTING', data)
      }

      commit('SET_STATUS', { key: 'fetching', value: false })
    } catch (e) {
      commit('SET_STATUS', { key: 'fetching', value: false })

      throw e
    }
  },
  async createBarcodeSetting({ commit }, { merchantId, settings }) {
    try {
      commit('SET_STATUS', { key: 'creating', value: true })

      await MerchantSettingApi.createBarcodeSetting(merchantId, { settings })

      commit('SET_STATUS', { key: 'creating', value: false })
    } catch (e) {
      commit('SET_STATUS', { key: 'creating', value: false })

      throw e
    }
  },

  async updateBarcodeSetting({ commit }, { merchantId, settings }) {
    try {
      commit('SET_STATUS', { key: 'updating', value: true })

      await MerchantSettingApi.updateBarcodeSetting(merchantId, { settings })

      commit('SET_STATUS', { key: 'updating', value: false })
    } catch (e) {
      commit('SET_STATUS', { key: 'updating', value: false })

      throw e
    }
  },
}

export default { namespaced: true, state, getters, mutations, actions }
