import BaseApi from '@/api/base-api'

class MerchantTransactionApi extends BaseApi {
  downloadTransactions = (data) => {
    return this.request(
      {
        timeout: 100000, // ms
        method: 'GET',
        path: '/merchants/orders/transaction/report',
        data: data,
      },
      {
        options: {
          hideMessage: true,
          camelizeRes: false,
        },
      }
    )
  }
}

export default new MerchantTransactionApi('/merchants/transactions')
