import Vue from 'vue'
import VueI18n from 'vue-i18n'
import enMessages from '@/locales/en_US.json'
import arMessages from '@/locales/ar_SA.json'
import { detectCurrentLocale } from '@/utils/i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: detectCurrentLocale || 'en_US', // set default locale
  fallbackLocale: 'en_US',
  messages: {
    en_US: enMessages,
    ar_SA: arMessages,
  }, // locale is empty and will be fetched later
})

export default i18n
