import crypto from 'crypto'
import { fetchTransactionList } from '@/api/transaction'
import MerchantTransactionApi from '@/api/merchant/transactions'

const SHA256 = (pattern) =>
  String(
    crypto
      .createHash('sha256')
      .update(pattern)
      .digest('hex')
  )

const state = {
  list: [],
  detail: {},
  eventHistories: {},
  transactionId: '',
  page: 1,
  limit: 25,
  queryHash: '',
  transactionTotalRecords: 0,
  approvedTransactionRecords: 0,
  authorisedTransactionRecords: 0,
  lastError: undefined,
  updatingStatus: false, // Trigger when merchant update status manually
  fetchingList: false,
  fetchingDetail: false,
  fetchingEventHistories: false,
  downloadingTransaction: false,
}

const mutations = {
  ADD_LIST(state, { data, page }) {
    state.page = Math.max(page, 1)

    state.list = data.data
  },
  REMOVE_LIST(state) {
    state.page = 1
    state.list = []
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.transactionTotalRecords = newValue
  },
  SET_APPROVED_ORDERS_RECORDS(state, newValue) {
    state.approvedTransactionRecords = newValue
  },
  SET_AUTHORISED_ORDERS_RECORDS(state, newValue) {
    state.authorisedTransactionRecords = newValue
  },
  SET_CURRENT_PAGE(state, newValue) {
    state.page = newValue
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  SET_QUERY_HASH(state, hash) {
    state.queryHash = hash
  },
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getTransactionTotalRecords: (state) => {
    return state.transactionTotalRecords
  },
}

const actions = {
  async fetchTransaction({ commit, dispatch, state }, params) {
    try {
      const { page, limit, ...query } = params

      if (query['settlementStatus[eq]'] === 'not_settled') {
        // XXX dirty hack because the db value for settlement status is 'settled' or empty
        // But empty string, will be removed once pushed to vue-router
        // We have to patching its special value here
        query['settlementStatus[eq]'] = ''
      }

      const queryHash = SHA256(JSON.stringify(query))

      if (state.queryHash !== queryHash) {
        commit('SET_QUERY_HASH', queryHash)
        commit('REMOVE_LIST')
      }

      commit('SET_FETCHING', { name: 'fetchingList', value: true })

      if (query.merchantIds) {
        query.merchantIds = Array.isArray(query.merchantIds)
          ? query.merchantIds
          : [query.merchantIds]
      }

      const { data } = await fetchTransactionList({ page, limit, ...query })

      commit('SET_FETCHING', { name: 'fetchingList', value: false })
      commit('ADD_LIST', { data, page })
      commit('SET_CURRENT_PAGE', page)
      commit('SET_TOTAL_RECORDS', data.total)
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    }
  },
  async fetchTransactionTotalRecords({ commit }) {
    try {
      const approvedTransaction = await fetchTransactionList({
        'status[eq]': 'approved',
        page: 1,
        limit: 1,
      })
      const approvedTransactionRecords =
        approvedTransaction.data.data.length > 0
          ? approvedTransaction.data.total
          : 0
      commit('SET_APPROVED_ORDERS_RECORDS', approvedTransactionRecords)

      const authorisedTransaction = await fetchTransactionList({
        'status[eq]': 'authorised',
        page: 1,
        limit: 1,
      })
      const authorisedTransactionRecords =
        authorisedTransaction.data.data.length > 0
          ? authorisedTransaction.data.total
          : 0
      commit('SET_AUTHORISED_ORDERS_RECORDS', authorisedTransactionRecords)
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },
  async downloadTransactions({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'downloadingTransaction', value: true })

      if (data['settlementStatus[eq]'] === 'not_settled') {
        // hack because the db value for settlement status is 'settled' or empty
        data['settlementStatus[eq]'] = ''
      }

      const response = await MerchantTransactionApi.downloadTransactions(data)

      commit('SET_FETCHING', { name: 'downloadingTransaction', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadingTransaction', value: false })

      throw error
    }
  },
}
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
