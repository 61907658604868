<template>
  <div
    id="app"
    :class="!isProduction ? 'has-environment-indicator' : ''"
    :dir="currentDirection"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Config from '@/config'
import { changeAdaBotLanguage } from '@/plugins/ada'

export default {
  name: 'App',
  computed: {
    ...mapGetters('translations', ['currentDirection', 'currentLang']),
    isProduction() {
      return Config.isProduction
    },
  },
  watch: {
    currentLang(newValue) {
      changeAdaBotLanguage(newValue)
    },
    currentDirection(newValue) {
      document.documentElement.setAttribute('dir', `${newValue}`)
    },
  },
}
</script>
