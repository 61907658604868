<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'RatingDialog',
  props: {
    ratingDialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: this.ratingDialogVisible,
      selectedRating: null,
      comment: null,
      commentMaxLength: 1000,
      range: Array.from(Array(11).keys()),
      success: false,
    }
  },
  computed: {
    ...mapGetters('userProfile', ['profile']),
    ...mapGetters('rating', ['loading', 'lastRating']),
  },
  methods: {
    ...mapActions('rating', ['saveRating', 'fetchRating']),
    selectRating(value) {
      this.selectedRating = value
    },
    async submitRating() {
      await this.$recaptchaLoaded()
      const reCaptchaToken = await this.$recaptcha('submitRating')
      await this.saveRating({
        rating: this.selectedRating,
        comment: this.comment,
        reCaptchaToken,
      })
      this.success = true
      setTimeout(this.closeDialog, 3000)
    },
    closeDialog() {
      this.dialogVisible = false
      this.$emit('closeDialog')
    },
  },
}
</script>

<template>
  <Transition v-if="dialogVisible" name="nps-rating__transition">
    <div class="nps-rating__dialog">
      <template v-if="!success">
        <el-button class="nps-rating__close" @click="closeDialog">
          <svg-icon icon-class="close" />
        </el-button>
        <h2>{{ $t('nps_rating_title') }}</h2>
        <div class="nps-rating__values">
          <el-button
            v-for="value in range"
            :key="value"
            :class="
              `nps-rating__rate ${value === selectedRating ? 'active' : ''}`
            "
            @click="selectRating(value)"
          >
            {{ value }}
          </el-button>
        </div>
        <div class="nps-rating__labels flex justify-between">
          <span>{{ $t('nps_rating_label_unlikely') }}</span>
          <span>{{ $t('nps_rating_label_likely') }}</span>
        </div>
        <div v-if="selectedRating !== null" class="nps-rating__comment">
          <el-input
            v-model="comment"
            :maxlength="commentMaxLength"
            :placeholder="$t('nps_rating_comment_placeholder')"
            type="textarea"
          />
          <div class="nps_rating__comment-limit">
            {{ comment !== null ? comment.length : 0 }}/{{ commentMaxLength }}
          </div>
        </div>
        <el-button
          v-if="selectedRating !== null"
          class="nps-rating__submit"
          :loading="loading"
          @click="submitRating"
        >
          {{ $t('nps_rating_submit') }}
        </el-button>
      </template>
      <template v-if="success">
        <h2>{{ $t('nps_rating_success_title') }}</h2>
        <p>{{ $t('nps_rating_success_message') }}</p>
      </template>
    </div>
  </Transition>
</template>
<style lang="scss" scoped>
.nps-rating__transition-leave-active {
  bottom: 0;
  transform: translateY(100%);
}

.nps-rating__dialog {
  position: fixed;
  right: 0;
  bottom: 48px;
  left: 0;
  z-index: 2006; // The filter overlay has 2005
  width: 90%;
  max-width: 624px;
  padding: 32px;
  margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 4px 54px 0 rgba(0, 0, 0, 0.06);
  transition: all 200ms ease-in;

  .nps-rating__close {
    position: absolute;
    top: 21px;
    right: 21px;
    padding: 0;
    border: none;

    &:hover,
    &:focus {
      background: transparent;

      svg {
        filter: none;
      }
    }

    svg {
      width: 10px;
      height: 10px;
    }
  }

  p {
    color: #666;
    text-align: center;
  }

  h2 {
    margin-bottom: 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    text-align: center;

    [dir='ltr'] & {
      font-family: 'Space Grotesk', sans-serif;
    }

    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
    }
  }

  .nps-rating__comment {
    padding-top: 28px;
    margin-top: 28px;
    border-top: 1px solid #f2f2f2;

    ::v-deep .el-textarea textarea {
      height: 120px;
      padding: 16px;
      resize: none;
    }
  }

  .nps_rating__comment-limit {
    margin-top: 8px;
    font-size: 12px;
    color: #666;
    text-align: right;
  }

  .nps-rating__rate {
    bottom: 0;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0 12px 12px 0;
    line-height: 40px;
    color: #000;
    text-align: center;
    border: 1px solid #ab8dffb2;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: #000;
      background: transparent;
      border: 1px solid #ab8dffb2;
    }

    &.active {
      color: #fff;
      background: #ab8dffb2;
    }
  }

  .nps-rating__labels span {
    font-size: 12px;
    line-height: 19px;
    color: #666;
  }

  .nps-rating__submit {
    display: block;
    width: 100%;
    padding: 12px;
    margin-top: 28px;
    line-height: 20px;
    color: #fff;
    background: #000;
    border: none;
  }
}
</style>
