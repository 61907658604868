import { countryData } from '@/constants/country-data'

const getAmountValue = (amountObject) => {
  if (!amountObject) {
    return 0
  }

  return amountObject.amount || 0
}

const getAmountCurrency = (amountObject) => {
  if (!amountObject) {
    return 0
  }

  return amountObject.currency || 'SAR'
}

const getDecimalDigitsByCurrency = (currency) => {
  const foundConfig = countryData?.find(
    (country) => country.currency === currency
  )

  return foundConfig?.decimalDigits || 2
}
const abbreviate = (value, isArabic) => {
  if (!value) return 0

  // shorten the number and remove the redundent trailing zeros if it's less than 1000
  if (value < 1000) return parseFloat(value.toFixed(2))

  // if it's more than 1000 the third party library will take care of this
  const returnValue = isArabic
    ? Intl.NumberFormat('en-SA', {
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
    : Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        notation: 'compact',
        compactDisplay: 'short',
      }).format(value)
  // Check if thousand number will show as k instead of K
  return value < 999999 ? returnValue?.toLowerCase() : returnValue
}

export {
  getAmountCurrency,
  getAmountValue,
  getDecimalDigitsByCurrency,
  abbreviate,
}
