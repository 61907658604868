<template>
  <div v-if="!item.hidden && userCanView(item)">
    <template
      v-if="
        hasOneShowingChild(item.children, item) &&
          (!onlyOneChild.children || onlyOneChild.noShowingChildren) &&
          !item.alwaysShow
      "
    >
      <div
        v-if="showLineItem.includes(onlyOneChild.meta.label)"
        class="sidebar-line"
      />
      <AppLink v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
        <el-menu-item
          :index="resolvePath(onlyOneChild.path)"
          :class="{
            'sidebar__with-dropdown': !isNest,
            'sidebar__without-dropdown': isNest,
            'sidebar__without-dropdown--less-height':
              isNest && isKycRoute(item),
            'sidebar__contact-item':
              onlyOneChild.meta.label === 'tab_kyc-contact',
          }"
        >
          <span
            v-if="onlyOneChild.meta.icon || (item.meta && item.meta.icon)"
            class="svg-off"
          >
            <svg-icon
              class="sidebar__main-item-icon"
              :icon-class="
                onlyOneChild.meta.icon || (item.meta && item.meta.icon)
              "
            />
          </span>
          <span
            v-if="
              onlyOneChild.meta.iconActive ||
                (item.meta && item.meta.iconActive)
            "
            class="svg-on"
          >
            <svg-icon
              class="sidebar__main-item-icon"
              :icon-class="
                onlyOneChild.meta.iconActive ||
                  (item.meta && item.meta.iconActive)
              "
            />
          </span>
          <span
            v-if="onlyOneChild.name === 'kyc-business'"
            :class="{
              'sidebar__kyc-icon': true,
              'sidebar__kyc-icon--number': kycStatusLabel.business.isFilled,
            }"
          >
            <svg-icon
              v-if="kycStatusLabel.business.isFilled"
              :icon-class="kycStatusLabel.business.icon"
            />
            <span
              v-if="!kycStatusLabel.business.isFilled"
              class="sidebar__kyc-step"
            >
              {{ kycStatusLabel.business.stepNumber }}
            </span>
          </span>

          <span
            v-if="onlyOneChild.name === 'kyc-legal'"
            :class="{
              'sidebar__kyc-icon': true,
              'sidebar__kyc-icon--number': kycStatusLabel.legal.isFilled,
            }"
          >
            <svg-icon
              v-if="kycStatusLabel.legal.isFilled"
              :icon-class="kycStatusLabel.legal.icon"
            />
            <span
              v-if="!kycStatusLabel.legal.isFilled"
              class="sidebar__kyc-step"
            >
              {{ kycStatusLabel.legal.stepNumber }}
            </span>
          </span>
          <span
            v-if="onlyOneChild.name === 'kyc-bank'"
            :class="{
              'sidebar__kyc-icon': true,
              'sidebar__kyc-icon--number': kycStatusLabel.bank.isFilled,
            }"
          >
            <svg-icon
              v-if="kycStatusLabel.bank.isFilled"
              :icon-class="kycStatusLabel.bank.icon"
            />
            <span
              v-if="!kycStatusLabel.bank.isFilled"
              class="sidebar__kyc-step"
            >
              {{ kycStatusLabel.bank.stepNumber }}
            </span>
          </span>
          <span
            v-if="onlyOneChild.name === 'kyc-additional-info'"
            :class="{
              'sidebar__kyc-icon': true,
              'sidebar__kyc-icon--number':
                kycStatusLabel.additional_info.isFilled,
            }"
          >
            <svg-icon
              v-if="kycStatusLabel.additional_info.isFilled"
              :icon-class="kycStatusLabel.additional_info.icon"
            />
            <span
              v-if="!kycStatusLabel.additional_info.isFilled"
              class="sidebar__kyc-step"
            >
              {{ kycStatusLabel.additional_info.stepNumber }}
            </span>
          </span>
          <span v-if="onlyOneChild.meta.label" class="sidebar__item-label">
            {{ $t(onlyOneChild.meta.label) }}
          </span>
          <span
            v-if="onlyOneChild.name === 'order-list-to-capture'"
            class="sidebar-item__count sidebar-item__count--capture"
          >
            {{ authorisedOrdersRecords }}
          </span>
          <span
            v-if="onlyOneChild.name === 'order-list-to-authorize'"
            class="sidebar-item__count sidebar-item__count--authorise"
          >
            {{ approvedOrdersRecords }}
          </span>
          <span
            v-if="onlyOneChild.name === 'dispute-list-action-required'"
            class="sidebar-item__count sidebar-item__count--action-required"
          >
            {{ actionRequiredCount }}
          </span>
          <span
            v-if="onlyOneChild.name === 'dispute-list-opened-cases'"
            class="sidebar-item__count sidebar-item__count--open-cases"
          >
            {{ staticOpenCasesCount }}
          </span>
          <span v-if="item.meta && item.meta.isNew" class="sidebar-item__new">
            {{ $t('roles_list_new') }}
          </span>
        </el-menu-item>
      </AppLink>
    </template>

    <el-submenu
      v-else
      ref="subMenu"
      :index="resolvePath(item.path)"
      popper-append-to-body
    >
      <template slot="title">
        <span v-if="item.meta && item.meta.icon" class="svg-off">
          <svg-icon
            class="sidebar__main-item-icon"
            :icon-class="item.meta.icon"
          />
        </span>
        <span v-if="item.meta && item.meta.iconActive" class="svg-on">
          <svg-icon
            class="sidebar__main-item-icon"
            :icon-class="item.meta.iconActive"
          />
        </span>
        <span v-if="headerLabel">
          {{ headerLabel }}
        </span>
        <span
          v-if="item.name === 'dispute'"
          class="sidebar-item__count sidebar-item__count--action-required"
          style="margin-inline: 8px;"
        >
          {{ actionRequiredCount }}
        </span>
        <span v-if="item.meta && item.meta.isNew" class="sidebar-item__new">
          {{ $t('roles_list_new') }}
        </span>
        <i
          class="tamara-submenu__icon-arrow el-submenu__icon-arrow el-icon-arrow-down"
          @click.stop="handleArrowClick(resolvePath(item.path), $event)"
        />
      </template>
      <div
        v-if="kycStatusLabel.business.isFilled && isKycRoute(item)"
        class="sidebar__active-line-business"
      />
      <div
        v-if="kycStatusLabel.legal.isFilled && isKycRoute(item)"
        class="sidebar__active-line-legal"
      />
      <div
        v-if="kycStatusLabel.bank.isFilled && isKycRoute(item)"
        class="sidebar__active-line-bank"
      />
      <div
        v-if="kycStatusLabel.additional_info.isFilled && isKycRoute(item)"
        class="sidebar__active-line-info"
      />
      <sidebar-item
        v-for="child in item.children"
        :key="child.path + child.name"
        :is-nest="true"
        :item="child"
        :base-path="resolvePath(child.path)"
        class="nest-menu"
      />
    </el-submenu>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import kyc from '@/store/modules/kyc'
import ResolvePathMixin from '@/mixins/resolve-path'
import AppLink from '@/components/SidebarUtils/Link'
import FixiOSBug from '@/components/SidebarUtils/FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { AppLink },
  mixins: [FixiOSBug, ResolvePathMixin],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
    supportUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {
      showLineItem: [
        'tab_kyc-business',
        'tab_kyc-legal',
        'tab_kyc-bank',
        'tab-kyc-additional-info',
      ],
    }
  },
  computed: {
    kyc() {
      return kyc
    },
    ...mapState('order', ['approvedOrdersRecords', 'authorisedOrdersRecords']),
    ...mapState('dispute', ['actionRequiredCount', 'staticOpenCasesCount']),
    ...mapGetters('kyc', ['onboardingData']),
    ...mapGetters('profile', { merchantProfile: 'profile' }),
    headerLabel() {
      const { label } = this.item.meta || {}

      return this.$te(label) ? this.$t(label) : label
    },
    kycStatusLabel() {
      const KYC_STATUS_COMPLETED_LABEL_ICON = 'kyc-checkmark-green'
      const KYC_STATUS_NOT_FILLED_LABEL_ICON = 'close'
      const KYC_STATUS_REOPEN_LABEL_ICON = 'kyc-update'
      const KYC_STATUS_COMPLETED_ICON = 'check-green-small'
      const KYC_STATUS_REOPEN_ICON = 'kyc-action-required'
      const KYC_STATUS_COMPLETED_CLASS = 'sidebar-item__status--green'
      const KYC_STATUS_REOPEN_CLASS = 'sidebar-item__status--reopened'
      const KYC_STATUS_NOT_FILLED_CLASS = 'sidebar-item__status--grey'
      const KYC_STATUS_REOPEN_TEXT = this.$t('sidebar_item_tag_reopen')
      const KYC_STATUS_NOT_FILLED_TEXT = this.$t('sidebar_item_tag_not_filled')
      const KYC_STATUS_COMPLETED_TEXT = this.$t('sidebar_item_tag_approved')

      const KYC_STATUS_LABELS = {
        business: {
          icon: '',
          labelIcon: KYC_STATUS_NOT_FILLED_LABEL_ICON,
          isFilled: true,
          stepNumber: 1,
          status: KYC_STATUS_NOT_FILLED_TEXT,
          className: KYC_STATUS_NOT_FILLED_CLASS,
        },
        legal: {
          icon: '',
          labelIcon: KYC_STATUS_NOT_FILLED_LABEL_ICON,
          isFilled: false,
          stepNumber: 2,
          status: KYC_STATUS_NOT_FILLED_TEXT,
          className: KYC_STATUS_NOT_FILLED_CLASS,
        },
        bank: {
          icon: '',
          labelIcon: KYC_STATUS_NOT_FILLED_LABEL_ICON,
          isFilled: false,
          stepNumber: 3,
          status: KYC_STATUS_NOT_FILLED_TEXT,
          className: KYC_STATUS_NOT_FILLED_CLASS,
        },
        additional_info: {
          icon: '',
          labelIcon: KYC_STATUS_NOT_FILLED_LABEL_ICON,
          isFilled: false,
          stepNumber: 4,
          status: KYC_STATUS_NOT_FILLED_TEXT,
          className: KYC_STATUS_NOT_FILLED_CLASS,
        },
      }

      if (
        this.onboardingData?.kycBusiness?.recollectFields.length === 0 ||
        !this.onboardingData?.kycBusiness?.recollectFields
      ) {
        KYC_STATUS_LABELS.business.icon = KYC_STATUS_COMPLETED_ICON
        KYC_STATUS_LABELS.business.labelIcon = KYC_STATUS_COMPLETED_LABEL_ICON
        KYC_STATUS_LABELS.business.status = KYC_STATUS_COMPLETED_TEXT
        KYC_STATUS_LABELS.business.className = KYC_STATUS_COMPLETED_CLASS
      }

      if (
        this.onboardingData.bankKyc &&
        this.onboardingData.bankKyc.recollectFields.length === 0
      ) {
        KYC_STATUS_LABELS.bank.icon = KYC_STATUS_COMPLETED_ICON
        KYC_STATUS_LABELS.bank.isFilled = true
        KYC_STATUS_LABELS.bank.labelIcon = KYC_STATUS_COMPLETED_LABEL_ICON
        KYC_STATUS_LABELS.bank.status = KYC_STATUS_COMPLETED_TEXT
        KYC_STATUS_LABELS.bank.className = KYC_STATUS_COMPLETED_CLASS
      }

      if (
        this.onboardingData.bankKyc &&
        this.onboardingData.bankKyc.recollectFields.length !== 0
      ) {
        KYC_STATUS_LABELS.bank.icon = KYC_STATUS_REOPEN_ICON
        KYC_STATUS_LABELS.bank.isFilled = true
        KYC_STATUS_LABELS.bank.labelIcon = KYC_STATUS_REOPEN_LABEL_ICON
        KYC_STATUS_LABELS.bank.status = KYC_STATUS_REOPEN_TEXT
        KYC_STATUS_LABELS.bank.className = KYC_STATUS_REOPEN_CLASS
      }

      if (!this.onboardingData.bankKyc) {
        KYC_STATUS_LABELS.bank.labelIcon = KYC_STATUS_NOT_FILLED_LABEL_ICON
        KYC_STATUS_LABELS.bank.status = KYC_STATUS_NOT_FILLED_TEXT
        KYC_STATUS_LABELS.bank.className = KYC_STATUS_NOT_FILLED_CLASS
        KYC_STATUS_LABELS.bank.isFilled = false
      }

      if (
        (this.isAutomatedLegalOnboarding(this.onboardingData) &&
          this.automatedLegalOnboardingCompleted(this.onboardingData)) ||
        (!this.isAutomatedLegalOnboarding(this.onboardingData) &&
          this.manualLegalOnboardingCompleted(this.onboardingData))
      ) {
        KYC_STATUS_LABELS.legal.icon = KYC_STATUS_COMPLETED_ICON
        KYC_STATUS_LABELS.legal.isFilled = true
        KYC_STATUS_LABELS.legal.labelIcon = KYC_STATUS_COMPLETED_LABEL_ICON
        KYC_STATUS_LABELS.legal.status = KYC_STATUS_COMPLETED_TEXT
        KYC_STATUS_LABELS.legal.className = KYC_STATUS_COMPLETED_CLASS
      }

      if (
        this.onboardingData.legalKyc &&
        this.onboardingData.legalKyc.recollectFields.length !== 0
      ) {
        KYC_STATUS_LABELS.legal.icon = KYC_STATUS_REOPEN_ICON
        KYC_STATUS_LABELS.legal.isFilled = true
        KYC_STATUS_LABELS.legal.labelIcon = KYC_STATUS_REOPEN_LABEL_ICON
        KYC_STATUS_LABELS.legal.status = KYC_STATUS_REOPEN_TEXT
        KYC_STATUS_LABELS.legal.className = KYC_STATUS_REOPEN_CLASS
      }

      if (!this.onboardingData.legalKyc) {
        KYC_STATUS_LABELS.legal.labelIcon = KYC_STATUS_NOT_FILLED_LABEL_ICON
        KYC_STATUS_LABELS.legal.status = KYC_STATUS_NOT_FILLED_TEXT
        KYC_STATUS_LABELS.legal.className = KYC_STATUS_NOT_FILLED_CLASS
        KYC_STATUS_LABELS.legal.isFilled = false
      }

      return KYC_STATUS_LABELS
    },
    kycProgress() {
      let progress = 0
      if (this.onboardingData.businessKyc) {
        progress += 25
      }
      if (this.onboardingData.legalKyc) {
        progress += 25
      }
      if (this.onboardingData.bankKyc) {
        progress += 25
      }

      return progress
    },
  },
  methods: {
    userCanView(item) {
      if (item.name !== 'api') return true

      if (item.name === 'api') {
        if (!this.merchantProfile) return false

        // For signed merchants, tier should be tier 3
        if (
          ['live', 'active', 'inactive', 'churned', 'lost', 'test'].includes(
            this.merchantProfile.status.toLowerCase()
          ) ||
          (this.merchantProfile.status.toLowerCase() === 'signed' &&
            this.merchantProfile.tier.tier === 'Tier 3 - Unverified Pilot')
        ) {
          // At this moment, merchant type is not available so we will assume its true
          let merchantTypeCondition = true

          if (this.merchantProfile.merchantTypes) {
            merchantTypeCondition =
              this.merchantProfile.merchantTypes.indexOf('Online') !== -1
          }

          if (
            merchantTypeCondition ||
            this.merchantProfile.inStoreDirectIntegrationEnabled
          ) {
            return true
          }
        }

        return false
      }
    },
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter((item) => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ...parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      return this.resolvePathWithBasePath(this.basePath, routePath)
    },
    isAutomatedLegalOnboarding(onboardingData) {
      return (
        onboardingData.automatedVerification &&
        onboardingData.automatedVerification.crNumber.isAutomated &&
        onboardingData.automatedVerification.nationalIdNumber.isAutomated
      )
    },
    automatedLegalOnboardingCompleted(onboardingData) {
      return (
        onboardingData.legalKyc &&
        onboardingData.legalKyc.isOtpVerified &&
        onboardingData.legalKyc.recollectFields.length === 0 &&
        onboardingData.legalKyc?.hasVatId !== null
      )
    },
    manualLegalOnboardingCompleted(onboardingData) {
      return (
        onboardingData.legalKyc &&
        onboardingData.legalKyc.dataSource &&
        onboardingData.legalKyc.dataSource
          .commercialRegistrationSubmittedFrom === 'manual_flow' &&
        (onboardingData.eddKyb || !onboardingData.legalKyc.isEddRequired)
      )
    },
    isKycRoute(item) {
      return item.meta && item.meta.isKyc
    },
    handleArrowClick(path, event) {
      this.$emit('click:arrow', this.$refs.subMenu, path, event)
    },
  },
}
</script>
