const countryData = [
  {
    countryName: 'Saudi Arabia',
    countryNameAr: 'السعودية',
    shortCountryName: 'Saudi',
    countryCode: 'SA',
    longCountryCode: 'KSA',
    currency: 'SAR',
    currencyAr: 'ر.س',
    telephoneCode: '966',
    decimalDigits: 2,
    timezone: 'Asia/Riyadh',
    currencyName: 'Riyal',
    numberStartsWith: [5],
    numberMinLength: 9,
    numberMaxLength: 10,
    vat: 15,
  },
  {
    countryName: 'United Arab Emirates',
    countryNameAr: 'الإمارات',
    shortCountryName: 'UAE',
    countryCode: 'AE',
    longCountryCode: 'UAE',
    currency: 'AED',
    currencyAr: 'د.إ',
    telephoneCode: '971',
    decimalDigits: 2,
    timezone: 'Asia/Dubai',
    currencyName: 'Dirham',
    numberStartsWith: [5],
    numberMinLength: 9,
    numberMaxLength: 10,
    vat: 5,
  },
  {
    countryName: 'Kuwait',
    countryNameAr: 'الكويت',
    shortCountryName: 'Kuwait',
    countryCode: 'KW',
    longCountryCode: 'KWT',
    currency: 'KWD',
    currencyAr: 'د.ك',
    telephoneCode: '965',
    decimalDigits: 3,
    timezone: 'Asia/Kuwait',
    currencyName: 'Dinar',
    numberStartsWith: [4, 5, 6, 9],
    numberMinLength: 8,
    numberMaxLength: 9,
    vat: 0,
  },
  {
    countryName: 'Bahrain',
    countryNameAr: 'البحرين',
    shortCountryName: 'Bahrain',
    countryCode: 'BH',
    longCountryCode: 'BHR',
    currency: 'BHD',
    currencyAr: 'د.ب',
    telephoneCode: '973',
    decimalDigits: 3,
    timezone: 'Asia/Bahrain',
    currencyName: 'Dinar',
    numberStartsWith: [3, 6],
    numberMinLength: 8,
    numberMaxLength: 9,
    vat: 5,
  },
  {
    countryName: 'Qatar',
    countryNameAr: 'قطر',
    shortCountryName: 'Qatar',
    countryCode: 'QA',
    longCountryCode: 'QAT',
    currency: 'QAR',
    currencyAr: 'ر.ق',
    telephoneCode: '974',
    decimalDigits: 2,
    timezone: 'Asia/Qatar',
    currencyName: 'Riyal',
    numberStartsWith: [3, 5, 6, 7],
    numberMinLength: 8,
    numberMaxLength: 9,
    vat: 0,
  },
  {
    countryName: 'Oman',
    countryNameAr: 'عمان',
    shortCountryName: 'OMN',
    countryCode: 'OM',
    longCountryCode: 'OMN',
    currency: 'OMR',
    currencyAr: 'ر.ع',
    telephoneCode: '968',
    decimalDigits: 3,
    timezone: 'Asia/Muscat',
    currencyName: 'Omani rial',
    numberStartsWith: [2, 7, 9],
    numberMinLength: 8,
    numberMaxLength: 9,
    vat: 5,
  },
]

export { countryData }
