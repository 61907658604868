import path from 'path'

import { isExternal } from '@/utils/validate'

const resolvePath = (basePath, routePath) => {
  if (isExternal(routePath)) {
    return routePath
  }

  if (isExternal(basePath)) {
    return basePath
  }

  // TODO: We will improve path.resolve by a client-side function later. Because 'path' is a Node's package, we shouldn't use 'path' for client-side
  return path.resolve(basePath, routePath)
}

export { resolvePath }
