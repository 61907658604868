// Centralize all process.env in this class. It helps us manage and know what env vars we are using currently.
class Config {
  env = process.env.VUE_APP_ENV

  isDev = process.env.VUE_APP_ENV === 'development'

  isProduction = process.env.VUE_APP_ENV === 'production'

  isStaging = process.env.VUE_APP_ENV === 'staging'

  releaseVersion = process.env.VUE_APP_RELEASE_VERSION || 'unknown'
}

export default new Config()
