import en from './en_US.json'
import ar from './ar_SA.json'

const mapLocale = {
  'en-US': 'en',
  en_US: 'en',
  'ar-SA': 'ar',
  ar_SA: 'ar',
}

const getMessagesByLocale = (locale) => {
  const [lang] = locale.replace('-', '_').split('_')
  const messages = {
    en,
    ar,
  }[lang]

  // return default to ar-SA
  return messages || ar
}

export default { en, ar }
export { mapLocale, getMessagesByLocale }
