import {
  forgotPassword,
  resendActivateEmail,
  resetPassword,
  login,
  fetchSsoSettings,
  activate,
  fetchUsers,
  fetchUserDetail,
  createUser,
  updateUser,
  deleteUser,
  fetchRoles,
  fetchRoleDetail,
  createRole,
  updateRole,
  fetchPermissionGroups,
  loginSso,
  fetchUserBranches,
  downloadUsers,
} from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'
import { ADS_NPS_RATING_DIALOG_SKIP } from '@/views/ads/constants/dialog'

const filterNotAllowedRoles = (rolesListData) => {
  const roles = rolesListData.data
  const ROLES_BLACKLIST = ['MERCHANT_KYC_USER', 'MERCHANT_ONBOARDING_USER']

  const allowedRoles = roles.filter(
    (role) => !ROLES_BLACKLIST.includes(role.roleCode)
  )
  return {
    data: allowedRoles,
    total: allowedRoles.length,
  }
}

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  permissions: [],
  list: [],
  detail: {},
  fetchingList: false,
  fetchingRoles: false,
  fetchingPermissionGroups: false,
  fetchingUserBranches: false,
  lastError: undefined,
  creatingUser: false,
  downloadingUser: false,
  // Event histories- dialog
  eventHistoriesVisible: false,
  eventHistoriesUserId: undefined,
  allRoles: [],
  rolesTotalRecords: 0,
  roleDetail: {},
  permissionGroups: [],
  userBranchesList: [],
  usersTotalRecords: 0,
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER_PERMISSIONS: (state, permissions) => {
    state.permissions = permissions
  },
  SET_ERROR(state, error) {
    state.lastError = error
  },
  ADD_LIST(state, { data, page }) {
    state.list = data
  },
  SET_FETCHING(state, { name, value }) {
    state[name] = value
  },
  SET_TOTAL_RECORDS(state, newValue) {
    state.usersTotalRecords = newValue
  },
  SET_EVENT_HISTORIES_DIALOG(state, { visible, userId }) {
    state.eventHistoriesVisible = visible
    state.eventHistoriesUserId = userId
  },
  ADD_USER_DETAIL(state, { userId, detail }) {
    state.detail = { ...state.detail, [userId]: detail }
  },
  ADD_ALL_ROLES_LIST(state, { data }) {
    state.allRoles = data.data
  },
  ADD_ALL_ROLES_TOTAL_RECORDS(state, { data }) {
    state.rolesTotalRecords = data.total
  },
  ADD_ROLE_DETAIL(state, { roleId, detail }) {
    state.detail = { ...state.detail, [roleId]: detail }
  },
  ADD_PERMISSION_GROUPS_LIST(state, { data }) {
    state.permissionGroups = data.data
  },
  ADD_USER_BRANCHES(state, { userBranches }) {
    state.userBranchesList = userBranches
  },
}

const getters = {
  getList: (state) => {
    return state.list
  },
  getUsersTotalRecords: (state) => {
    return state.usersTotalRecords
  },
  getUserDetail: (state) => (userId) => {
    return state.detail[userId] || {}
  },
  getAllRoles: (state) => {
    return state.allRoles
  },
  getRolesTotalRecords: (state) => {
    return state.rolesTotalRecords
  },
  getRoleDetail: (state) => (roleId) => {
    return state.detail[roleId] || {}
  },
  getPermissionGroups: (state) => {
    return state.permissionGroups
  },
}

const actions = {
  // user login
  async login({ commit }, { userInfo, reCaptchaToken }) {
    const { email, password } = userInfo
    const response = await login({
      email: email.trim(),
      password: password,
      reCaptchaToken,
    })
    const { data } = response

    commit('SET_TOKEN', data.token)

    return response
  },
  setToken({ commit }, token) {
    commit('SET_TOKEN', token)
  },

  fetchSsoSettings({ commit }, { provider }) {
    return fetchSsoSettings(provider)
  },

  async loginSso({ commit }, { provider, authCode }) {
    const response = await loginSso({
      provider,
      authCode,
    })

    const { data } = response
    commit('SET_TOKEN', data.token)
    setToken(data.token)

    return response
  },

  async forgotPassword({ commit }, { userInfo, reCaptchaToken }) {
    try {
      const { email, locale } = userInfo
      const response = await forgotPassword({
        email: email.trim(),
        locale,
        reCaptchaToken,
      })
      return response
    } catch (err) {
      return err.response
    }
  },

  async resendActivateEmail({ commit }, { userInfo, reCaptchaToken }) {
    const { locale, userId } = userInfo
    const response = await resendActivateEmail({
      locale,
      userId,
      reCaptchaToken,
    })

    return response
  },

  async resetPassword({ commit }, { userInfo, reCaptchaToken }) {
    const { userId, hashCode, password, confirmPassword } = userInfo
    const response = await resetPassword({
      userId,
      hashCode,
      password,
      confirmPassword,
      reCaptchaToken,
    })

    return response
  },

  // get user info
  async getInfo({ commit, dispatch }) {
    const data = await dispatch('userProfile/getMerchantUserProfile')

    if (!data) {
      throw new Error('Verification failed, please Login again.')
    }

    const { roles, permissions, name, avatar, introduction } = data

    // roles must be a non-empty array
    if (!roles || roles.length <= 0) {
      throw new Error('getInfo: roles must be a non-null array!')
    }

    // roles must be a non-empty array
    if (!permissions || permissions.length <= 0) {
      throw new Error('getInfo: roles must be a non-null array!')
    }

    commit('SET_USER_ROLES', roles)
    commit('SET_USER_PERMISSIONS', permissions)
    commit('SET_NAME', name)
    commit('SET_AVATAR', avatar)
    commit('SET_INTRODUCTION', introduction)

    return data
  },

  // user logout
  async logout({ commit, dispatch }) {
    commit('SET_TOKEN', '')
    commit('SET_USER_ROLES', [])
    commit('SET_USER_PERMISSIONS', [])
    dispatch('userProfile/clearMerchantUserData', null, { root: true })
    dispatch('permission/resetRoutes', null, { root: true })
    localStorage.removeItem('site-wide-dialogs-skip')
    localStorage.removeItem(ADS_NPS_RATING_DIALOG_SKIP)
    removeToken()
    resetRouter()

    // reset visited views and cached views
    // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
    dispatch('tagsView/delAllViews', null, { root: true })

    return true
  },

  // remove token
  resetAuth({ commit }) {
    commit('SET_TOKEN', '')
    commit('SET_USER_ROLES', [])
    removeToken()
  },

  activateUser(
    _,
    { reCaptchaToken, hashCode, userId, newPassword, confirmPassword }
  ) {
    return activate({
      reCaptchaToken,
      hashCode,
      userId,
      newPassword,
      confirmPassword,
    })
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return async () => {
      const token = role + '-token'

      commit('SET_TOKEN', token)
      setToken(token)

      const { permissions } = await dispatch('getInfo')

      resetRouter()

      // generate accessible routes map based on user permissions
      const accessRoutes = await dispatch(
        'permission/generateRoutes',
        permissions,
        {
          root: true,
        }
      )

      // dynamically add accessible routes
      router.addRoutes(accessRoutes)

      // reset visited views and cached views
      dispatch('tagsView/delAllViews', null, { root: true })

      return Promise.resolve()
    }
  },

  async fetchUsers({ commit, dispatch, state }, params) {
    try {
      const { ...query } = params
      if (query.merchantIds) {
        query.merchantIds = Array.isArray(query.merchantIds)
          ? query.merchantIds
          : [query.merchantIds]
      }
      commit('SET_FETCHING', { name: 'fetchingList', value: true })

      // TODO add pagination to users list
      const paginationData = {
        page: 1,
        limit: 200,
      }

      const { data } = await fetchUsers({
        ...paginationData,
        ...query,
      })
      commit('ADD_LIST', { data: data.data })
      commit('SET_TOTAL_RECORDS', data.total)
      commit('SET_FETCHING', { name: 'fetchingList', value: false })
    } catch (error) {}
  },

  async fetchUserDetail({ commit }, userId) {
    try {
      commit('SET_FETCHING', { name: 'fetchingDetail', value: true })

      const response = await fetchUserDetail(userId)

      commit('SET_FETCHING', { name: 'fetchingDetail', value: false })
      commit('ADD_USER_DETAIL', { userId, detail: response.data })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async createUser({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'creatingUser', value: true })

      const response = await createUser(data)

      commit('SET_FETCHING', { name: 'creatingUser', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'creatingUser', value: false })

      throw error
    }
  },

  async updateUser({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'updatingUser', value: true })

      const response = await updateUser(data)

      commit('SET_FETCHING', { name: 'updatingUser', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'updatingUser', value: false })

      throw error
    }
  },

  async deleteUser({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'deletingUser', value: true })

      const response = await deleteUser(data)

      commit('SET_FETCHING', { name: 'deletingUser', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'deletingUser', value: false })

      throw error
    }
  },

  setEventHistoriesDialog({ commit, state }, { visible, userId }) {
    if (state.eventHistoriesVisible !== visible) {
      commit('SET_EVENT_HISTORIES_DIALOG', { visible, userId })
    }
  },

  async fetchRoles({ commit }) {
    try {
      commit('SET_FETCHING', { name: 'fetchingRoles', value: true })

      const { data } = await fetchRoles()
      const filteredRoles = filterNotAllowedRoles(data)

      commit('ADD_ALL_ROLES_LIST', { data: filteredRoles })
      commit('ADD_ALL_ROLES_TOTAL_RECORDS', { data: filteredRoles })
      commit('SET_FETCHING', { name: 'fetchingRoles', value: false })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async fetchPermissionGroups({ commit }) {
    try {
      commit('SET_FETCHING', { name: 'fetchingPermissionGroups', value: true })
      const { data } = await fetchPermissionGroups()

      commit('ADD_PERMISSION_GROUPS_LIST', { data })
      commit('SET_FETCHING', { name: 'fetchingPermissionGroups', value: false })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async fetchRoleDetail({ commit }, roleId) {
    try {
      const response = await fetchRoleDetail(roleId)

      commit('ADD_ROLE_DETAIL', { roleId, detail: response.data })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async createRole({ commit }, data) {
    try {
      const response = await createRole(data)

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)

      throw error
    }
  },

  async updateRole({ commit }, data) {
    try {
      const response = await updateRole(data)

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)

      throw error
    }
  },

  async fetchUserBranches({ commit }, merchantIds) {
    try {
      commit('SET_FETCHING', { name: 'fetchingUserBranches', value: true })

      if (
        !merchantIds ||
        !Array.isArray(merchantIds) ||
        merchantIds.length === 0
      )
        return
      const params = merchantIds.map((id) => `merchantIds[]=${id}`).join('&')

      const response = await fetchUserBranches(params)

      commit('SET_FETCHING', { name: 'fetchingUserBranches', value: false })
      commit('ADD_USER_BRANCHES', { userBranches: response.data.data })
    } catch (error) {
      commit('SET_ERROR', error)
    }
  },

  async downloadUsers({ commit }, data) {
    try {
      commit('SET_FETCHING', { name: 'downloadingUser', value: true })

      const response = await downloadUsers(data)

      commit('SET_FETCHING', { name: 'downloadingUser', value: false })

      return response.data
    } catch (error) {
      commit('SET_ERROR', error)
      commit('SET_FETCHING', { name: 'downloadingUser', value: false })

      throw error
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
