import { fetchMessages } from '@/api/translations'

const RIGHT_TO_LEFT_LANGS = ['ar']

const state = {
  locale: '',
  messages: {},
  direction: 'ltr',
  fetchingMessage: false,
}

const getters = {
  isFetchingMessage: (state) => state.fetchingMessage,
  messages: (state) => {
    return state.messages || {}
  },
  currentLocale: (state) => {
    return state.locale
  },
  currentLang: (state) => {
    const locale = state.locale.replace('-', '_')
    const [lang] = locale.split('_')

    return lang || 'en'
  },
  hasLocale: (state) => {
    return !!state.locale
  },
  currentDirection: (state) => {
    return state.direction
  },
  isRightToLeftDirection: () => {
    return state.direction === 'rtl'
  },
}

const mutations = {
  UPDATED_FETCHING_MESSAGES: (state, fetching) => {
    state.fetchingMessage = fetching
  },
  UPDATE_LOCALE: (state, locale) => {
    state.locale = locale
  },
  UPDATE_MESSAGES: (state, { messages, locale }) => {
    state.messages = { ...state.messages, [locale]: messages }
  },
  SET_DIRECTION: (state, direction) => {
    state.direction = direction
  },
}

const actions = {
  fetchMessages({ commit, dispatch, state }, locale) {
    commit('UPDATE_LOCALE', locale)

    if (state.messages[locale]) {
      // Trigger change direction
      dispatch('setDirectionByLocale', locale)

      return state.messages[locale]
    }
    commit('UPDATED_FETCHING_MESSAGES', true)

    const messages = fetchMessages(locale)

    commit('UPDATE_MESSAGES', { messages, locale })
    commit('UPDATED_FETCHING_MESSAGES', false)

    // Trigger change direction
    dispatch('setDirectionByLocale', locale)

    return messages
  },

  setDirectionByLocale({ commit }, locale) {
    const [lang] = locale.replace('-', '_').split('_')

    if (RIGHT_TO_LEFT_LANGS.includes(lang)) {
      return commit('SET_DIRECTION', 'rtl')
    }

    commit('SET_DIRECTION', 'ltr')
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
