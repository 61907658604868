export const OPEN_STATUS = 'open'
export const UNDER_REVIEW_STATUS = 'under_review'
export const CLOSED_STATUS = 'closed'
export const CANCELLED_STATUS = 'cancelled'

export const SUB_STATUS_MERCHANT_CHALLENGED = 'merchant_challenged'
export const SUB_STATUS_ACCEPTED_BY_MERCHANT = 'accepted_by_merchant'
export const SUB_STATUS_REJECTED_BY_MERCHANT = 'rejected_by_merchant'
export const SUB_STATUS_REFUND_FROM_TAMARA = 'refunded_from_tamara'
export const SUB_STATUS_REFUNDED_FROM_MERCHANT = 'refunded_from_merchant'

export default [
  {
    value: OPEN_STATUS,
    label: 'dispute_status_open',
    icon: 'eye-blue',
    colorClass: 'blue',
  },
  {
    value: UNDER_REVIEW_STATUS,
    label: 'dispute_status_under_review',
    icon: 'eye-blue',
    colorClass: 'blue',
  },
  {
    value: CLOSED_STATUS,
    label: 'dispute_status_closed',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
  {
    value: CANCELLED_STATUS,
    label: 'dispute_status_cancelled',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
]

export const SUB_STATUS_MAP = [
  {
    value: SUB_STATUS_MERCHANT_CHALLENGED,
    label: 'dispute_sub_status_merchant_challenged',
    icon: 'eye-blue',
    colorClass: 'blue',
  },
  {
    value: SUB_STATUS_ACCEPTED_BY_MERCHANT,
    label: 'dispute_sub_status_accepted_by_merchant',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
  {
    value: SUB_STATUS_REJECTED_BY_MERCHANT,
    label: 'dispute_sub_status_rejected_by_merchant',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
  {
    value: SUB_STATUS_REFUND_FROM_TAMARA,
    label: 'dispute_sub_status_refund_from_tamara',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
  {
    value: SUB_STATUS_REFUNDED_FROM_MERCHANT,
    label: 'dispute_sub_status_refunded_from_merchant',
    icon: 'check-outline-green',
    colorClass: 'green',
  },
]
