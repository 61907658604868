import arLocale from 'date-fns/locale/ar-SA'
import enLocale from 'date-fns/locale/en-US'
import { format as formatFn, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import subDays from 'date-fns/subDays'
import subYears from 'date-fns/subYears'
import isFuture from 'date-fns/isFuture'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import { translate } from '@/utils/i18n'

import store from '@/store'

const DEFAULT_FORMAT = 'yyyy-MM-dd HH:mm (z)'
const convertToZonedTime = (date, timeZone) => {
  if (typeof date !== 'string') {
    return date
  }

  return utcToZonedTime(date, timeZone || store.state.userProfile.timezone)
}

const formatDate = (date = '', options = {}) => {
  if (typeof date !== 'string') {
    return date
  }

  const format = options.format || DEFAULT_FORMAT
  const timeZone = options.timeZone || store.state.userProfile.timezone
  const dateInZoned = convertToZonedTime(date, timeZone)
  const locale = store.getters.currentLang === 'en' ? enLocale : arLocale

  return formatFn(dateInZoned, format, { timeZone, locale })
}

const getFormattedDateRangeQuery = (
  fromDate,
  toDate,
  paramsName = 'createdAt'
) => {
  const dateRangeFormatted = {}

  if (!fromDate && !toDate) {
    return dateRangeFormatted
  }

  // Assuming fromDate and toDate are in 'yyyy-MM-dd' format
  const startOfDay = 'T00:00:00'
  const endOfDay = 'T23:59:59'

  // Append the time to the date strings
  const fromDateWithTime = `${fromDate}${startOfDay}`
  const toDateWithTime = `${toDate}${endOfDay}`

  // Convert the datetime in user acc timezone to the equivalent UTC times
  const fromDateInUTC = zonedTimeToUtc(
    fromDateWithTime,
    store.state.userProfile.timezone
  )
  const toDateInUTC = zonedTimeToUtc(
    toDateWithTime,
    store.state.userProfile.timezone
  )

  // Format the UTC dates to ISO strings
  const fromDateFormatted = fromDateInUTC ? fromDateInUTC.toISOString() : null
  const toDateFormatted = toDateInUTC ? toDateInUTC.toISOString() : null

  if (paramsName === 'fromDateToDate') {
    dateRangeFormatted.fromDate = fromDateFormatted
    dateRangeFormatted.toDate = toDateFormatted
    return dateRangeFormatted
  }

  dateRangeFormatted['createdAt[gte]'] = fromDateFormatted
  dateRangeFormatted['createdAt[lte]'] = toDateFormatted
  return dateRangeFormatted
}

const nowInZone = () =>
  utcToZonedTime(new Date(), store.state.userProfile.timezone)
const startOfDayInZone = (date) =>
  startOfDay(utcToZonedTime(date, store.state.userProfile.timezone))
const endOfDayInZone = (date) =>
  endOfDay(utcToZonedTime(date, store.state.userProfile.timezone))

const getPickerOptions = (options = {}) => {
  const { excludeShortcuts = [] } = options
  const shortcuts = []

  const shortcutDefinitions = {
    today: {
      text: translate('date_today'),
      onClick(picker) {
        const start = startOfDayInZone(nowInZone())
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    yesterday: {
      text: translate('date_yesterday'),
      onClick(picker) {
        const start = startOfDayInZone(subDays(nowInZone(), 1))
        const end = endOfDayInZone(subDays(nowInZone(), 1))
        picker.$emit('pick', [start, end])
      },
    },
    last_7_days: {
      text: translate('date_last_7days'),
      onClick(picker) {
        const start = startOfDayInZone(subDays(nowInZone(), 7))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
    last_month: {
      text: translate('date_last_month'),
      onClick(picker) {
        const end = endOfDayInZone(nowInZone())
        const start = startOfDayInZone(subDays(end, 30))

        picker.$emit('pick', [start, end])
      },
    },
    last_year: {
      text: translate('date_last_year'),
      onClick(picker) {
        const start = startOfDayInZone(subYears(nowInZone(), 1))
        const end = endOfDayInZone(nowInZone())
        picker.$emit('pick', [start, end])
      },
    },
  }

  Object.entries(shortcutDefinitions).forEach(([key, shortcut]) => {
    if (!excludeShortcuts.includes(key)) {
      shortcuts.push(shortcut)
    }
  })

  return {
    disabledDate: (time) => {
      // Convert the incoming picker date to UTC to be able to compare it with the current time in user acc timezone
      const pickerDateUTC = zonedTimeToUtc(
        time,
        store.state.userProfile.timezone
      )
      // Get the current date and time in user acc timezone and convert it to UTC
      const nowInZoneUTC = zonedTimeToUtc(
        new Date(),
        store.state.userProfile.timezone
      )
      // Disable the date if it's in the future relative to user acc timezone
      return isFuture(pickerDateUTC) && pickerDateUTC > nowInZoneUTC
    },
    shortcuts: shortcuts,
  }
}

const getPreviousMonthToday = () => {
  // Get the current date in UTC
  const now = new Date()

  // Subtract 30 days from the current date
  const previousMonthDate = subDays(now, 30)

  // Convert the date to user acc timezone
  const zonedDate = utcToZonedTime(
    previousMonthDate,
    store.state.userProfile.timezone
  )

  // Return the date as a string formatted in the user acc timezone
  return formatFn(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { zonedDate })
}

export {
  formatDate,
  convertToZonedTime,
  getFormattedDateRangeQuery,
  getPickerOptions,
  getPreviousMonthToday,
  nowInZone,
  startOfDayInZone,
  endOfDayInZone,
}
