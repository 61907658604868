const getters = {
  size: (state) => state.app.size,
  device: (state) => state.app.device,
  visitedViews: (state) => state.tagsView.visitedViews,
  cachedViews: (state) => state.tagsView.cachedViews,
  token: (state) => state.user.token,
  email: (state) => state.user.email,
  roles: (state) => state.userProfile.roles,
  permissions: (state) => state.userProfile.permissions || [],
  permissionRoutes: (state) => state.permission.routes,
  permissionAdded: (state) => state.permission.addedRoutes,
  errorLogs: (state) => state.errorLog.logs,
  currentLang: (_, getters) => getters['translations/currentLang'],
}

export default getters
