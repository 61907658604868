import Vue from 'vue'
import Vuex from 'vuex'

import getters from './getters'

Vue.use(Vuex)

const modules = {
  app: require('./modules/app').default,
  errorLog: require('./modules/error-log').default,
  invoice: require('./modules/invoice').default,
  exports: require('./modules/exports').default,
  order: require('./modules/order').default,
  dispute: require('./modules/dispute').default,
  permission: require('./modules/permission').default,
  route: require('./modules/route').default,
  settings: require('./modules/settings').default,
  settlement: require('./modules/settlement').default,
  tagsView: require('./modules/tags-view').default,
  user: require('./modules/user').default,
  profile: require('./modules/profile').default,
  userProfile: require('./modules/user-profile').default,
  translations: require('./modules/translations').default,
  barcodeSettings: require('./modules/barcode-settings').default,
  webhooks: require('./modules/webhooks').default,
  transaction: require('./modules/transaction').default,
  dashboard: require('./modules/dashboard').default,
  store: require('./modules/store').default,
  api: require('./modules/api').default,
  rating: require('./modules/rating').default,
  kyc: require('./modules/kyc').default,
  onboarding: require('./modules/onboarding').default,
  address: require('./modules/address').default,
  ads: require('./modules/ads').default,
}

const store = new Vuex.Store({
  modules,
  getters,
})

export default store
