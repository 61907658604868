import request from '@/utils/request'

const fetchInvoiceList = (data) => {
  return request({
    path: '/merchants/invoices',
    method: 'GET',
    data,
  })
}

const fetchInvoiceLink = (data) => {
  return request({
    path: `/merchants/invoices/${data.invoice_id}/get-link`,
    method: 'GET',
    data,
  })
}

const downloadInvoicesFromDates = (data) => {
  return request(
    {
      path: `/merchants/invoices/download-all-invoices`,
      method: 'GET',
      timeout: 100000, // ms
      data,
    },
    {
      responseType: 'arraybuffer',
      options: {
        camelizeRes: false,
        headers: {
          'Content-Type': 'application/zip',
        },
      },
    }
  )
}

export { fetchInvoiceList, fetchInvoiceLink, downloadInvoicesFromDates }
