<template>
  <div v-if="userId" class="user-id">
    <div>
      <div>
        <svg-icon :icon-class="'user-id'" />
      </div>
      <div class="user-id__id-container ellipsis">
        ID #<CopyText :label="ellipsisCenter(userId)" :value="userId" />
      </div>
    </div>
  </div>
</template>

<script>
import { ellipsisCenter } from '@/utils/index.js'
import CopyText from '@/components/CopyText'
export default {
  components: {
    CopyText,
  },
  data() {
    return {
      ellipsisCenter,
    }
  },
  computed: {
    userId() {
      return this.$route.params.userId
    },
  },
}
</script>

<style lang="scss" scoped>
.user-id {
  display: flex;
  justify-content: flex-end;
  svg {
    width: 18px;
    height: 18px;
    margin: 0 6px;
  }
}

.user-id > div {
  display: flex;
  align-items: center;
  margin: 0 25px;
  &:last-child {
    margin-right: 0;
    [dir='rtl'] & {
      margin-left: 0;
    }
  }
}

.user-id__id-container {
  display: flex;
  justify-content: center;
}

.user-id__id-container > div {
  [dir='rtl'] & {
    direction: rtl;
  }
}
.user-id__id-container button {
  margin-bottom: -3px;
}
</style>
