import request from '@/utils/request'

const fetchDisputeList = (data) => {
  return request({
    path: '/merchants/orders/disputes',
    method: 'GET',
    data,
  })
}

const exportDisputeList = (data) => {
  return request({
    path: '/merchants/orders/disputes/report',
    method: 'GET',
    data,
  })
}
const fetchDisputeDetail = (data) => {
  return request(
    {
      path: `/merchants/orders/disputes/detail/${data.disputeId}?merchantId=${data.merchantId}`,
      method: 'GET',
    },
    {
      options: { hideMessage: true, disableErrorRedirect: true },
    }
  )
}

const fetchMerchantBranches = (params) => {
  return request({
    path: `/merchants/branches/list?${params}`,
    method: 'GET',
  })
}

const fetchPaymentPlans = ({ countryCode, amount, currency }) => {
  return request({
    path: `/payment/types`,
    method: 'GET',
    data: {
      country: countryCode,
      order_value: amount,
      currency,
    },
  })
}

const uploadDisputeFile = (id, formData, reCaptchaToken) => {
  return request(
    {
      path: `/merchants/orders/dispute/${id}/upload-document`,
      method: 'POST',
      data: formData,
    },
    {
      options: {
        hideMessage: true,
        disableErrorRedirect: true,
        headers: { 'x-tamara-token': reCaptchaToken },
      },
    }
  )
}

export {
  fetchDisputeList,
  exportDisputeList,
  fetchDisputeDetail,
  fetchMerchantBranches,
  fetchPaymentPlans,
  uploadDisputeFile,
}
