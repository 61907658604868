<template>
  <div id="sidebar" class="sidebar">
    <div v-if="subMerchantAccounts && !isKycUser">
      <el-drawer
        :direction="currentLocale === 'ar_SA' ? 'rtl' : 'ltr'"
        :with-header="false"
        :modal-append-to-body="false"
        custom-class="sidebar__submerchants-drawer"
        :visible.sync="showSubMerchantAccounts"
        @open="handleBeforeOpenDrawer"
        @closed="closeDrawer"
      >
        <div>
          <div class="sidebar__submerchants">
            <div class="sidebar__submerchants-head">
              <div class="sidebar__submerchants-head-search">
                <span class="sidebar__search-svg-container">
                  <svg-icon icon-class="search" />
                </span>
                <el-input
                  v-model="subMerchantsSearchText"
                  clearable
                  :placeholder="$t('sidebar_search_placeholder')"
                  class="search-input"
                />
              </div>
              <div class="sidebar__submerchants-head-text">
                {{ subMerchantAccounts.length }}
                {{ $t('sidebar_accounts_found') }}
              </div>
            </div>
            <div class="sidebar__submerchants-content">
              <div
                v-if="
                  subMerchantsSearchText && subMerchantAccounts.length === 0
                "
                class="sidebar__submerchants-no-results"
              >
                {{ $t('sidebar_no_results') }}
              </div>
              <div
                v-for="item in subMerchantAccounts"
                :key="item.merchantId"
                class="sidebar__submerchants-item"
                @click="handleSelectSubMerchant(item.merchantId)"
              >
                <div class="sidebar__submerchants-item-svg-container">
                  <svg-icon icon-class="store" />
                </div>
                <div class="sidebar__submerchants-item-name-container">
                  <el-tooltip
                    slot="title"
                    placement="right"
                    :open-delay="200"
                    :content="getLocalizedSubMerchantName(item)"
                  >
                    <div class="sidebar__submerchants-item-name ellipsis">
                      {{ getLocalizedSubMerchantName(item) }}
                    </div>
                  </el-tooltip>
                </div>
                <div v-if="item.merchantId === selectedMerchant">
                  <svg-icon icon-class="check" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <el-menu
      ref="sidebarMenu"
      :default-active="activeMenu"
      :background-color="variables.menuBg"
      :text-color="variables.menuText"
      :unique-opened="false"
      :active-text-color="variables.menuActiveText"
      :collapse-transition="false"
      mode="vertical"
      @close="handleMenuCollapse"
      @item-click="handleClickedOnItem"
      @submenu-click="handleClickedSubmenu"
    >
      <div class="sidebar__inner">
        <div v-if="isConfirmedUser" class="sidebar__top">
          <div
            :class="
              `sidebar__submerchants-button ${
                subMerchantAccounts
                  ? 'sidebar__submerchants-button--clickable'
                  : ''
              }`
            "
            @click="
              showSubMerchantAccounts = subMerchantAccounts
                ? !showSubMerchantAccounts
                : false
            "
          >
            <div class="sidebar__submerchants-main-logo">
              <img
                v-if="merchantProfile.logo"
                :src="merchantProfile.logo"
                alt=""
              />
              <svg-icon v-else icon-class="store" />
            </div>

            <div class="sidebar__submerchants-button-text">
              <div>
                <el-tooltip
                  slot="title"
                  placement="bottom"
                  :content="activeSubMerchantName"
                >
                  <div class="ellipsis">
                    <span
                      class="sidebar__submerchants-button-merchant-name global__always-ltr"
                    >
                      {{ ellipsisEnd(activeSubMerchantName, 15) }}
                    </span>
                  </div>
                </el-tooltip>
              </div>
              <div>
                {{ $t('sidebar_id') }}:
                <span class="global__always-ltr">
                  {{ merchantProfile.merchantCode }}
                </span>
              </div>
            </div>

            <div
              v-if="subMerchantAccounts"
              class="sidebar__submerchants-button-svg"
            >
              <svg-icon
                v-if="showSubMerchantAccounts"
                class="sidebar__change-branch-svg"
                icon-class="change-branch"
              />
              <svg-icon
                v-if="!showSubMerchantAccounts"
                icon-class="two-arrows"
              />
            </div>
          </div>
          <SidebarItem
            v-for="route in restRoutes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
            @click:arrow="handleArrowClick"
          />
        </div>

        <div
          v-if="isKycUser && onboardingData"
          class="sidebar__top sidebar__top--onboarding"
        >
          <div
            :class="
              `sidebar__submerchants-button ${
                subMerchantAccounts
                  ? 'sidebar__submerchants-button--clickable'
                  : ''
              }`
            "
          >
            <div class="sidebar__submerchants-main-logo">
              <img
                v-if="!fetchingOnboardingData"
                :src="kycMerchantLogoUrl"
                alt=""
              />
            </div>

            <div class="sidebar__submerchants-button-text">
              <div>
                {{ kycMerchantName }}
              </div>
              <el-tooltip
                slot="title"
                placement="bottom"
                :content="activeSubMerchantName"
              >
                <div class="ellipsis">
                  {{ activeSubMerchantName }}
                </div>
              </el-tooltip>
            </div>
          </div>

          <SidebarItem
            v-for="route in kycRoutes"
            :key="route.path"
            :item="route"
            :base-path="route.path"
          />
        </div>

        <div class="sidebar__bottom">
          <div>
            <SidebarItem :key="docsUrl" :item="docsItem" :base-path="docsUrl" />
          </div>
          <div>
            <SidebarItem :key="kitUrl" :item="kitItem" :base-path="kitUrl" />
          </div>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import SidebarItem from '@/components/SidebarItem'
import variables from '@/styles/variables.scss'
import { ellipsisEnd } from '@/utils/index.js'
import userHasPermission from '@/utils/user-has-permission'
import tamaraSmallLogo from '@/assets/images/tamara-small-logo.png'
import {
  OPEN_STATUS,
  UNDER_REVIEW_STATUS,
} from '@/views/dispute/constants/status-options'
import ResolvePathMixin from '@/mixins/resolve-path'
import { findRouteByPath } from '@/utils/route'

export default {
  components: { SidebarItem },
  mixins: [ResolvePathMixin],
  data() {
    return {
      showSubMerchantAccounts: false,
      subMerchantsSearchText: '',
      tamaraSmallLogo,
      ellipsisEnd,
    }
  },
  computed: {
    ...mapGetters('userProfile', { userProfile: 'profile' }),
    ...mapGetters('userProfile', ['subMerchants', 'selectedMerchant']),
    ...mapGetters('profile', { merchantProfile: 'profile' }),
    ...mapGetters(['permissionRoutes', 'token']),
    ...mapGetters('translations', ['currentLocale']),
    ...mapGetters('kyc', ['onboardingData', 'fetchingOnboardingData']),
    ...mapGetters('translations', ['currentLocale']),
    kycMerchantLogoUrl() {
      return this.onboardingData?.businessKyc?.logoUrl &&
        this.onboardingData?.businessKyc?.logo
        ? this.onboardingData?.businessKyc?.logoUrl
        : tamaraSmallLogo
    },

    kycMerchantName() {
      return (
        this.onboardingData?.businessKyc?.businessName ||
        this.$t('sidebar_account')
      )
    },
    kycRoutes() {
      const kycPaths = []
      return this.permissionRoutes.filter((route) => {
        const isKyc = route.meta && route.meta.isKyc
        const isUnique = !kycPaths.includes(route.path)
        kycPaths.push(route.path)
        return isKyc && isUnique
      })
    },
    restRoutes() {
      return this.permissionRoutes.filter(
        (route) => !route.meta || !route.meta.isKyc
      )
    },
    isKycUser() {
      return this.userProfile && this.userProfile.role === 'MERCHANT_KYC_USER'
    },
    isConfirmedUser() {
      return this.userProfile && this.userProfile.role !== 'MERCHANT_KYC_USER'
    },
    userCanViewOrderCount() {
      return this.merchantProfile && userHasPermission(['order_view_list_v2'])
    },
    userCanViewDisputes() {
      return this.merchantProfile && userHasPermission(['get_dispute_listing'])
    },
    docsUrl() {
      return `${this.$t('docs_url')}`
    },
    kitUrl() {
      return `${this.$t('merchant_kit_url')}`
    },
    activeMenu() {
      const route = this.$route
      const { meta, path, query } = route

      // If there's an active menu set in the metadata, return it
      if (meta.activeMenu) {
        return meta.activeMenu
      }

      // If we're not on any subpage such as under review orders or open disputes, return the path as is
      if (!query.statusPage) {
        return path
      }

      let statusParam = ''
      let statusValue = ''

      // If the path includes '/order/', set the status parameter and value for orders
      if (path.includes('/order/')) {
        statusParam = 'status[eq]'
        statusValue =
          query.statusPage === 'toCapture' ? 'authorised' : 'approved'
      }

      // If the path includes '/dispute/', set the status parameter and value for disputes
      if (path.includes('/dispute/')) {
        statusParam = 'statuses'
        statusValue =
          query.statusPage === 'action-required'
            ? OPEN_STATUS
            : UNDER_REVIEW_STATUS
      }

      const queryString = `${statusParam}=${statusValue}&statusPage=${query.statusPage}`

      return `${path}?${queryString}`
    },
    showLogo() {
      return this.$store.state.settings.sidebarLogo
    },
    variables() {
      return variables
    },
    docsItem() {
      return {
        hidden: false,
        children: [
          {
            path: this.docsUrl,
            component: () => {},
            name: 'docs',
            meta: {
              title: 'tab_docs',
              label: 'tab_docs',
              icon: 'menu-docs',
              iconActive: 'menu-docs',
              affix: true,
            },
          },
        ],
      }
    },
    kitItem() {
      return {
        hidden: false,
        children: [
          {
            path: this.kitUrl,
            component: () => {},
            name: 'kit',
            meta: {
              title: 'merchant_kit_tab',
              label: 'merchant_kit_tab',
              icon: 'kit',
              iconActive: 'kit',
              affix: true,
            },
          },
        ],
      }
    },
    merchantName() {
      if (this.currentLocale === 'ar_SA') {
        return (
          this.merchantProfile.arMerchantName ||
          this.merchantProfile.merchantName
        )
      }
      return (
        this.merchantProfile.merchantName || this.merchantProfile.arMerchantName
      )
    },
    activeSubMerchantName() {
      const merchant = this.subMerchants.find(
        (mer) => mer.merchantId === this.selectedMerchant
      )
      if (!merchant) return this.merchantName
      return this.getLocalizedSubMerchantName(merchant)
    },
    subMerchantAccounts() {
      if (this.subMerchants.length <= 1) {
        return null
      }

      if (this.subMerchantsSearchText) {
        const filterdItems = this.subMerchants.filter((item) => {
          const merchantName =
            this.currentLocale === 'ar_SA'
              ? item.arMerchantName
              : item.merchantName

          return (
            merchantName
              .toLowerCase()
              .includes(this.subMerchantsSearchText.toLowerCase()) ||
            item.merchantCode
              .toLowerCase()
              .includes(this.subMerchantsSearchText.toLowerCase())
          )
        })

        return filterdItems
      }

      return this.subMerchants
    },
  },
  created() {
    if (this.userCanViewOrderCount) {
      this.fetchOrdersCount()
    }
    if (this.userCanViewDisputes) {
      this.fetchActionRequiredDisputes()
    }
  },
  methods: {
    ...mapActions('userProfile', ['updateSelectedMerchant']),
    ...mapActions('order', ['fetchOrdersRecordsCount']),
    ...mapActions('dispute', ['fetchActionRequiredList']),
    fetchActionRequiredDisputes() {
      // status is open to get the ones requires an action
      this.fetchActionRequiredList({
        page: 1,
        limit: 3,
        'statuses[]': 'open',
        withListingData: true,
      })
    },
    async fetchOrdersCount() {
      await this.fetchOrdersRecordsCount()
    },
    async handleSelectSubMerchant(key, keyPath) {
      this.closeDrawer()
      await this.removeFilterParams()
      this.updateSelectedMerchant(key)
    },
    handleMenuCollapse(key) {
      // It is to prevent menu to be collapsable
      if (key === '/kyc') {
        this.$refs.sidebarMenu.open(key)
      }
    },
    removeFilterParams() {
      // this is clear default query filters
      // associated with specific page
      return new Promise((resolve, reject) => {
        const currentRouteName = this.$route.name
        const currentQueryParams = Object.assign({}, this.$route.query)

        if (currentRouteName === 'dashboard-home') {
          delete currentQueryParams['currency[eq]']
          this.$router.replace({ query: currentQueryParams }, resolve, reject)
        } else if (currentRouteName === 'settlement') {
          // in settlement page we are resetting both currency and country
          delete currentQueryParams['currency[eq]']
          delete currentQueryParams['country[eq]']
          this.$router.replace({ query: currentQueryParams }, resolve, reject)
        } else {
          resolve()
        }
      })
    },
    getLocalizedSubMerchantName(item) {
      return this.currentLocale === 'ar_SA'
        ? item.arMerchantName
        : item.merchantName
    },
    getTopMenuOffset() {
      const element = document.querySelector('#sidebar')

      if (!element) {
        console.error('Element #sidebar not found')
        return 0
      }

      const rect = element.getBoundingClientRect()
      return rect.top
    },
    handleBeforeOpenDrawer() {
      const offsetTop = this.getTopMenuOffset()
      // fix the issue of the drawer not showing up correctly when the page is scrolled down
      const drawerContainer = document.querySelector(
        '#sidebar .el-drawer__container'
      )
      drawerContainer.style.setProperty('top', `${offsetTop}px`, 'important')
    },
    closeDrawer() {
      this.showSubMerchantAccounts = false
      this.subMerchantsSearchText = ''
    },
    handleArrowClick(subMenuRef, targetIndex) {
      const sidebarMenu = this.$refs.sidebarMenu

      if (!sidebarMenu || !subMenuRef) {
        return
      }

      const isActive = subMenuRef.active || false

      // We ignore action if sub-menu is active
      if (isActive) {
        return
      }

      const isOpening = subMenuRef.opened || false

      this.handleToggleSubmenu(sidebarMenu, targetIndex, isOpening)
    },
    handleToggleSubmenu(sidebarMenu, targetIndex, isOpening) {
      if (isOpening) {
        this.handleCollapseSubmenu(sidebarMenu, targetIndex)

        return
      }

      this.handleExpandSubmenu(sidebarMenu, targetIndex)
    },
    handleExpandSubmenu(sidebarMenu, targetIndex) {
      this.handleCollapseOtherSubmenu(sidebarMenu, targetIndex)

      sidebarMenu.open(targetIndex)
    },
    handleCollapseSubmenu(sidebarMenu, targetIndex) {
      sidebarMenu.close(targetIndex)
    },
    handleCollapseOtherSubmenu(sidebarMenu, targetIndex) {
      const submenus = sidebarMenu?.submenus || {}

      Object.values(submenus).forEach((submenu) => {
        if (
          submenu.index !== targetIndex &&
          !submenu.active &&
          submenu.opened
        ) {
          this.handleCollapseSubmenu(sidebarMenu, submenu.index)
        }
      })
    },
    handleOpenSubmenuChild(targetIndex, onComplete) {
      const matchedRoute = this.permissionRoutes.find(
        (route) =>
          this.resolvePathWithBasePath(route.path, route.path) === targetIndex
      )

      const foundAvailableRoute = findRouteByPath(
        this.permissionRoutes,
        matchedRoute?.redirect
      )

      if (foundAvailableRoute) {
        this.$router.replace({ path: matchedRoute.redirect }, onComplete)

        return true
      }

      if (!Array.isArray(matchedRoute?.children)) {
        return false
      }

      // Find the first valid non-hidden route.
      // We don't need to check permissions because matchedRoute is a filtered list from permissionRoutes
      const firstAvailableRoute = matchedRoute.children.find(
        (route) => !route.hidden
      )

      if (firstAvailableRoute) {
        this.$router.replace(firstAvailableRoute.path, onComplete)

        return true
      }

      return false
    },
    handleClickedOnItem(item) {
      const { indexPath } = item
      const [level1Item] = indexPath || []
      const sidebarMenu = this.$refs.sidebarMenu

      // A small hack to wait for the currently active submenu to turn off and turn on at the new targetIndex
      // We do this because we want to wait for the active state to be passed to targetIndex
      // This case applies when there is an active submenu but the user clicks on a menu item (single item/item in submenu)
      setTimeout(() => {
        this.handleCollapseOtherSubmenu(sidebarMenu, level1Item)
      }, 50)
    },
    handleClickedSubmenu(item) {
      const { index: targetIndex } = item
      const sidebarMenu = this.$refs.sidebarMenu

      if (item.active && item.opened) {
        // A small hack to re-open the active submenu when we click on active submenu title.
        // We do this because element-ui does not support to cancel event from `submenu-click`
        setTimeout(() => {
          sidebarMenu.open(targetIndex)
        }, 0)

        return
      }

      const handleCollapseOtherSubmenuEvent = () => {
        // A small hack to wait for the currently active submenu to turn off and turn on at the new targetIndex
        // We do this because we want to wait for the active state to be passed to targetIndex
        // This case applies when there is an active submenu but the user clicks on another submenu
        setTimeout(() => {
          this.handleCollapseOtherSubmenu(sidebarMenu, targetIndex)
        }, 50)
      }

      // If we can redirect to new URL, we wait for the redirection to complete
      const redirecting = this.handleOpenSubmenuChild(
        targetIndex,
        handleCollapseOtherSubmenuEvent
      )

      // If we cannot redirect to new URL. We collapse them as fallback case
      if (!redirecting) {
        handleCollapseOtherSubmenuEvent()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.sidebar {
  position: relative;
  z-index: 11;
}

.sidebar__inner {
  [dir='ltr'] & {
    height: calc(100vh + 102px);
    overflow-y: auto;
  }
}

.sidebar__bottom {
  bottom: 0;
  z-index: 5000;
  width: 285px;
  background: white;
  border-top: 1px solid #e5e5e5;

  [dir='ltr'] & {
    border-right: 1px solid #e5e5e5;
  }

  [dir='rtl'] & {
    border-left: 1px solid #e5e5e5;
  }
}

.sidebar__submerchants {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background: #fff;
  border-right: 1.5px solid #e5e5e5;
  border-left: 1.5px solid #e5e5e5;
}

.sidebar__submerchants-item {
  display: flex;
  align-items: center;
  min-width: 338px;
  padding: 0 10px;
  font-size: 14px;
  cursor: pointer;
  background: #fff;

  &:hover,
  &:focus,
  &:active {
    background: #f9f9f9;
    transition: 0.2s all ease-in-out;
  }

  svg:last-child {
    [dir='ltr'] & {
      margin-left: auto;
    }

    [dir='rtl'] & {
      margin-right: auto;
    }
  }
}

.sidebar__submerchants-item-svg-container svg {
  width: 32px !important;
  height: 32px !important;

  [dir='ltr'] & {
    margin-right: 15px;
  }

  [dir='rtl'] & {
    margin-left: 15px;
  }
}

.sidebar__submerchants-item-name {
  width: 238px;
  padding-top: 18px;
  padding-bottom: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar__submerchants-item-name-container {
  width: 100%;
  border-bottom: 1.5px solid #e5e5e5;
}

.sidebar__submerchants-button {
  display: flex;
  align-items: center;
  min-height: 72px;
  padding: 13px 24px;
  font-size: 14px;
  color: #000 !important;
  border-bottom: 1.5px solid #e5e5e5;

  &.sidebar__submerchants-button--clickable {
    cursor: pointer;
  }
}

.sidebar__submerchants-button-svg {
  [dir='ltr'] & {
    margin-left: auto;
  }

  [dir='rtl'] & {
    margin-right: auto;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.sidebar__change-branch-svg {
  [dir='rtl'] & {
    transform: scale(-1, 1);
    transform-origin: center;
  }
}

.sidebar__submerchants-button-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  > :first-child {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;

    [dir='ltr'] & {
      font-family: 'Space Grotesk', sans-serif;
    }

    [dir='rtl'] & {
      font-family: 'IBM Plex Sans Arabic', sans-serif;
    }
  }

  > :last-child {
    width: 145px;
    margin-top: 4px;
    overflow: hidden;
    font-size: 12px;
    font-weight: 400;
    color: #666;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.sidebar__submerchants-button-merchant-name {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;

  [dir='ltr'] & {
    font-family: 'Space Grotesk', sans-serif;
  }

  [dir='rtl'] & {
    font-family: 'IBM Plex Sans Arabic', sans-serif;
  }
}

.sidebar__submerchants-main-logo {
  width: 27px;

  img {
    width: 100%;
    max-height: 32px;
  }

  svg {
    width: 27px;
    height: 32px;
  }

  [dir='ltr'] & {
    margin-right: 12px;
  }

  [dir='rtl'] & {
    margin-left: 12px;
  }
}

.sidebar__submerchants-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px;
  border-bottom: 1.5px solid #e5e5e5;
}

.sidebar__submerchants-content {
  height: calc(100vh - 139px);
  max-height: calc(100vh - 139px);
  padding: 10px 24px;
  overflow-y: scroll;
}

.sidebar__submerchants-footer {
  display: flex;
  align-items: center;
  padding: 24px 24px;
  font-size: 14px;
  color: #666;
  border-top: 1.5px solid #e5e5e5;
}

.sidebar__submerchants-no-results {
  padding-top: 50px;
  padding-bottom: 50px;
  font-weight: 600;
  color: #999;
  text-align: center;
}

.sidebar__submerchants-head-search {
  display: flex;
  align-items: center;
  justify-items: center;
  width: 174px;
  height: 36px;
  color: #666;
  background: #f2f2f2;
  border-radius: 24px;
}

.sidebar__submerchants-head-search ::v-deep input {
  width: 80%;
  height: initial;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: initial;
  color: #000;
  background: #f2f2f2;
  border: none;

  &::placeholder {
    color: #666;
  }
}

.sidebar__submerchants-head-search ::v-deep .el-input__suffix {
  top: -8px !important;
}

.sidebar__submerchants-head-text {
  font-size: 14px;
  color: #666;
}

.sidebar__search-svg-container {
  display: inline-block;
  color: #000;
  vertical-align: middle;

  [dir='ltr'] & {
    padding: 6px 5px 6px 12px;
  }

  [dir='rtl'] & {
    padding: 6px 12px 6px 5px;
  }

  svg {
    width: 10px;
    height: 10px;
    color: #999;
  }
}

.sidebar ::v-deep .v-modal {
  z-index: 0 !important;
}

.sidebar ::v-deep .el-menu {
  z-index: 3000;
}

.sidebar ::v-deep .el-drawer__container {
  [dir='ltr'] & {
    left: 284px !important;
  }

  [dir='rtl'] & {
    right: 284px !important;
  }
}

.sidebar ::v-deep .el-menu-item .svg-icon,
.sidebar ::v-deep .el-submenu .svg-icon {
  [dir='ltr'] & {
    margin-right: 15px;
  }

  [dir='rtl'] & {
    margin-left: 15px;
  }
}

.sidebar ::v-deep .el-menu-item,
.sidebar ::v-deep .el-submenu .el-submenu__title {
  padding-right: 30px !important;
  padding-left: 30px !important;
}

.sidebar ::v-deep .el-submenu .el-menu-item {
  padding-right: 64px !important;
  padding-left: 64px !important;
  background: transparent !important;

  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
  }
}

.sidebar ::v-deep .el-drawer[style] {
  width: initial !important;
  box-shadow: none;
}
</style>
