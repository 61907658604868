import { countryData } from '@/constants/country-data'
/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string | null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time)
    }
    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  return format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    return value.toString().padStart(2, '0')
  })
}

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (('' + time).length === 10) {
    time = parseInt(time) * 1000
  } else {
    time = +time
  }
  const d = new Date(time)
  const now = Date.now()

  const diff = (now - d) / 1000

  if (diff < 30) {
    return '刚刚'
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + '分钟前'
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + '小时前'
  } else if (diff < 3600 * 24 * 2) {
    return '1天前'
  }
  if (option) {
    return parseTime(time, option)
  } else {
    return (
      d.getMonth() +
      1 +
      '月' +
      d.getDate() +
      '日' +
      d.getHours() +
      '时' +
      d.getMinutes() +
      '分'
    )
  }
}

/**
 * @param {string} type
 * @returns {Date}
 */
export function getTime(type) {
  if (type === 'start') {
    return new Date().getTime() - 3600 * 1000 * 24 * 90
  } else {
    return new Date(new Date().toDateString())
  }
}

/**
 * Fires a func specific number of times (timesToRepeat) with a delay (delay)
 * @param {func} func the function to call repeatedly
 * @param {number} delay wait time between each fire
 * @param {number} timesToRepeat how many times to call the passed function
 * @returns {number} id of setTimeout
 */
export function repeatSetTimeout(func, delay, timesToRepeat) {
  if (!timesToRepeat) return

  return setTimeout(() => {
    func()
    repeatSetTimeout(func, delay, timesToRepeat - 1)
  }, delay)
}

/**
 * capitalize first letter of a string
 * @param {string} string string to capitalize
 * @returns {string}
 */
export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}

/**
 * get class name to apply for this class
 * @param {string} status status string to get color of
 * @returns {string}
 */
export function getStatusColorClass(status) {
  if (
    status === 'fully_captured' ||
    status === 'partially_captured' ||
    status === 'authorised' ||
    status === 'new' ||
    status === 'approved' ||
    status === 'OrderWasCaptured'
  ) {
    return 'global__tag-green'
  }
  if (status === 'fully_refunded' || status === 'partially_refunded') {
    return 'global__tag-yellow'
  }
  if (
    status === 'fully_cancelled' ||
    status === 'partially_cancelled' ||
    status === 'declined' ||
    status === 'OrderWasRefunded' ||
    status === 'OrderWasCanceled'
  ) {
    return 'global__tag-red'
  }
  if (status === 'updated' || status === 'on_hold') {
    return 'global__tag-blue'
  }
  if (status === 'expired' || status === 'closed' || status === 'canceled') {
    return 'global__tag-grey'
  }

  return 'global__tag-grey'
}

/**
 * ellipsis a string from the center someword...another
 * @param {string} string string to ellipsis
 * @param numberOfCharacters maximum number of characters allowed without ellipsis
 * @returns {string}
 */
export function ellipsisCenter(string, numberOfCharacters = 35) {
  if (string && string.length > numberOfCharacters) {
    return string.substring(0, 5) + '...' + string.substring(string.length - 5)
  }
  return string
}

/**
 * ellipsis a string from the center someword...another
 * @param {string} string string to ellipsis
 * @param numberOfCharacters maximum number of characters allowed without ellipsis
 * @returns {string}
 */
export function ellipsisCenterFlexible(string, numberOfCharacters) {
  if (string && string.length > numberOfCharacters) {
    const charactersToShow = numberOfCharacters / 2 - 2
    return (
      string.substring(0, charactersToShow) +
      '...' +
      string.substring(string.length - charactersToShow)
    )
  }
  return string
}

/**
 * ellipsis a string from the end
 * @param {string} string string to ellipsis
 * @param {number} numberOfCharacters
 * @returns {string}
 */
export function ellipsisEnd(string, numberOfCharacters) {
  if (string && string.length > numberOfCharacters) {
    return string.substring(0, numberOfCharacters) + '...'
  }
  return string
}

export function decimalCount(num) {
  // Convert to String
  const numStr = String(num)
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length
  }
  // String Does Not Contain Decimal
  return 0
}

export function getPaymentTypeIcon(paymentType, numberOfInstallments) {
  if (paymentType === 'PAY_BY_INSTALMENTS' && numberOfInstallments) {
    return `split-${numberOfInstallments}`
  }
  if (paymentType === 'PAY_NOW') {
    return `paynow`
  }
  if (paymentType === 'PAY_BY_LATER' || paymentType === 'PAY_NEXT_MONTH') {
    return 'later'
  }
  return 'paynow'
}

export function checkCapslock(e) {
  if (e instanceof KeyboardEvent) {
    this.capsLockNote = e.getModifierState('CapsLock')
      ? this.$t('login_capslock_alert')
      : null
  }
}

/**
 * Get country data by country code
 * @param countryCode
 * @returns {{}}
 */
export function getCountryDataByCountryCode(countryCode) {
  if (!countryCode) return {}
  return countryData.find(
    (country) => country.countryCode.toLowerCase() === countryCode.toLowerCase()
  )
}
