import request from '@/utils/request'

const fetchSettlements = (params) => {
  const requiredParams = {
    'sort[createdAt]': 'desc',
  }
  return request(
    {
      path: '/merchants/settlement/list',
      method: 'GET',
      data: { ...params, ...requiredParams },
    },
    {
      options: {
        cache: true,
      },
    }
  )
}

const fetchConfigs = (merchantId) => {
  return request(
    {
      path: `/merchants/${merchantId}/accounts`,
      method: 'GET',
    },
    {
      options: {
        cache: true,
      },
    }
  )
}

const fetchBreakdown = (countryCode, currency) => {
  return request({
    path: `/merchants/settlement/breakdown?country_code=${countryCode}&currency=${currency}`,
    method: 'GET',
  })
}

const updateSettlementModel = (merchantAccountId, model) => {
  return request({
    path: `/merchants/account/${merchantAccountId}/update-config`,
    method: 'PUT',
    data: {
      settlement_model: model,
    },
  })
}

export default {
  fetchSettlements,
  fetchConfigs,
  updateSettlementModel,
  fetchBreakdown,
}
