import { getAdtechFeatureFlag } from '@/api/ads'
import MerchantUserApi from '@/api/merchant/users'
import router from '@/router'
import store from '@/store/index'

const state = {
  data: null,
  selectedMerchant: '',
  roles: [],
  permissions: [],
  fetching: false,
  onboardingId: null,
  error: null,
  timezone: 'Asia/Riyadh',
}

const getters = {
  profile: (state) => {
    return state.data || {}
  },
  subMerchants: (_, getters) => {
    return getters.profile.stores || []
  },
  selectedMerchant: (state) => {
    return state.selectedMerchant
  },
  userPermissions: (state) => {
    return state.permissions
  },
  timezone: (state) => {
    return state.timezone
  },
}

const mutations = {
  SET_STATUS: (state, { key, value }) => {
    state[key] = value
  },
  SET_SELECTED_MERCHANT: (state, merchantId) => {
    state.selectedMerchant = merchantId
    localStorage.setItem('selectedMerchant', merchantId)
  },
  SET_MERCHANT_USER_DATA: (state, data) => {
    state.data = data
    state.roles = data.roles || []
    state.permissions = data.permissions || []
  },
  SET_MERCHANT_ERROR: (state, error) => {
    state.error = error
  },
  SET_ONBOARDING_ID: (state, onboardingId) => {
    state.onboardingId = onboardingId
  },
  CLEAR_MERCHANT_USER_DATA: (_) => {
    state.data = null
    state.selectedMerchant = ''
    localStorage.setItem('selectedMerchant', '')
  },
  SET_TIME_ZONE: (state, timezone) => {
    state.timezone = timezone
  },
}

const actions = {
  updateSelectedMerchant({ commit }, merchantId) {
    commit('SET_SELECTED_MERCHANT', merchantId)
    router.go()
  },
  async getMerchantUserProfile({ commit, getters, state }) {
    if (state.data) {
      return state.data
    }

    try {
      commit('SET_STATUS', { key: 'fetching', value: true })

      const { data } = await MerchantUserApi.getMerchantUserProfile()
      commit('SET_ONBOARDING_ID', data.onboardingId)
      commit('SET_TIME_ZONE', data.timeZone)

      if (
        !getters.selectedMerchant &&
        data &&
        data.stores &&
        data.stores.length > 0
      ) {
        const merchantId = localStorage.getItem('selectedMerchant')
          ? localStorage.getItem('selectedMerchant')
          : data.stores[0].merchantId
        commit('SET_SELECTED_MERCHANT', merchantId)

        // Feature flag from BE to enable AdTech module on FE
        const res = await getAdtechFeatureFlag()

        store.dispatch('ads/setFeatureFlags', res)
        if (res?.isAdtechEnabled) {
          data.permissions.push({
            permissionPath: '^/ads$',
            permissionName: 'Access Adtech',
            permissionNameAr: 'Access Adtech',
            code: 'access_adtech',
            isVisible: true,
          })
        }
      }

      // Convert to list
      data.roles = data.role?.split(',') || []

      commit('SET_STATUS', { key: 'fetching', value: false })
      commit('SET_MERCHANT_USER_DATA', data)

      return data
    } catch (error) {
      commit('SET_MERCHANT_ERROR', error)
    }
  },
  clearMerchantUserData({ commit }) {
    commit('CLEAR_MERCHANT_USER_DATA')
  },
}

export default { namespaced: true, state, getters, mutations, actions }
